import { useState, useEffect } from "react";
import propTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Select, FormControl, MenuItem, Link, Breadcrumbs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AppBar from "./Appbar";
import Sites from "../pages/Sites";
import { useLocation, useNavigate } from "react-router-dom";
import SitesList from "../pages/Site/SitesList";
import SignOutButton from "./Logout";
import ViewerComponent from "./ViewerComponent";
import SiteDetails from "../pages/Site/SiteDetails";
import ProtocolList from "../pages/Protocol/ProtocolList";
import TrailDetails from "../pages/Trail/TrailDetails";
import TrailList from "../pages/Trail/TrailList";
import ProtocolDetails from "../pages/Protocol/ProtocolDetails";
import TrialOwner from "../pages/Trail/TrialOwner";

const useStyles = makeStyles((theme) => ({
  box1: { height: "100vh", bgcolor: "#fafbfe" },
  box2: {
    margin: "2px 4px 0px 4px"
  },
  mainGrid: {
    paddingTop: "0px"
  },
  userIcon: { color: "green", transform: "scale(1.4)", marginRight: "8px" },
  smallFontSize: {
    fontSize: ".875rem"
  },
  formControl: theme.FormControl,
  homeGrid: {
    backgroundColor: "#e9ecef",
    height: 52,
    display: "flex",
    alignItems: "center",
    color: "black",
    borderRadius: "4px"
  },
  mblViewHome: {
    marginTop: "50px",
    backgroundColor: "#e9ecef",
    height: 52,
    display: "flex",
    alignItems: "center",
    color: "black"
  },
  link: {
    fontSize: ".875rem",
    color: "green"
  }
}));
export default function Home({ yearList, handleChange1 }) {
  document.title = "Viewer";
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState(
    sessionStorage.getItem("selectedYear") === null
      ? yearList[0]
      : sessionStorage.getItem("selectedYear")
  );
  const [pathID, setPathID] = useState(null);
  const [detailsName, setDetailsName] = useState();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let navigate = useNavigate();
  let location = useLocation();

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
    handleChange1(event.target.value);
  };

  const navigateToSite = () => {
    let path = `/home/sites/${selectedYear}`;
    navigate(path);
  };

  const navigateToHome = () => {
    let path = "/home";
    navigate(path);
  };

  const navigateToTrials = () => {
    let path = `/home/trials/${selectedYear}`;
    navigate(path);
  };

  const navigateToProtocal = () => {
    let path = `/home/protocol/${selectedYear}`;
    navigate(path);
  };

  const navigateToTrailOwner = () => {
    let path = `/home/trialsOwner/${selectedYear}`;
    navigate(path);
  };

  const navigateTocropsName = () => {
    let path = `/home/crops/${selectedYear}`;
    navigate(path);
  };
  const navigateToProtocolsOwner = () => {
    let path = `/home/protocolsOwner/${selectedYear}`;
    navigate(path);
  };
  const [siteName, setSiteName] = useState("");
  const [protocolname, setProtocolname] = useState("");
  const [trialname, setTrailname] = useState("");

  const changeName = (selectedName) => {
    setDetailsName(selectedName);
  };
  useEffect(() => {
    const newSelectedYear = location?.pathname.split("/");
    const newID = location?.pathname.split("/");
    if (newSelectedYear[3] !== undefined) {
      setSelectedYear(newSelectedYear[3]);
      handleChange1(newSelectedYear[3]);

      setPathID(newID[4]);
    } else {
      setPathID(newID[4]);
    }
    if (location.pathname === `/home/sites/${selectedYear}/${location?.pathname.split("/")[4]}`) {
      setSiteName(detailsName);
    }
    if (
      location.pathname === `/home/protocol/${selectedYear}/${location?.pathname.split("/")[4]}` ||
      location.pathname === `/home/protocolsOwner/${selectedYear}/${location?.pathname.split("/")[4]}`
    ) {
      setProtocolname(detailsName);
    }

    if (location.pathname === `/home/trials/${selectedYear}/${location?.pathname.split("/")[4]}`) {
      setTrailname(detailsName);
    }

    if (
      location.pathname === `/home/trialsOwner/${selectedYear}/${location?.pathname.split("/")[4]}` ||
      location.pathname === `/home/crops/${selectedYear}/${location?.pathname.split("/")[4]}`
    ) {
      setTrailname(detailsName);
    }
  }, [selectedYear, detailsName]);

  const crumbs = (path) => {
    let pages = "";
    if (path.indexOf("trialsOwner") > -1) {
      pages = "trialsOwner";
    } else if (path.indexOf("crops") > -1) {
      pages = "crops";
    } else if (path.indexOf("protocolsOwner") > -1) {
      pages = "protocolsOwner";
    } else if (path.indexOf("sites") > -1) {
      pages = "sites";
    } else if (path.indexOf("trials") > -1) {
      pages = "trials";
    } else {
      pages = "protocol";
    }

    const navigatePage =
      pages === "trialsOwner"
        ? navigateToTrailOwner
        : pages === "crops"
        ? navigateTocropsName
        : pages === "protocolsOwner"
        ? navigateToProtocolsOwner
        : pages === "sites"
        ? navigateToSite
        : pages === "trials"
        ? navigateToTrials
        : navigateToProtocal;

    return (
      <>
        {path === "/home" ? (
          <Link style={{ textDecoration: "none" }} component="button">
            <Typography
              style={{
                cursor: "default",
                margin: "10px",
                fontSize: ".875rem",
                color: "grey"
              }}>
              Home
            </Typography>
          </Link>
        ) : path === `/home/${pages}/${selectedYear}` ? (
          <Breadcrumbs seperator="/">
            <Link
              onClick={navigateToHome}
              component="button"
              sx={{
                "&:hover": {
                  textDecoration: "underline green"
                }
              }}>
              <Typography
                style={{
                  margin: "10px",
                  fontSize: ".875rem",
                  color: "green"
                }}>
                Home
              </Typography>
            </Link>

            <Link component="button">
              <Typography style={{ cursor: "default", fontSize: ".875rem", color: "grey" }}>
                {pages === "trialsOwner"
                  ? "Trial" + " " + "Owners"
                  : pages === "protocolsOwner"
                    ? "Protocol" + " " + "Owners"
                    : pages === "crops"
                      ? "Crops" + " "
                      : pages.charAt(0).toUpperCase() + pages.slice(1)}
              </Typography>
            </Link>
          </Breadcrumbs>
        ) : path == `/home/${pages}/${selectedYear}/${location?.pathname.split("/")[4]}` ? (
          <Breadcrumbs seperator="/">
            <Link
              onClick={navigateToHome}
              component="button"
              sx={{
                "&:hover": {
                  textDecoration: "underline green"
                }
              }}>
              <Typography
                style={{
                  margin: "10px",
                  fontSize: ".875rem",
                  color: "green"
                }}>
                Home
              </Typography>
            </Link>

            <Link
              onClick={navigatePage}
              component="button"
              sx={{
                "&:hover": {
                  textDecoration: "underline green"
                }
              }}>
              <Typography
                style={{
                  fontSize: ".875rem",

                  color: "green"
                }}>
                {pages === "trialsOwner"
                  ? "Trial" + " " + "Owners"
                  : pages === "protocolsOwner"
                    ? "Protocol" + " " + "Owners"
                    : pages === "crops"
                      ? "Crops" + " "
                      : pages.charAt(0).toUpperCase() + pages.slice(1)}
              </Typography>
            </Link>
            <Link component="button" style={{ textDecoration: "none" }}>
              <Typography
                style={{
                  cursor: "default",
                  fontSize: ".875rem",
                  color: "grey"
                }}>
                {pages === "trialsOwner" || pages === "crops"
                  ? trialname
                  : pages == "sites"
                    ? siteName
                    : pages == "protocol" || pages === "protocolsOwner"
                      ? protocolname
                      : pages === "trials"
                        ? trialname
                        : null}
              </Typography>
            </Link>
          </Breadcrumbs>
        ) : (
          "null"
        )}
      </>
    );
  };

  const pages =
    location.pathname.indexOf("trialsOwner") > -1
      ? "trialsOwner"
      : location.pathname.indexOf("crops") > -1
      ? "crops"
      : location.pathname.indexOf("protocolsOwner") > -1
      ? "protocolsOwner"
      : location.pathname.indexOf("sites") > -1
      ? "sites"
      : location.pathname.indexOf("trials") > -1
      ? "trials"
      : "protocol";
  return (
    <>
      <Grid container sx={{ marginBottom: "16px", marginTop: isMobile && "20px" }}>
        {isMobile ? (
          location.pathname !== "/oldviewer" ? (
            location.pathname === `/home/${pages}/${selectedYear}/${location?.state?.id}` ? (
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.homeGrid}
                sx={{ marginTop: "20px", height: isMobile ? 100 : null }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  {crumbs(location.pathname)}
                </Grid>

                {location.pathname === "/home" ? (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    container
                    justifyContent="end"
                    sx={{ paddingRight: 2 }}>
                    <FormControl sx={{ minWidth: 60, m: 1 }} className={classes.formControl}>
                      <Select
                        id="year"
                        className={classes.dropdownChange}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "white",
                            fontSize: ".76563rem"
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#82b122",
                            borderWidth: "0px",
                            boxShadow: "0 0 0 0.2rem rgba(0,128,0,.25)"
                          }
                        }}
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                        value={selectedYear}
                        onChange={handleChange}
                        autoWidth>
                        {yearList
                          ?.sort(function (a, b) {
                            return b - a;
                          })
                          ?.map((item) => (
                            <MenuItem
                              sx={{ fontSize: "14px", color: "#16181b", padding: "4px 24px" }}
                              key={item}
                              value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={12}
                    xl={12}
                    container
                    justifyContent="end"
                    sx={{ paddingRight: 2 }}>
                    <Link onClick={navigateToHome} component="button">
                      <Typography
                        style={{
                          margin: "10px",
                          fontSize: ".875rem",
                          color: "green",
                          textDecoration: "underline green"
                        }}>
                        {selectedYear}
                      </Typography>
                    </Link>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.homeGrid}
                sx={{ marginTop: "20px", height: isMobile ? 72 : null }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  {crumbs(location.pathname)}
                </Grid>

                {location.pathname === "/home" ? (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    container
                    justifyContent="end"
                    sx={{ paddingRight: 2 }}>
                    <FormControl sx={{ minWidth: 60, m: 1 }} className={classes.formControl}>
                      <Select
                        id="year"
                        className={classes.dropdownChange}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "white",
                            fontSize: ".76563rem"
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#82b122",
                            borderWidth: "0px",
                            boxShadow: "0 0 0 0.2rem rgba(0,128,0,.25)"
                          }
                        }}
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                        value={selectedYear}
                        onChange={handleChange}
                        autoWidth>
                        {yearList
                          ?.sort(function (a, b) {
                            return b - a;
                          })
                          ?.map((item) => (
                            <MenuItem
                              sx={{ fontSize: "14px", color: "#16181b", padding: "4px 24px" }}
                              key={item}
                              value={item}>
                              {item}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    container
                    justifyContent="end"
                    sx={{ paddingRight: 2 }}>
                    <Link onClick={navigateToHome} component="button">
                      <Typography
                        style={{
                          margin: "10px",
                          fontSize: ".875rem",
                          color: "green",
                          textDecoration: "underline green"
                        }}>
                        {selectedYear}
                      </Typography>
                    </Link>
                  </Grid>
                )}
              </Grid>
            )
          ) : null
        ) : location.pathname !== "/oldviewer" ? (
          <>
            <AppBar />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.homeGrid}>
              <Grid item xs={8} sm={8} md={8} lg={10} xl={10}>
                {crumbs(location.pathname)}
              </Grid>
              {location.pathname === "/home" ? (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  container
                  justifyContent="end"
                  sx={{ paddingRight: 2 }}>
                  <FormControl sx={{ minWidth: 60, m: 1 }} className={classes.formControl}>
                    <Select
                      id="year"
                      className={classes.dropdownChange}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                          fontSize: ".76563rem"
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#82b122",
                          borderWidth: "0px",
                          boxShadow: "0 0 0 0.2rem rgba(0,128,0,.25)"
                        }
                      }}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      value={selectedYear}
                      onChange={handleChange}
                      autoWidth>
                      {yearList
                        ?.sort(function (a, b) {
                          return b - a;
                        })
                        ?.map((item) => (
                          <MenuItem
                            sx={{ fontSize: "14px", color: "#16181b", padding: "4px 24px" }}
                            key={item}
                            value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  container
                  justifyContent="end"
                  sx={{ paddingRight: 2 }}>
                  <Link onClick={navigateToHome} component="button">
                    <Typography
                      style={{
                        margin: "10px",
                        fontSize: ".875rem",
                        color: "green",
                        textDecoration: "underline green"
                      }}>
                      {selectedYear}
                    </Typography>
                  </Link>
                </Grid>
              )}
            </Grid>
          </>
        ) : null}
      </Grid>
      {location.pathname === "/home" ? (
        <Sites selectedYear={selectedYear} />
      ) : location.pathname === "/home/logout" ? (
        <SignOutButton />
      ) : location.pathname === "/oldviewer" ? (
        <ViewerComponent />
      ) : location.pathname === `/home/trialsOwner/${selectedYear}` ||
        location.pathname === `/home/crops/${selectedYear}` ||
        location.pathname === `/home/protocolsOwner/${selectedYear}` ? (
        <TrialOwner />
      ) : location.pathname === `/home/sites/${selectedYear}/${location?.pathname.split("/")[4]}` ? (
        <SiteDetails selectedName={(e) => changeName(e)} />
      ) : location.pathname === `/home/sites/${selectedYear}` ? (
        <SitesList selectedYear={selectedYear} />
      ) : location.pathname === `/home/protocol/${selectedYear}/${location?.pathname.split("/")[4]}` ||
        location.pathname ===
          `/home/protocolsOwner/${selectedYear}/${location?.pathname.split("/")[4]}` ? (
        <ProtocolDetails selectedYear={selectedYear} selectedName={(e) => changeName(e)} />
      ) : location.pathname === `/home/protocol/${selectedYear}` ? (
        <ProtocolList selectedYear={selectedYear} />
      ) : location.pathname === `/home/trials/${selectedYear}/${location?.pathname.split("/")[4]}` ||
        location.pathname === `/home/trialsOwner/${selectedYear}/${location?.pathname.split("/")[4]}` ||
        location.pathname === `/home/crops/${selectedYear}/${location?.pathname.split("/")[4]}` ? (
        <TrailDetails selectedYear={selectedYear} selectedName={(e) => changeName(e)} />
      ) : location.pathname === `/home/trials/${selectedYear}` ? (
        <TrailList selectedYear={selectedYear} />
      ) : (
        location.pathname + " " + selectedYear + " " + pathID
      )}
    </>
  );
}

Home.propTypes = {
  yearList: propTypes.array,
  handleChange1: propTypes.func
};
