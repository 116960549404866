import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, CircularProgress, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { trailListAPI, loginSyt } from "../../services/API";

export default function TrailList() {
  const [trailListData, setTrailListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const idToken = sessionStorage.getItem("idToken");
  document.title = "Trials";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const onChange = (event) => {
    const final = filterList.filter(({ trial }) =>
      trial.replace(/\s/g, "").toLowerCase().includes(event.target.value.toLowerCase())
    );
    setTrailListData(final);
  };

  let navigate = useNavigate();

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          trailListAPI(location.pathname.split("/")[4], (data) => {
            setFilterList(data?.trial_lists);
            setTrailListData(data?.trial_lists);
            setLoading(false);
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [location.pathname.split("/")[4]]);

  const onTrialDetailsClick = (elem) => {
    let path = `/home/trials/${location.pathname.split("/")[4]}/${elem?.id}`;
    navigate(path, {
      state: {
        name: elem.site,
      }
    });
  };
  return (
    <>
      {loading ? (
        <Stack
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress
            size={80}
            thickness={6}
            sx={{
              color: "green",
              "--CircularProgress-size": "80px",
              top: "10%",
              left: "50%"
            }}
          />
        </Stack>
      ) : (
        <div>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            textAlign="center"
            style={{ paddingTop: "10px", marginBottom: "12px" }}>
            <TextField
              variant="outlined"
              onChange={onChange}
              sx={{
                padding: 0,
                width: "300px",
                "& label.Mui-focused": {
                  color: "black"
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "black"
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black"
                  },
                  "&:hover fieldset": {
                    borderColor: "black"
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black"
                  }
                }
              }}
              label="Search trial name..."
              id="outlined-size-small"
              size="small"
            />
          </Grid>

          <div
            className="flex-container"
            style={{ marginBottom: isMobile ? "50px" : isIpad ? "100px" : "30px" }}>
            {trailListData &&
              trailListData.map((elem, index) => (
                <div className="divflex" key={index} onClick={() => onTrialDetailsClick(elem)}>
                  <div className="card-header" key={index}>
                    <span className="initial-avatar">{elem?.trial.charAt(0).toUpperCase()}</span>
                    <span className="card-title">{elem?.trial}</span>
                  </div>
                  <ul className="card-details">
                    <li className="card-blocks">
                      <b>{elem?.collection}</b> collections
                    </li>
                    <li className="card-blocks" style={{ textAlign: "left" }}>
                      {elem?.site + " - " + elem?.block}
                    </li>
                  </ul>
                </div>
              ))}
            {trailListData?.length === 0 && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "20px" }}>
                <Grid
                  item
                  style={{
                    backgroundColor: "#e9ecef",
                    display: "flex",
                    alignItems: "center",
                    color: "black",
                    borderRadius: "4px",
                    padding: "15px"
                  }}>
                  <Typography variant="h5" sx={{ fontSize: "22px", margin: "10px 5px" }}>
                    No trials found for this search query.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      )}
    </>
  );
}

