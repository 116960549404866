import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import "../assests/Styles/color.css";
import { InfoCircleFill } from "react-bootstrap-icons";
import propTypes from "prop-types";

export default function ThankYouModal({ isShowThankYouModel, handleClose }) {
  return (
    <Modal Modal show={isShowThankYouModel} onHide={() => handleClose()} centered>
      <br></br>
      <br></br>
      <h1 className="thank-you-modal-text deep-cove-color">Message Sent</h1>
      <Modal.Body style={{ maxwidth: "700px", height: "110px" }}>
        <br></br>
        <div className="thank-you-message">
          <InfoCircleFill  fill="#FFB71E" height={20} width={20} />
          <span className="deep-cove-color">&nbsp; &nbsp;Thank You! for your feedback on the In-Season Insights.</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="thank-modal-button background-deep-cove">
          <span>OK</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}
ThankYouModal.propTypes = {
  isShowThankYouModel: propTypes.boolean,
  handleClose: propTypes.any
};
