import { useEffect, useState } from "react";
import propTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  MsalAuthenticationTemplate,
  useMsal,
  AuthenticatedTemplate,
  useIsAuthenticated
} from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import Pages from "./Routes";
import { callMsGraph } from "../services/API";
import "../assests/Styles/common.css";

function ErrorComponent({ error }) {
  return <p>An Error Occurred: {error}</p>;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#FAFBFE"
    },
    secondary: {
      main: "#e9ecef"
    }
  },
  typography: {
    fontFamily: ["HelveticaNeue", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"].join(","),
    lineHeight: "1.6",
    fontWeight: "400"
  },
  FormControl: {
    "& .MuiInputBase-root": {
      backgroundColor: "green",
      color: "#fff",
      borderColor: "green",
      height: "30px",
      justifyContent: "center",
      fontSize: ".76563rem",
      lineHeight: "1.5",
      borderRadius: "0.2rem",
      paddingRight: "0px"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      padding: "0 0 0 11px",
      paddingRight: "0px !important"
    },
    list: {
      fontSize: ".875rem",
      "& li.Mui-selected": {
        background: "#e9ecef"
      },
      "& li.Mui-selected:hover": {
        background: "#e9ecef"
      }
    },
    select: {
      "&:after": {
        borderColor: "#e9ecef"
      }
    },
    selected: {
      "&.Mui-selected": {
        backgroundColor: "#e9ecef",
        color: "white",
        fontWeight: 600,
        paddingRight: 0
      }
    }
  }
});

export function App() {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState();
  useEffect(() => {
    // Function to refresh the token
    if (token !== null) {
      const refreshToken = () => {
        if (accounts.length > 0 && sessionStorage.getItem("idToken") !== null && sessionStorage.getItem("accessToken") !== null) {
          const request = {
            scopes: ["User.Read"],
            account: accounts[0],
            forceRefresh: true // This forces the library to ignore the token cache and retrieve a new token from the server
          };
          // sessionStorage.removeItem("idToken");
          instance
            .acquireTokenSilent(request)
            .then((response) => {
              sessionStorage.setItem("idToken", response?.idToken);
              sessionStorage.setItem("accessToken", response.accessToken);
              setToken(response.idToken); // Corrected to update state correctly
              // sessionStorage.setItem("accessToken", response?.accessToken);
              // // Assuming callMsGraph is a function you've defined to call Microsoft Graph
              // callMsGraph(response.accessToken).then((response1) => {
              //   sessionStorage.setItem("firstName", response1?.givenName);
              //   sessionStorage.setItem("lastName", response1?.surname);
              // });
            })
            .catch((error) => {
              console.error(
                "Silent token acquisition failed, falling back to acquireTokenRedirect",
                error
              );
              // instance
              //   .acquireTokenRedirect(request)
              //   .then((response) => {
              //     sessionStorage.setItem("idToken", response.idToken);
              //     setToken(response.idToken); // Corrected to update state correctly
              //   })
              //   .catch((redirectError) => {
              //     console.error("Token acquisition via redirect failed", redirectError);
              //   });
            });
        }
      };

      // Call refreshToken immediately if authenticated to ensure token is fresh on load
      if (isAuthenticated) {
        refreshToken();
      }

      // Set up the interval to refresh the token every hour
      const intervalId = setInterval(refreshToken, 3600000); // 3600000 ms = 1 hour

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [instance, accounts, isAuthenticated]);

  const onLoad = async () => {
    const request = {
      scopes: ["User.Read"],
      account: accounts[0]
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then(async (response) => {
        sessionStorage.setItem("idToken", response.idToken);
        setToken(sessionStorage.setItem("idToken", response.idToken));
        sessionStorage.setItem("accessToken", response.accessToken);
        callMsGraph(response.accessToken).then((response1) => {
          sessionStorage.setItem("userId", response1?.id);
          sessionStorage.setItem("firstName", response1?.givenName);
          sessionStorage.setItem("lastName", response1?.surname);
        });
      })
      .catch(() => {
        instance.acquireTokenRedirect(request).then((response) => {
          sessionStorage.setItem("idToken", response.idToken);
          setToken(sessionStorage.setItem("idToken", response.idToken));
          sessionStorage.setItem("accessToken", response.accessToken);
        });
      });
    if (accounts[0]) {
      sessionStorage.setItem("userName", accounts[0]?.username);
    }
  };

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      onLoad();
    }
  }, [instance, accounts, inProgress, isAuthenticated]);

  const authRequest = {
    scopes: ["user.Read"]
  };
  if (token === null) {
    onLoad();
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}>
      <BrowserRouter basename="/app">
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <AuthenticatedTemplate>{token !== null && <Pages />}</AuthenticatedTemplate>
          </CssBaseline>
        </ThemeProvider>
      </BrowserRouter>
    </MsalAuthenticationTemplate>
  );
}
ErrorComponent.propTypes = {
  error: propTypes.object
};
export default App;
