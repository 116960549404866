import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  trailOwnerListAPI,
  loginSyt,
  cropOwnerListAPI,
  protocolOwnerListAPI
} from "../../services/API";
import "../../assests/Styles/new-listpage.css";
import { Spinner } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, TextField } from "@mui/material";

export default function TrailOwner() {
  const [trailOwnerListData, setTrailOwnerListData] = useState();
  const [loading, setLoading] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [noData, setNoData] = useState(false);
  const idToken = sessionStorage.getItem("idToken");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let pageTitle;
  if (location.pathname.indexOf("trialsOwner") > -1) {
    pageTitle = "Trials Owner";
  } else if (location.pathname.indexOf("crops") > -1) {
    pageTitle = "Crops";
  } else if (location.pathname.indexOf("protocolsOwner") > -1) {
    pageTitle = "Protocols Owner";
  }
  document.title = pageTitle;

  const onChange = (event) => {
    if (location.pathname.indexOf("trialsOwner") > -1) {
      const final = filterList.filter(({ trial_owner }) =>
        trial_owner.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setTrailOwnerListData(final);
    } else if (location.pathname.indexOf("crops") > -1) {
      const final = filterList.filter(({ crop_name }) =>
        crop_name.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setTrailOwnerListData(final);
    } else if (location.pathname.indexOf("protocolsOwner") > -1) {
      const final = filterList.filter(({ protocol_owner }) =>
        protocol_owner.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setTrailOwnerListData(final);
    }
  };

  let navigate = useNavigate();

  const fetchData = (apiFunction, sortFunction) => {
    apiFunction(location.pathname.split("/")[4], (data) => {
      if (data?.results.length > 0) {
        setTrailOwnerListData(
          data?.results.sort(sortFunction)
        );
        setFilterList(data?.results);
        setLoading(false);
      } else {
        setTrailOwnerListData([]);
        setNoData(true);
        setFilterList([]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          if (location.pathname.indexOf("trialsOwner") > -1) {
            fetchData(trailOwnerListAPI, (a, b) => a?.trial_owner.localeCompare(b?.trial_owner));
          } else if (location.pathname.indexOf("crops") > -1) {
            fetchData(cropOwnerListAPI, (a, b) => a?.crop_name.localeCompare(b?.crop_name));
          } else if (location.pathname.indexOf("protocolsOwner") > -1) {
            fetchData(protocolOwnerListAPI, (a, b) => a?.protocol_owner.localeCompare(b?.protocol_owner));
          }
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [location.pathname.split("/")[4]]);

  const onTrialDetailsClick = (elem) => {
    let path = "";
    if (location.pathname.indexOf("trialsOwner") > -1) {
      path = `/home/trialsOwner/${location.pathname.split("/")[4]}/${elem?.id}`;
    } else if (location.pathname.indexOf("crops") > -1) {
      path = `/home/crops/${location.pathname.split("/")[4]}/${elem?.id}`;
    } else if (location.pathname.indexOf("protocolsOwner") > -1) {
      path = `/home/protocolsOwner/${location.pathname.split("/")[4]}/${elem?.id}`;
    }
    navigate(path);
  };

  let currentPage = "";
  if (location.pathname.includes("trialsOwner")) {
    currentPage = "Trial Owner";
  } else if (location.pathname.indexOf("crops") > -1) {
    currentPage = "Crop";
  } else if (location.pathname.indexOf("protocolsOwner") > -1) {
    currentPage = "Protocol Owner";
  } else {
    currentPage = " ";
  }

  return (
    <>
      {loading ? (
        <div className="center-spinner">
          <Spinner animation="border" className="custom-spinner" size="md" />
        </div>
      ) : (
        <>
          {noData === false && (
            <div data-bs-spy="scroll">
              <Grid
                xs={12}
                md={12}
                lg={12}
                xl={12}
                textAlign="center"
                style={{ paddingTop: "10px", marginBottom: "12px" }}>
                <TextField
                  variant="outlined"
                  onChange={onChange}
                  sx={{
                    padding: 0,
                    width: isMobile ? "100%" : "300px",
                    "& label.Mui-focused": {
                      color: "black"
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black"
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black"
                      },
                      "&:hover fieldset": {
                        borderColor: "black"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black"
                      }
                    }
                  }}
                  label={`Search ${currentPage} name...`}
                  size="small"
                />
              </Grid>

              <div className="cardsGap">
                {trailOwnerListData &&
                  trailOwnerListData.map((elem, index) => (
                    <div className="topDiv" key={index}>
                      <div className="cardHead" key={index}>
                        <span className="avatar">
                          {location.pathname.indexOf("trialsOwner") > -1
                            ? elem?.trial_owner?.charAt(0)
                            : location.pathname.indexOf("crops") > -1
                              ? elem?.crop_name?.charAt(0)
                              : location.pathname.indexOf("protocolsOwner") > -1
                                ? elem?.protocol_owner?.charAt(0)
                                : null}
                        </span>
                        <span className="cardTitle">
                          {location.pathname.indexOf("trialsOwner") > -1
                            ? elem?.trial_owner
                            : location.pathname.indexOf("crops") > -1
                              ? elem?.crop_name
                              : location.pathname.indexOf("protocolsOwner") > -1
                                ? elem?.protocol_owner
                                : null}
                        </span>
                      </div>

                      <ul className="cardDetails">
                        {elem?.values.map((obj, i) => (
                          <>
                            <li key={i} className="blocks">
                              <b onClick={() => onTrialDetailsClick(obj)}>
                                {location.pathname.indexOf("protocolsOwner") > -1
                                  ? obj?.protocol
                                  : obj?.trial}
                              </b>
                            </li>
                            {i === elem?.values.length - 1 ? null : <hr className="divider" />}
                          </>
                        ))}
                      </ul>
                    </div>
                  ))}

                {trailOwnerListData.length === 0 && (
                  <div className="noDataParent">
                    <div className="nodataChild">
                      <h6 style={{ fontSize: "22px", margin: "10px 5px" }}>
                        No {currentPage} found for this search query.
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {noData === true && (
            <h6
              style={{
                fontSize: "22px",
                margin: "10px 5px",
                textAlign: "center"
              }}>{`No ${currentPage} found.`}</h6>
          )}
        </>
      )}
    </>
  );
}
