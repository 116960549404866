import { useForm } from "@formspree/react";
import { Button, Typography, Divider, TextField, TextareaAutosize, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { useRef } from "react";
import propTypes from "prop-types";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    width: 320px;
    font-family:  Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  
    &:hover {
      border-color: black;
    }
  
    &:focus {
      border-color: #007FFF;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#66afe9",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f"
};

export default function MyForm({
  sendFeedbackEmail,
  currentDate,
  currentTrial,
  collectionName,
  collectionId,
  closeFeedbackModal
}) {
  const [register, handleSubmit] = useForm("xayvedow");
  const textareaRef = useRef(null);
  if (register.succeeded) {
    return sendFeedbackEmail();
  }

  const close = () => {
    closeFeedbackModal();
  };

  const onSubmit = (params) => {
    params.preventDefault();
    const test = {
      name: params.target.name.value,
      message: params.target.message.value,
      email: params.target.email.value,
      currentDate: currentDate,
      currentTrial: currentTrial,
      collectionName: collectionName,
      collectionId: collectionId
    };
    return test;
  };

  return (
    <form onSubmit={(data) => handleSubmit(onSubmit(data))}>
      <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: "14px", fontWeight: 700 }}>
        Your Name:
      </Typography>

      <Typography id="modal-modal-description">
        <TextField
          id="outlined-required"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "34px",
              fontSize: "14px",
              "&.Mui-focused fieldset": {
                borderColor: "#66afe9",
                boxShadow: "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)"
              }
            }
          }}
          size="small"
          name="name"
          inputRef={register}
        />
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: "14px", fontWeight: 700 }}>
        Your Email:
      </Typography>
      <Typography id="modal-modal-description">
        <TextField
          id="outlined-required"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "34px",
              fontSize: "14px",
              "&.Mui-focused fieldset": {
                borderColor: "#66afe9",
                boxShadow: "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)"
              }
            }
          }}
          name="email"
          size="small"
          inputRef={register}

        />
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: "14px", fontWeight: 700 }}>
        Your Message:
      </Typography>
      <Typography
        id="modal-modal-description"
        sx={{
          mb: 3
        }}>
        <StyledTextarea
          id="outlined-textarea"
          multiline
          ref={textareaRef}
          // onDragStart={this.handleDragStart}
          // onDragEnd={this.handleDragEnd}
          fullWidth
          rows={2}
          size="medium"
          minRows={3}
          style={{
            width: "100%",
            height: "54px",
            overflow: "auto"
          }}
          sx={{
            fontSize: "14px",
            "&.MuiTextarea-textarea": {
              borderColor: "blue",
              boxShadow: "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)"
            }
          }}
          name="message"
          inputRef={register}
        />
      </Typography>
      <Divider />

      <Typography sx={{ mt: 2 }}>
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button variant="contained" style={{ textTransform: "none" }} onClick={() => close()}>
            Close
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#337ab7",
              borderColor: "#2e6da4",
              color: "#fff",
              textTransform: "none"
            }}
            type="submit"
            disabled={register.submitting}>
            Send It!
          </Button>
        </Stack>
      </Typography>
    </form>
  );
}

MyForm.propTypes = {
  sendFeedbackEmail: propTypes.any,
  isOpen: propTypes.bool,
  closeFeedbackModal: propTypes.any,
  currentDate: propTypes.any,
  currentTrial: propTypes.any,
  collectionName: propTypes.any,
  collectionId: propTypes.any
};
