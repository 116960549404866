import propTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { Button } from "react-bootstrap";
import "../assests/Styles/newviewer.css";
import { InfoCircleFill, Calendar2Event } from "react-bootstrap-icons";
import { formatFlightDate } from "../services/Common";

export default function DesktopFlightDropdown({
  flightDropdown,
  flightContainerVisible,
  flightDatesContainerRef,
  selectedFlightDate,
  flightDates,
  handleFlightOption,
  flightDateRef,
  isEditViewer = false,
  isDisabled = false,
  trialType
}) {
  const isMobileLandscape = useMediaQuery({ maxHeight: 430 });
  const isSmallDevice = useMediaQuery({ maxHeight: 430 });
  const isMobile = useMediaQuery({ maxWidth: 576 });

  return (
    <div className={`flight-date-wrapper ${isEditViewer ? "edit-viewer-flight-date-wrapper" : ""}`}>
      {(!(isSmallDevice || isMobileLandscape || isMobile) || isEditViewer) && (
        <div
          style={{
            backgroundColor: "#ddd",
            position: "absolute",
            top: 0,
            zIndex: 999,
            left: 0,
          }}>
            {trialType !== "manual" ?(
          <Button
            className="d-flex flex-column align-items-center"
            style={{
              width: "40px",
              border: "none",
              backgroundColor: !flightContainerVisible ? "white" : "#FFE8BC" ,
              borderRadius: "0px 2px 2px 0px",
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 5px 2px"
            }}
            ref={flightDateRef}
            disabled={isDisabled} 
            onClick={flightDropdown}>
            <Calendar2Event
              color={"#0D1A40"}
              alignItems="center"
              width={20}
              height={20}
            />
            <span
              style={{
                color: "#0D1A40",
                fontSize: "10px",
                marginTop: "3px",
                fontFamily: "Helvetica"
              }}>
              <strong>Flight</strong>
            </span>
          </Button>) : null}
        </div>
      )}

      {flightContainerVisible && !isDisabled? (
        <div
          ref={flightDatesContainerRef}
          className={
            isMobile
              ? "mobile-flight-date-container d-flex flex-column mt-0"
              : "flight-date-container d-flex flex-column justify-content-between"
          }>
          <span className="mt-2" style={{ fontSize: "14px", fontWeight: 400, color: "#0D1A40" }}>
            Flight date {formatFlightDate(selectedFlightDate)}
          </span>
          <div className="flight-date-title">
            <InfoCircleFill fill="#FFB71E" height={20} width={20} />{" "}
            &nbsp;&nbsp;Select a flight date to show image
          </div>
          <div style={{ paddingTop: "12px" }}>
            {Object.keys(flightDates)?.map((option) => (
              <div className="p-1" key={option}>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="options"
                    checked={selectedFlightDate === option}
                    onChange={() => handleFlightOption(option)}
                    // style={{ height: "16px", width: "16px" }}
                    id={`radio-${option}`}
                    value={option}
                  />
                  <label
                    htmlFor={`radio-${option}`}
                    style={{
                      fontSize: "12px",
                      color: "#0D1A40",
                      cursor: "pointer"
                    }}>
                    {formatFlightDate(option)}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

DesktopFlightDropdown.propTypes = {
  flightDropdown: propTypes.any,
  flightContainerVisible: propTypes.any,
  flightDatesContainerRef: propTypes.any,
  selectedFlightDate: propTypes.any,
  flightDates: propTypes.any,
  handleFlightOption: propTypes.any,
  flightDateRef: propTypes.any,
  isEditViewer: propTypes.any,
  isDisabled: propTypes.any,
  trialType: propTypes.any
};
