import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Fullscreen } from "react-bootstrap-icons";
import propTypes from "prop-types";

export default function MobileTools({
  isMobileLandscape,
  ShowFullMap,
}) {
  return (
    <div
      className="d-flex flex-column mobile-tools-container"
      style={{
        backgroundColor: "transparent",
        position: "fixed",
        bottom: isMobileLandscape ? "2%" : "15%",
        zIndex: 997
      }}>
      <Button className="mobile-icon" onClick={ShowFullMap}>
        <Fullscreen color="black" height={18} width={18} />
      </Button>
    </div>
  );
}
MobileTools.propTypes = {
  isMobileLandscape: propTypes.any,
  ShowFullMap: propTypes.any,
};
