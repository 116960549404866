import { useEffect, useState } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Link,
  TableContainer,
  CircularProgress,
  Stack
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import propTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { dataConverstion, formatDateNew } from "../../services/Common";
import { trialDetailsAPI, loginSyt } from "../../services/API";

const useStyles = makeStyles(() => ({
  cardStyle: {
    border: "1px solid rgba(0,0,0,.125)",
    borderRadius: "0.25rem",
    boxShadow: "0 0 10px 0 rgba(229,234,250,.75)"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#FFFFD0 !important"
    }
  },
  dateCss: {
    color: "green",
    paddingLeft: "20px",
    "&:hover": {
      color: "#003400",
      textDecoration: "underline"
    }
  }
}));

export default function TrailDetails({ selectedName }) {
  const classes = useStyles();
  const [trailDetailsData, setTrialDetailsData] = useState([]);
  const [name, setName] = useState([]);
  const [tableName, setTableName] = useState([]);
  const location = useLocation();
  const basicURL = process.env.REACT_APP_BASE_URL;
  const idToken = sessionStorage.getItem("idToken");
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();
  document.title = location?.state !== null ? location?.state?.name : name;

  const onDateClick = (id) => {
    let path = "/oldviewer";
    navigate({
      pathname: path,
      search: `?${createSearchParams({
        collection_id: id?.collection_id,
        trial: name
      })}`
    });
  };

  const groupingData = (detailsData) => {
    const resultsDataGroup =
      detailsData?.resultfile_details.length > 0 &&
      detailsData?.resultfile_details.reduce((acc, obj) => {
        let collection_id = obj?.collection_id;
        let index = acc.findIndex((item) => {
          return item?.collection_id === collection_id;
        });
        if (index === -1) {
          acc.push({
            collection_id: collection_id,
            results_files: [
              {
                filesize: obj?.filesize,
                res_type: obj?.res_type,
                resultfile_id: obj?.resultfile_id
              }
            ]
          });
        } else {
          acc[index].results_files.push({
            filesize: obj?.filesize,
            res_type: obj?.res_type,
            resultfile_id: obj?.resultfile_id
          });
        }
        return acc;
      }, []);
    resultsDataGroup &&
      resultsDataGroup.forEach((item) => {
        let results =  item?.results_files;
        if(results[1]?.res_type==="csv_zip"){
          let temp  =  results[1];
          results[1] = results[0];
          results[0]=temp;
        }
      });

    let resultsPlusdiffIds = {};
    resultsDataGroup &&
      resultsDataGroup.forEach((item) => {
        const collection_id = item["collection_id"];
        resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
      });

    detailsData?.rasterlayer_details.length > 0 &&
      detailsData?.rasterlayer_details.forEach((item) => {
        const collection_id = item["collection_id"];
        resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
      });

    detailsData?.trial_details &&
      detailsData?.trial_details.forEach((item) => {
        const collection_id = item["collection_id"];
        resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
      });

    setTrialDetailsData(Object.values(resultsPlusdiffIds));
    let trail = Object.values(resultsPlusdiffIds)[0]?.trial;
    let table_Head =
      Object.values(resultsPlusdiffIds)[0]?.site +
      " - " +
      Object.values(resultsPlusdiffIds)[0]?.block;
    selectedName(Object.values(resultsPlusdiffIds)[0]?.site);
    setName(trail);
    setTableName(table_Head);
  };

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          trialDetailsAPI(
            location.pathname.split("/")[3],
            location.pathname.split("/")[4],
            (data) => {
              groupingData(data);
              setLoading(false);
            }
          );
        }
      },

      (err) => {
        console.log("err", err);
        setLoading(false);
      }
    );
  }, [location.pathname.split("/")[3], location.pathname.split("/")[4]]);

  return (
    <>
      {" "}
      {loading ? (
        <Stack
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress
            size={80}
            thickness={6}
            sx={{
              color: "green",
              "--CircularProgress-size": "80px",
              top: "10%",
              left: "50%"
            }}
          />
        </Stack>
      ) : (
        <div style={{ backgroundColor: "#fafbfe", height: "100%" }}>
          <Typography align="center" variant="h4" style={{ marginBottom: "15px" }}>
            {name}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ marginBottom: "20px", paddingBottom: "30px" }}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item xs={12} md={6} sm={6} lg={4} xl={3}>
              <TableContainer
                component="div"
                style={{
                  boxShadow: "0 0 10px 0 rgba(229,234,250,.75)",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "flex-start",
                  height: "100%",
                  border: "1px solid rgba(224, 224, 224, 1)"
                }}>
                <Table container className={classes.cardStyle} size="small">
                  <TableHead>
                    <TableRow
                      sx={{
                        borderTop: "3px solid green",
                        boxShadow: 1
                      }}>
                      <TableCell
                        colSpan={4}
                        sx={{
                          padding: "5px",
                          backgroundColor: "black",
                          color: "white",
                          textAlign: "center",
                          fontSize: "0.95rem",
                          fontWeight: 500,
                          lineHeight: "1.2"
                        }}>
                        {tableName}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ marginBottom: "30px" }}>
                    <>
                      {trailDetailsData
                        .sort(function (a, b) {
                          return new Date(a?.date) - new Date(b?.date);
                        })
                        .map((val, key) => (
                          <TableRow
                            key={key}
                            className={
                              val?.dem_id === null && val?.rgb_id === null && val?.ndvi_id === null
                                ? null
                                : classes.tableRow
                            }>
                            <TableCell
                              size="small"
                              padding="none"
                              style={{
                                backgroundColor: key % 2 == 0 ? "rgba(0,0,0,.075)" : "",
                                cursor:
                                  val?.dem_id === null &&
                                  val?.rgb_id === null &&
                                  val?.ndvi_id === null
                                    ? "default"
                                    : "pointer"
                              }}>
                              <Link
                                className={
                                  val?.dem_id === null &&
                                  val?.rgb_id === null &&
                                  val?.ndvi_id === null
                                    ? "datelink"
                                    : "datelinknone"
                                }
                                style={{
                                  textDecoration:
                                    val?.dem_id === null &&
                                    val?.rgb_id === null &&
                                    val?.ndvi_id === null
                                      ? "none"
                                      : null,
                                  cursor:
                                    val?.dem_id === null &&
                                    val?.rgb_id === null &&
                                    val?.ndvi_id === null
                                      ? "default"
                                      : "pointer",
                                  paddingLeft: "20px",
                                  "&:hover": {
                                    color: "#003400",
                                    textDecoration: "underline"
                                  }
                                }}
                                onClick={() =>
                                  val?.dem_id === null &&
                                  val?.rgb_id === null &&
                                  val?.ndvi_id === null
                                    ? null
                                    : onDateClick(val)
                                }>
                                <Typography variant="h8" color="green">
                                  {formatDateNew(val?.date)}
                                </Typography>
                              </Link>
                            </TableCell>
                            <TableCell
                              size="small"
                              padding="none"
                              style={{
                                backgroundColor: key % 2 == 0 ? "rgba(0,0,0,.075)" : "",
                                cursor:
                                  val?.dem_id === null &&
                                  val?.rgb_id === null &&
                                  val?.ndvi_id === null
                                    ? "default"
                                    : "pointer",
                                paddingTop: "6px",
                                paddingBottom: " 6px"
                              }}>
                              <Link
                                style={{
                                  textDecoration:
                                    val?.results_files &&
                                    val?.results_files[1]?.res_type !== "pdf_zip"
                                      ? "none"
                                      : null,
                                  cursor:
                                    val?.results_files &&
                                    val?.results_files[1]?.res_type !== "pdf_zip"
                                      ? "default"
                                      : null,
                                  color:
                                    val?.dem_id === null &&
                                    val?.rgb_id === null &&
                                    val?.ndvi_id === null
                                      ? "black"
                                      : "green"
                                }}
                                href={
                                  val?.results_files &&
                                  val?.results_files[1]?.res_type === "pdf_zip"
                                    ? `${basicURL}/api/resultsfile/${val?.results_files[1]?.resultfile_id}`
                                    : null
                                }>
                                <IconButton sx={{ padding: 0 }}>
                                  {val?.results_files &&
                                  val?.results_files[1]?.res_type === "pdf_zip" ? (
                                      <DownloadIcon sx={{ color: "black", fontSize: "15px" }} />
                                    ) : null}
                                </IconButton>
                                <Typography
                                  variant="h8"
                                  style={{
                                    color:
                                      val?.results_files &&
                                      val?.results_files[1]?.res_type === "pdf_zip"
                                        ? "green"
                                        : "black",

                                    fontSize: ".7rem"
                                  }}>
                                  {val?.results_files &&
                                  val?.results_files[1]?.res_type === "pdf_zip"
                                    ? "pdf "
                                    : "n/a"}
                                </Typography>
                              </Link>
                              <Typography
                                variant="h8"
                                sx={{ fontSize: ".7rem", color: "#6c757d!important" }}>
                                {val?.results_files && val?.results_files[1]?.filesize
                                  ? dataConverstion(val?.results_files[1]?.filesize)
                                  : ""}
                              </Typography>
                            </TableCell>
                            <TableCell
                              size="small"
                              padding="none"
                              style={{
                                backgroundColor: key % 2 == 0 ? "rgba(0,0,0,.075)" : "",
                                cursor:
                                  val?.dem_id === null &&
                                  val?.rgb_id === null &&
                                  val?.ndvi_id === null
                                    ? "default"
                                    : "pointer"
                              }}>
                              <Link
                                style={{
                                  textDecoration:
                                    val?.results_files &&
                                    val?.results_files[0]?.res_type !== "csv_zip"
                                      ? "none"
                                      : null,
                                  cursor:
                                    val?.results_files &&
                                    val?.results_files[0]?.res_type !== "csv_zip"
                                      ? "default"
                                      : null,
                                  color:
                                    val?.dem_id === null &&
                                    val?.rgb_id === null &&
                                    val?.ndvi_id === null
                                      ? "black"
                                      : "green"
                                }}
                                href={
                                  val?.results_files &&
                                  val?.results_files[0]?.res_type === "csv_zip"
                                    ? `${basicURL}/api/resultsfile/${val?.results_files[0]?.resultfile_id}`
                                    : null
                                }>
                                <IconButton>
                                  {val?.results_files &&
                                  val?.results_files[0]?.res_type === "csv_zip" ? (
                                      <DownloadIcon sx={{ color: "black", fontSize: "15px" }} />
                                    ) : null}
                                </IconButton>
                                <Typography
                                  variant="h8"
                                  style={{
                                    color:
                                      val?.results_files &&
                                      val?.results_files[0]?.res_type === "csv_zip"
                                        ? "green"
                                        : "black",
                                    fontSize: ".7rem"
                                  }}>
                                  {val?.results_files &&
                                  val?.results_files[0]?.res_type === "csv_zip"
                                    ? "excel "
                                    : "n/a"}
                                </Typography>
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

TrailDetails.propTypes = {
  selectedName: propTypes.func,
  selectedYear: propTypes.any
};
