/* eslint-disable react/display-name */
import { useParams, useLocation, useNavigate } from "react-router-dom";

const withRouter = (WrappedComponent) => (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const params = useParams();

  return <WrappedComponent {...props} params={params} location={location} navigate={navigate} />;
};

export default withRouter;
