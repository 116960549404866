import propTypes from "prop-types";
import { AssessmentBySwitch, AssessmentMessage } from "./AssessmentDropDown";
import { ChevronLeft, ChevronRight, Search, X } from "react-bootstrap-icons";
import droneIconBlack from "../assests/Styles/assets/droneIconBlack.svg";
import loanVesselIcon from "../assests/Styles/assets/loanVesselIcon.svg";
import droneIcon from "../assests/Styles/assets/drone.svg";
import { useState } from "react";
import loanVesselIconfaint from "../assests/Styles/assets/loanVesselIconfaint.svg";
import { formatFlightDate } from "../services/Common";
import { InputGroup, FormControl } from "react-bootstrap";
import "../assests/Styles/mobileTopBar.css";

const orderArray = ["A", "B", "C", "D", "E", "F"];

function AssessmentContainerMobile({
  assessmentBy,
  setAssessmentBy,
  isTablet,
  assessmentDatesAndTypes,
  assessmentsFilteredByType,
  handleAssessmentSelect,
  assessmentDateType,
  setAssessmentDateType,
  setShowTreatment,
  setShowTime,
  setShowCompare,
  setShowTreatmentMT,
  setShowTimeMT,
  setShowCompareMT,
  setAssessmentOptionsVisible,
  setHamburgerOpen,
  setAssessmentContainerVisible,
  customMobileLandscape,
  customMobilePortrait
}) {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedType, setSelectedType] = useState([]);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const[secondAssessmentContainerVisible,setSecondAssessmentContainerVisible]=useState(false);

  const selectedAsessmentCategories = assessmentDatesAndTypes?.find(
    ({ assessmentdate }) => assessmentdate === selectedDate
  );
  const isMax6Item = assessmentDateType.length >= 6;

  let containerMobile = "";

  const handleFirstPageChevron = () => {
    setHamburgerOpen(true);
    setAssessmentContainerVisible(false);
  };
  const handleSecondPageChevron = () => {
    //  setSelectedDate();
    // setAssessmentContainerVisible(true);
    //setSelectedType([]);
    setSelectedAssessmentId();
    setSecondAssessmentContainerVisible(false);
  };
 
  return (
    <>
      <div className={`${containerMobile} pt-3 pb-2`}>
        <button
          className="btn"
          onClick={secondAssessmentContainerVisible ? handleSecondPageChevron : handleFirstPageChevron}>
          <ChevronLeft width={24} height={24} />
        </button>

        {AssessmentMessage}
        <span style={{position: "absolute", right: "15px", top: "24px"}}>
          <X
            onClick={() => setAssessmentContainerVisible(false)}
            width={24}
            height={24}
          />
        </span>
      </div>
      {secondAssessmentContainerVisible && (
        <>
          {assessmentBy === "date" ? (
            <div className="d-flex justify-content-center">
              <InputGroup className="AssessmentSearchInputGroup">
                <FormControl
                  type="text"
                  placeholder="Search for ratings"
                  className="MobileRatingModalSearch"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                {searchValue ? (
                  <X
                    onClick={() => setSearchValue("")}
                    className="assessment-search-icon"
                    width={15}
                    height={15}
                  />
                ) : (
                  <Search
                    onClick={() => {}}
                    className="assessment-search-icon"
                    width={15}
                    height={15}
                  />
                )}
              </InputGroup>
            </div>
          ) : null}
          <div className="text-right pr-3 pt-2 mobileClearAll">
            <span
              className="ass-clear-all-position"
              style={{ float: "unset" }}
              onClick={() =>
                setAssessmentDateType(
                  assessmentDateType.filter((i) =>
                    assessmentBy === "date" ? i.date !== selectedDate : i.type !== selectedType?.[1]
                  )
                )
              }>
              DESELECT ALL
            </span>
          </div>
        </>
      )}
      <div className={`${isTablet && "text-center"}`}>
        {secondAssessmentContainerVisible? (
          <>
            {assessmentBy === "date" ? (
              <>
                {selectedAsessmentCategories &&
                  Object.keys(selectedAsessmentCategories?.categories)
                    .sort((a, b) => {
                      if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                      }
                      if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((type) => {
                      const filterArray = selectedAsessmentCategories?.categories[type].filter(
                        (test) =>
                          selectedAsessmentCategories.group[type] !== "manual"
                            ? test?.toLowerCase().includes(searchValue?.toLowerCase())
                            : test?.fullName?.toLowerCase().includes(searchValue?.toLowerCase())
                      );
                      return filterArray.length > 0 ? (
                        <div style={{margin:"0px 10px", borderBottom: "1px solid #ededed"}}>
                          <div className="d-flex justify-content-between assessment-group px-3">
                            <span className="doneOrManualImg">{type}</span>
                            {selectedAsessmentCategories.group[type] === "drone" ? (
                              <img src={droneIcon} alt="" />
                            ) : (
                              <img src={loanVesselIconfaint} alt="" />
                            )}
                          </div>
                          {filterArray?.map((title) => {
                            const typeCheck =
                              selectedAsessmentCategories.group[type] !== "manual"
                                ? title
                                : title.fullName;
                            const foundIndex = assessmentDateType.findIndex(
                              (i) => i.date === selectedDate && i.type === typeCheck
                            );
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center px-3"
                                style={{
                                  opacity: isMax6Item && foundIndex === -1 ? 0.5 : 1,
                                  padding: "5px"
                                }}
                                key={typeCheck}>
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="options"
                                      className="assessment-input"
                                      onChange={(e) => {
                                        handleAssessmentSelect(
                                          e,
                                          selectedDate,
                                          typeCheck,
                                          selectedAssessmentId,
                                          selectedAsessmentCategories.group[type]
                                        );
                                      }}
                                      disabled={isMax6Item && foundIndex === -1}
                                      checked={foundIndex !== -1}
                                    />
                                    <span className="assessmentNames">
                                      {typeCheck.split("v:")[0]}
                                    </span>
                                  </label>
                                </div>
                                <div className="foundIndexCss">
                                  {foundIndex !== -1 && `(${orderArray[foundIndex]})`}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null;
                    })}
              </>
            ) : (
              <>
                {assessmentsFilteredByType[selectedType?.[0]]?.[selectedType?.[1]]?.map((dates) => {
                  const foundIndex = assessmentDateType.findIndex(
                    (i) => i.date === dates.date && i.type === selectedType?.[1]
                  );
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center px-3 typeToDate"
                      key={dates.date}>
                      <div style={{ width: "130px" }}>
                        <label>
                          <input
                            type="checkbox"
                            name="options"
                            className="assessment-input"
                            style={{ width: "16px", height: "16px" }}
                            onChange={(e) => {
                              handleAssessmentSelect(
                                e,
                                dates.date,
                                selectedType?.[1],
                                dates.assessmentID,
                                dates.isManual ? "manual" : "drone"
                              );
                            }}
                            disabled={isMax6Item && foundIndex === -1}
                            checked={foundIndex !== -1}
                          />
                          <span
                            style={{
                              paddingLeft: "8px",
                              fontSize: "12px",
                              fontWeight: "400"
                            }}>
                            {formatFlightDate(dates.date)}
                          </span>
                        </label>
                      </div>
                      <div style={{ width: "16px" }}>
                        {dates?.isDrone && <img src={droneIconBlack} alt="" />}
                      </div>
                      <div style={{ width: "16px" }}>
                        {dates?.isManual && <img src={loanVesselIcon} alt="" />}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          width: "18px"
                        }}>
                        {foundIndex !== -1 && `(${orderArray[foundIndex]})`}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        ) : (
          <>
            <AssessmentBySwitch
              assessmentBy={assessmentBy}
              setAssessmentBy={setAssessmentBy}
              assessmentDateType={assessmentDateType}
              setAssessmentDateType={setAssessmentDateType}
              setSearchValue={setSearchValue}
              setShowTreatment={setShowTreatment}
              setShowTime={setShowTime}
              setShowCompare={setShowCompare}
              setShowTreatmentMT={setShowTreatmentMT}
              setShowTimeMT={setShowTimeMT}
              setShowCompareMT={setShowCompareMT}
              setAssessmentOptionsVisible={setAssessmentOptionsVisible}
              customMobilePortrait={customMobilePortrait}
              customMobileLandscape={customMobileLandscape}
            />
            {assessmentBy === "date" ? (
              <>
                {assessmentDatesAndTypes?.map((assessment) => {                 
                  const haschildSelected = assessmentDateType.filter(
                    (i) => i.date === assessment?.assessmentdate
                  );
                  return (                    
                    <div
                      className="px-3 assessment-row-hover"                      
                        style={{backgroundColor:selectedDate===assessment?.assessmentdate && haschildSelected.length>0?"#FFE8BC":"",  margin:"0px 10px", borderBottom: "1px solid #ededed"}}
                      key={assessment?.assessmentdate}
                      onClick={() => {
                        setSelectedDate(assessment?.assessmentdate);
                        setSelectedAssessmentId(assessment?.assessmentID);
                        setSecondAssessmentContainerVisible(true);
                      }}>
                      <span
                        className="d-flex justify-content-between align-items-center"
                        style={{ height: "49px" }}>
                        <span className="assessment-option text-left" style={{ width: "100px" }}>
                          {formatFlightDate(assessment?.assessmentdate)}
                        </span>
                        <div style={{ width: "16px" }}>
                          {assessment?.isDrone && <img src={droneIconBlack} alt="" />}
                        </div>
                        <div style={{ width: "16px" }}>
                          {assessment?.isManual && <img src={loanVesselIcon} alt="" />}
                        </div>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: 700,
                            width: "18px"
                          }}>
                          {haschildSelected.length > 0 && `(${haschildSelected.length})`}
                        </span>
                        <ChevronRight color="#0D1A40" height={8} />
                      </span>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <InputGroup className="AssessmentSearchInputGroup">
                    <FormControl
                      type="text"
                      placeholder="Search for ratings"
                      className="MobileRatingModalSearch"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    {searchValue ? (
                      <X
                        onClick={() => setSearchValue("")}
                        className="assessment-search-icon"
                        width={15}
                        height={15}
                      />
                    ) : (
                      <Search
                        onClick={() => {}}
                        className="assessment-search-icon"
                        width={15}
                        height={15}
                      />
                    )}
                  </InputGroup>
                </div>
                {assessmentsFilteredByType &&
                  Object.keys(assessmentsFilteredByType)?.map((typeGroup) => {
                    const filterValuse = Object.keys(assessmentsFilteredByType[typeGroup]).filter(
                      (test) => test?.toLowerCase().includes(searchValue?.toLowerCase())
                    );
                    return filterValuse.length > 0 ? (
                      <div key={typeGroup} style={{margin: "0px 10px", borderBottom: "1px solid #ededed"}}>
                        <div className="px-3 d-flex justify-content-between assessment-group">
                          <span
                            style={{
                              fontWeight: "700",
                              fontSize: "12px",
                              color: "#293306"
                            }}>
                            {typeGroup}
                          </span>
                          {assessmentsFilteredByType[typeGroup][
                            Object.keys(assessmentsFilteredByType[typeGroup])?.[0]
                          ]?.[0]?.isDrone ? (
                            <img src={droneIcon} alt="" />
                          ) : (
                            <img src={loanVesselIconfaint} alt="" />
                          )}
                        </div>
                        {filterValuse?.map((type) => {
                          const haschildSelected = assessmentDateType.filter(
                            (i) => i.type === type
                          );
                          return (
                            <span
                              key={type}
                              className="px-3 d-flex justify-content-between align-items-center"
                              style={{ height: "32px",backgroundColor:selectedType[1]===type && haschildSelected.length>0?"#ffe8bc":""}}
                              onClick={() => {setSelectedType([typeGroup, type]),setSecondAssessmentContainerVisible(true)}}>
                              <span className="assessment-option">{type.split("v:")[0]}</span>
                              <div>
                                <span
                                  className="pr-3"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 700
                                  }}>
                                  {haschildSelected.length > 0 && `(${haschildSelected.length})`}
                                </span>
                                <ChevronRight color="#0D1A40" height={8} />
                              </div>
                            </span>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
              </>
            )}
          </>
        )}
        {/* )} */}
      </div>
    </>
  );
}
AssessmentContainerMobile.propTypes = {
  assessmentBy: propTypes.any,
  setAssessmentBy: propTypes.any,
  isTablet: propTypes.any,
  assessmentDatesAndTypes: propTypes.any,
  assessmentsFilteredByType: propTypes.any,
  handleAssessmentSelect: propTypes.any,
  assessmentDateType: propTypes.any,
  setAssessmentDateType: propTypes.any,
  isMobileLandscape: propTypes.any,
  setShowTreatment: propTypes.any,
  setShowTime: propTypes.any,
  setShowCompare: propTypes.any,
  setShowTreatmentMT: propTypes.any,
  setShowTimeMT: propTypes.any,
  setShowCompareMT: propTypes.any,
  setAssessmentOptionsVisible: propTypes.any,
  setHamburgerOpen: propTypes.any,
  setAssessmentContainerVisible: propTypes.any,
  customMobileLandscape: propTypes.any,
  customMobilePortrait: propTypes.any,
};
export default AssessmentContainerMobile;
