import { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import {
  Search,
  ChevronUp,
  ChevronDown,
  ChevronRight,
  XLg,
  EmojiFrown
} from "react-bootstrap-icons";
import { Accordion, Dropdown, Col, Container, Row, Card } from "react-bootstrap";
import {
  loginSyt,
  yearsAPI,
  collectionCountAPI,
  siteDetailsAPI,
  recentlyVisitedSite
} from "../services/API";
import syngentaLogo from "../assests/Styles/assets/logo.png";
import plotEditorIcon from "../assests/Styles/assets/plotEditor.svg";
import logout from "../assests/Styles/assets/logout1.svg";
import { useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import { Spinner, dateConverterForSites } from "../services/Common";
import "../assests/Styles/droneFlight.css";
import vectorNewIcon from "../assests/Styles/assets/vectorNewIcon.svg";
import shapeNewIcon from "../assests/Styles/assets/ShapeNewIcon.svg";
import FeedbackForm from "./FeedbackForm";

export default function DroneFlights({
  accounts,
  instance,
  state,
  submitForm,
}) {
  const name = sessionStorage.getItem("userName");
  const pro = name?.split(".");
  const [profileClick, setProfileClick] = useState(false);
  const dropdownRef = useRef(null);
  const profileIconRef = useRef(null);
  const accordElem = useRef({});
  const [show, setShow] = useState(null);
  const [yearList, setYearList] = useState(null);
  const idToken = sessionStorage.getItem("idToken");
  const effectRun = useRef(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState();
  const [loading, setLoading] = useState(true);
  const [trialOpen, setTrialOpen] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const year = params.get("year");
  const [selectedOption, setSelectedOption] = useState(year || "2024");
  const [siteListData, setSiteListData] = useState([]);
  const [siteDataOnX, setSiteDataOnX] = useState([]);
  const [filteredCollection, setFilteredCollection] = useState([]);
  const [collectionId, setCollectionId] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isShowFeedback, setIsShowFeedback] = useState(false);

  const handleOpenFeedback = () => {
    setIsShowFeedback(true);
  };

  const handleCloseFeedback = () => {
    setIsShowFeedback(false);
  };

  let navigate = useNavigate();

  /*This useEffect handles focus for accordian body */
  useEffect(() => {
    if (show && accordElem.current[show]) {
      setTimeout(() => {
        accordElem.current[show].scrollIntoView({ behavior: "smooth", block: "nearest" });
      }, 300);
    }
  });

  /*This useEffect handles the click outside functionality for the dropdown menu and profile icon.*/
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileIconRef?.current?.contains(event.target)
      ) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /*This useEffect handles the popstate event and redirects to "/newLandingPage"*/
  useEffect(() => {
    const handlePopstate = (event) => {
      event.preventDefault();
      window.location.href = "/";
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const routeChange = () => {
    let path = "/home/logout";
    navigate(path);
  };
  const handleButtonClick = () => {
    setProfileClick(!profileClick);
  };

  useEffect(() => {
    axios.defaults.withCredentials = true;
    if (effectRun.current === false) {
      loginSyt(
        idToken,
        (res) => {
          setError(null);
          if (res === 200) {
            if (sessionStorage.getItem("yearsList") === null) {
              yearsAPI((years) => {
                const yearList = years.sort(function (a, b) {
                  return b - a;
                });

                setYearList(yearList);
                sessionStorage.setItem("yearsList", JSON.stringify(yearList));
                if (yearList.length > 0) {
                  if (sessionStorage.getItem("selectedYear") === null) {
                    sessionStorage.setItem("selectedYear", yearList[0]);
                    setSelectedYear(yearList[0]);
                  } else {
                    setSelectedYear(sessionStorage.getItem("selectedYear"));
                  }
                }
                const yearSelected =
                  sessionStorage.getItem("selectedYear") === null
                    ? sessionStorage.setItem("selectedYear", yearList[0])
                    : sessionStorage.getItem("selectedYear");
                const params = new URLSearchParams(window.location.search);
                const year = params.get("year");
                collectionCountAPI(
                  year || yearSelected,
                  (collection) => {
                    setData(collection);
                    setLoading(false);
                  },
                  (err) => {
                    console.log("error", err);
                  }
                );
              });
            } else {
              setYearList(JSON.parse(sessionStorage.getItem("yearsList")));
              setSelectedYear(sessionStorage.getItem("selectedYear"));
              setLoading(false);
            }
          }
        },
        (err) => {
          setError(err.message);
          window.location.reload();
          return () => {
            effectRun.current = false;
          };
        }
      );
      return () => {
        effectRun.current = true;
      };
    }
  }, [error, loading, selectedYear]);

  /* To show number of sites,blocks and collections inside bubble */
  const collectionCount = () => {
    const params = new URLSearchParams(window.location.search);
    const year = params.get("year");
    collectionCountAPI(
      year || selectedOption,
      (res) => {
        setData(res?.new_collection);
      },
      (err) => {
        console.log("error", err);
      }
    );
  };

  useEffect(() => {
    collectionCount();
  }, [selectedOption]);

  /*sites details api call to show tables inside accordians*/
  useEffect(() => {
    loginSyt(
      sessionStorage.getItem("idToken"),
      (res) => {
        if (res === 200) {
          if (collectionId != "" && show != null) {
            setFilteredCollection([]);
            setLoading(true);
            siteDetailsAPI(selectedOption, collectionId, (data) => {
              groupingData(data);
              setLoading(false);
            });
          }
        }
      },
      (err) => {
        setLoading(false);
        console.log("err", err);
      }
    );
  }, [selectedOption, collectionId, show]);

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          setLoading(true);
          const payload = {
            year: selectedOption,
            user_email: sessionStorage.getItem("userName")
          };
          recentlyVisitedSite(payload, (data) => {
            const recentSitesData = data?.site_lists
              ?.filter((item) => item?.visited_date != "")
              ?.sort(function (a, b) {
                return (
                  dateConverterForSites(b?.visited_date) - dateConverterForSites(a?.visited_date)
                );
              });
            const dataWithoutRecentSites =
              recentSitesData?.length > 0
                ? data?.site_lists?.filter((item) =>
                  recentSitesData?.every((item2) => item2?.id !== item?.id)
                )
                : data?.site_lists;
            let combinedData =
              recentSitesData?.length > 0
                ? [...recentSitesData, ...dataWithoutRecentSites]
                : dataWithoutRecentSites;
            setSiteListData(combinedData);
            setSiteDataOnX(combinedData);
            setFilterList(combinedData);

            setLoading(false);
          });
        }
      },
      (err) => {
        setLoading(false);
        console.log("err", err);
      }
    );
  }, [selectedOption]);

  /*Grouping Details based on collection id and block*/
  const groupingData = (detailsData) => {
    const resultsDataGroup =
      detailsData?.resultfile_details.length > 0 &&
      detailsData?.resultfile_details.reduce((acc, obj) => {
        let collection_id = obj?.collection_id;
        let index = acc.findIndex((item) => {
          return item?.collection_id === collection_id;
        });
        if (index === -1) {
          acc.push({
            collection_id: collection_id,
            results_files: [
              {
                filesize: obj?.filesize,
                res_type: obj?.res_type,
                resultfile_id: obj?.resultfile_id
              }
            ]
          });
        } else {
          acc[index].results_files.push({
            filesize: obj?.filesize,
            res_type: obj?.res_type,
            resultfile_id: obj?.resultfile_id
          });
        }
        return acc;
      }, []);

    let resultsPlusdiffIds = {};
    if (resultsDataGroup.length > 0) {
      resultsDataGroup?.forEach((item) => {
        let results = item?.results_files;
        if (results?.[1]?.res_type === "csv_zip") {
          [results[0], results[1]] = [results[1], results[0]];
        }
      });

      resultsDataGroup?.forEach((item) => {
        const collection_id = item?.collection_id;
        resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
      });
    }
    detailsData?.rasterlayer_details?.forEach((item) => {
      const collection_id = item?.collection_id;
      resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
    });

    detailsData?.site_details?.forEach((item) => {
      const collection_id = item["collection_id"];
      resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
    });

    let productName = [];
    Object.values(resultsPlusdiffIds)?.filter((o) => {
      const newIndex = productName.findIndex((i) => i?.tablename === o.block);
      if (newIndex > -1) {
        productName[newIndex].collection.push({
          results_files: o?.results_files !== undefined ? o?.results_files : [],
          date: o?.date,
          collection_id: o?.collection_id,
          ndvi_id: o?.ndvi_id !== undefined ? o?.ndvi_id : null,
          rgb_id: o?.rgb_id !== undefined ? o?.rgb_id : null,
          dem_id: o?.dem_id !== undefined ? o?.dem_id : null,
          is_layout_submitted: o?.is_layout_submitted,
          is_assessment_available: o?.is_assessment_available
        });
      } else if (o?.block !== undefined) {
        return productName.push({
          tablename: o?.block,
          siteTitle: o?.site,
          collection: [
            {
              results_files: o?.results_files !== undefined ? o?.results_files : [],
              date: o?.date,
              collection_id: o?.collection_id,
              ndvi_id: o?.ndvi_id !== undefined ? o?.ndvi_id : null,
              rgb_id: o?.rgb_id !== undefined ? o?.rgb_id : null,
              dem_id: o?.dem_id !== undefined ? o?.dem_id : null,
              is_layout_submitted: o?.is_layout_submitted,
              is_assessment_available: o?.is_assessment_available
            }
          ]
        });
      }
      return null;
    });
    productName.length > 0 && productName.sort((a, b) => a?.tablename.localeCompare(b?.tablename));

    setFilteredCollection(productName);
  };

  /*setting the selected year*/
  const handleSelect = (option) => {
    setSelectedOption(option);
    let path = "/droneflight";
    setShow(null)
    setSearchText("");
    navigate({
      pathname: path,
      search: `?${createSearchParams({
        year: option
      })}`
    });
  };

  /*Year dropdown open/close */
  const handleToggle = () => {
    setTrialOpen(!trialOpen);
  };

  /*OnClick handler for accordians*/
  const handleLayoutToggle = (index, id, e) => {
    const newShow = show === index ? null : index;
    setShow(newShow);
    setCollectionId(id);
    setTimeout(() => {
      const isClosing = e.target.classList.contains("collapsed");
      let path = "/droneflight";
      if (!isClosing) {
        navigate({
          pathname: path,
          search: `?${createSearchParams({
            site: id,
            year: selectedOption
          })}`
        });
      } else {
        navigate({
          pathname: path,
        });
      }
    }, 100)
    if (newShow !== null) {
      setFilteredCollection([]);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const siteId = params.get("site");
    const year = params.get("year");
    if(year !== null) {
      setSelectedOption(year)
    }
    if (siteId !== null) {
      setCollectionId(siteId);
      const siteIndex = siteListData?.findIndex(({id}) => id == siteId);
      if (siteIndex !== -1) {
        setShow(siteIndex);
      }
    }
  }, [siteListData]); 

  /*Filtering data based on search Text*/
  const handleonChange = (event) => {
    setSearchText(event.target.value);
    const final = filterList.filter(({ site }) =>
      site.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setSiteListData(final);
  };

  /*To allow only alphanumeric characters in search*/
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
      "Escape",
      "Enter",
      " "
    ];
    if (!e.key.match(/^[a-z0-9]+$/i) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  /*To navigate to plot edior for selected date */
  const routePlotEdior = (e, id) => {
    e.stopPropagation();
    let path = `/ploteditor?collection_id=${id}`;
    navigate(path);
  };

  /*To navigate to new Viewer for the selected date */
  const routeNewViewer = (e, id) => {
    e.stopPropagation();
    let path = `/viewer?collection_id=${id}`;
    navigate(path);
  };

  /* Component for rendering sites,block,collections bubble*/
  const textBubble = (ml, name, data) => {
    return (
      <div className="DFS-text-bubble">
        <span className="DFS-text-bubble-data">
          {name} <span style={{ marginLeft: ml, fontWeight: 700 }}>{data}</span>
        </span>
      </div>
    );
  };

  /*Component for rendering blocks and collections on accordian*/
  const blockCollectionHeading = (name, data) => {
    return (
      <>
        <span className="DFS-block-collectionText-data">{data}</span>
        <span className="DFS-block-collectionText">&nbsp; {name}</span>
      </>
    );
  };

  /*On Click of X in search it clears the search field and sets the sites list data to show all the sites */
  const handleXbutton = () => {
    setSearchText("");
    setSiteListData(siteDataOnX);
  };

  return (
    <>
      {loading && (
        <div className="center-spinner DFS-new-layout-loader" data-testid="loading-spinner">
          <Spinner animation="border" className="DFS-date-spinner" />
        </div>
      )}
      <div className="DFS-row DFS-topBar" data-test-id="dronflights">
        <div
          className="col-1"
          style={{ borderRight: "1px solid white", padding: "5px 0px 0px 10px" }}>
          <a className="logo" href="/app/" id="viewer-link">
            <div className="align-items-center" style={{ textAlign: "center" }}>
              <img
                src={syngentaLogo}
                alt="syngenta"
                style={{
                  cursor: "pointer"
                }}
              />
            </div>
          </a>
        </div>
        <div className="col-2 DFS-inseasonText-div">
          <span className="DFS-inseasonText">In-Season Insights</span>
        </div>
        <div className="col-8"></div>
        <div className="col-1">
          <button className="DFS-logout" ref={profileIconRef} onClick={handleButtonClick}>
            {pro && pro.length > 1
              ? pro[0].charAt(0).toUpperCase() + pro[1].charAt(0).toUpperCase()
              : pro[0].charAt(0).toUpperCase()}
          </button>
          {profileClick && (
            <div ref={dropdownRef} className="DFS-profile-paper">
              <ul className="list-group">
                <li className="list-group-item">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <span>
                      <img
                        src={shapeNewIcon}
                        alt="shapeNewIcon"
                        style={{ width: "20px", height: "20px", margin: "2px" }}
                      />
                    </span>
                    <span> {name}</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <button
                    className="DFS-feedback"
                    onClick={handleOpenFeedback}
                  >
                    <div className="DFS-vector">
                      <span>
                        <img
                          src={vectorNewIcon}
                          alt="vectorNewIcon"
                          style={{ width: "20px", height: "20px", margin: "2px" }}
                        />
                      </span>
                      <span style={{ fontWeight: 400 }}> Feedback</span>
                    </div>
                  </button>
                </li>
                <li
                  className="list-group-item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#0D1A40"
                  }}>
                  <img
                    src={logout}
                    alt="logout"
                    style={{ width: "18px", height: "18px", margin: "3px" }}
                  />
                  <button className="DFS-logout-Mobile" onClick={routeChange} data-testid="logout-button">
                    LOGOUT
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="DFS-row" style={{ position: "relative", top: "15px" }}>
        <div className="DFS-droneFlightSites-div">
          <span className="DFS-droneFlightSites-text">Drone Flight Sites</span>
        </div>
        <div className="DFS-text-bubble-div">
          {textBubble("90px", "SITES", data?.sites)}
          {textBubble("70px", "BLOCKS", data?.blocks)}
          {textBubble("20px", "COLLECTIONS", data?.collections)}
        </div>

        <div
          style={{
            position: "relative",
            top: "60px",
            right: "14%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "15px"
          }}>
          <div>
            <input
              type="text"
              onChange={handleonChange}
              onKeyDown={handleKeyDown}
              value={searchText}
              className="sites-search"
              placeholder="Search for Sites"
              style={{ paddingLeft: "25px" }}
            />
            <span className="sites-search-icons">
              {!searchText ? <Search /> : <XLg role="button" data-testid="clear-search-button" aria-label="Clear search" onClick={handleXbutton} />}
            </span>
          </div>
          <div className="droneFlights-drop">
            <Dropdown onToggle={handleToggle} onSelect={handleSelect}>
              <Dropdown.Toggle className="DFS-year-dropdown" variant="light">
                <div className="d-flex justify-content-center align-items-center">
                  <div style={{ textAlign: "left", lineHeight: "0.1px" }}>
                    <span style={{ fontSize: "14px", color: "#0D1A40" }}>{selectedOption}</span>
                    <span>
                      <ChevronDown
                        color="#0D1A40"
                        style={{
                          marginLeft: "20px",
                          width: "10px",
                          height: "16px"
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <div className="custom-dropdown-menu">
                <Dropdown.Menu
                  col-xs-12
                  className="dropdown-menu-show"
                  onChange={(e) => handleSelect(e.target.value)}>
                  <div className="selects-year">SELECT YEAR</div>
                  {yearList
                    ?.sort(function (a, b) {
                      return b - a;
                    })
                    .map((option) => (
                      <Dropdown.Item
                        key={option}
                        eventKey={option}
                        value={option}
                        onClick={() => handleSelect(option)}
                        active={selectedOption === option}>
                        <div
                          className="option-bold"
                          style={{
                            marginLeft: "18px",
                            fontSize: "12px",
                            color: selectedOption == option ? "#E08330" : "#0D1A40",
                            fontWeight: selectedOption == option ? "bold" : "normal"
                          }}>
                          {option}
                        </div>
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="custom-scrollbar">
        <Accordion activeKey={show?.toString()} className="DFS-accordion">
          {siteListData?.map((item, ItemIndex) => (
            <div key={item}>
              <Accordion.Item eventKey={ItemIndex.toString()} style={{ marginBottom: "3px" }}>
                <Accordion.Header onClick={(e) => handleLayoutToggle(ItemIndex, item?.id, e)}>
                  <div>
                    <span
                      className="DFS-accodion-heading"
                      style={{ fontWeight: show === ItemIndex ? 700 : 400 }}>
                      <span className="AccodionHeading">
                        <span style={{ fontWeight: "bold", color: "#0D1A40", fontSize: "14px" }}>
                          {item?.site}
                        </span>
                        {blockCollectionHeading(item?.block > 1 ? "Blocks" : "Block", item?.block)}
                        {blockCollectionHeading(item?.collection > 1 ? "Collections" : "Collection", item?.collection)}
                      </span>
                    </span>
                    <span className="DFS-icon-up-down">
                      {show === ItemIndex ? (
                        <ChevronUp
                          color="#0D1A40"
                          class="DFS-collapse-icon"
                          style={{ fontWeight: "bold" }}
                        />
                      ) : (
                        <ChevronDown
                          color="#0D1A40"
                          class="DFS-collapse-icon"
                          style={{ fontWeight: "bold" }}
                        />
                      )}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body style={{ position: "relative" }}>
                  <div>
                    <Container>
                      <Card className="DFS-card">
                        <Card.Body
                          className="DFS-card-body text-left "
                          ref={(el) => (accordElem.current[ItemIndex] = el)}
                          tabIndex="-1">
                          <Row Row xs={2} md={3} lg={5}>
                            {filteredCollection.map((item) => {
                              const itemsWithCollectionDataAvailable = item?.collection?.filter(
                                (data) => data?.dem_id || data?.rgb_id || data?.ndvi_id
                              );
                              const selectedPlotEditorCollection =
                                itemsWithCollectionDataAvailable?.sort(function (a, b) {
                                  return new Date(a?.date) - new Date(b?.date);
                                })?.[0]?.collection_id;
                              return (
                                <Col
                                  style={{
                                    borderRight: "1px solid #E8E8E8",
                                    borderBottom: "1px solid #E8E8E8"
                                  }}
                                  key={item?.tablename}>
                                  <div className="d-flex">
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        color: "#0D1A40",
                                        wordBreak: "break-word"
                                      }}>
                                      {item?.tablename}
                                    </div>
                                    {itemsWithCollectionDataAvailable.length > 0 && (
                                      <span
                                        style={{ marginLeft: "11px", marginTop: "10px" }}
                                        role="button"
                                        tabIndex={0}
                                        onClick={(e) =>
                                          routePlotEdior(e, selectedPlotEditorCollection)
                                        }
                                        onKeyPress={(event) => {
                                          if (event.key === "Enter" || event.key === " ") {
                                            routePlotEdior(event, selectedPlotEditorCollection);
                                          }
                                        }}>
                                        <a href={`/app/ploteditor?collection_id=${selectedPlotEditorCollection}`}>
                                          <img
                                            src={plotEditorIcon}
                                            alt="ploteditor"
                                            style={{
                                              cursor: "pointer",
                                              width: "15px",
                                              height: "16px"
                                            }}
                                          />
                                        </a>
                                      </span>
                                    )}
                                    {item?.collection?.some(
                                      ({ is_layout_submitted }) => is_layout_submitted
                                    ) && <span className="submitted-button">Submitted</span>}
                                  </div>
                                  {item?.collection
                                    .sort(function (a, b) {
                                      return new Date(a?.date) - new Date(b?.date);
                                    })
                                    .map((obj, itemIndex) => (
                                      <div
                                        key={obj.date}
                                        style={{
                                          borderBottom:
                                            itemIndex === item?.collection.length - 1
                                              ? ""
                                              : "1px solid #E8E8E8",
                                          paddingBottom:
                                            itemIndex === item?.collection.length - 1 ? "20px" : ""
                                        }}>
                                        <div className="DFS-body-date d-flex justify-content-between">
                                          {obj?.date}
                                          {obj?.dem_id === null &&
                                            obj?.rgb_id === null &&
                                            obj?.ndvi_id === null ? null : (
                                            <a href={`/app/viewer?collection_id=${obj?.collection_id}`}>
                                              <span style={{ marginLeft: "10px" }}>
                                                <button
                                                  onClick={(e) =>
                                                    routeNewViewer(e, obj?.collection_id)
                                                  }
                                                  style={{
                                                    backgroundColor: "#FFFFFF",
                                                    color: "#E08330",
                                                    border: obj?.is_assessment_available
                                                      ? "1px solid #E08330"
                                                      : "1px dashed #E08330",
                                                    borderRadius: "24px",
                                                    width: "72px"
                                                  }}>
                                                  <span>DATA</span>
                                                  <ChevronRight
                                                    color="#E08330"
                                                    className="pl-1"
                                                    height={14}
                                                    width={14}
                                                  />
                                                </button>
                                              </span>
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </Col>
                              );
                            })}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Container>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </div>
          ))}
        </Accordion>
        {searchText.length >= 1 && siteListData.length === 0 && (
          <div className="d-flex ml-3 align-items-center p-3 mb-3">
            <EmojiFrown color="#FF3F3F" />
            <span className="DFS-no-data-text ml-2">Sorry we could not find any results</span>
          </div>
        )}
      </div>
      <div>
        <FeedbackForm
          handleFeedbackClose={handleCloseFeedback}
          isShowFeedback={isShowFeedback}
          handleCloseFeedback={handleCloseFeedback}
          isDesktop={true}
          accounts={accounts}
          instance={instance}
          state={state}
          submitForm={submitForm}
        />
      </div>
    </>
  );
}
DroneFlights.propTypes = {
  accounts: propTypes.any,
  instance: propTypes.any,
  state: propTypes.any,
  submitForm: propTypes.any,
};

