import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
} from "chart.js";
import Plot from "react-plotly.js";
import { Typography, Box } from "@mui/material";
import propTypes from "prop-types";
import { barChartApi } from "../services/API";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);

function CompareChart({
  assessment1,
  assessment2,
  trialname,
  measurements,
  collectionId,
  rightDrawer,
  selectedValue
}) {
  const [barData1, setBarData1] = useState([]);
  const [barData2, setBarData2] = useState([]);
  const [noAssesment1, setNoAssesment1] = useState(false);
  const [noAssesment2, setNoAssesment2] = useState(false);
  const [noMean1, setNoMean1] = useState(false);
  const [noMean2, setNoMean2] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setNoAssesment1(false);
    setNoAssesment2(false);
    setNoMean1(false);
    setNoMean2(false);
    const trial = measurements.filter((item) => item?.name === trialname);
    if (trial.length === 0) {
      setError(true);
      setNoAssesment1(true);
      setNoAssesment2(true);
    } else {
      setError(false);
      fetchBarChartData(assessment1?.vector, trial[0]?.trial_id, collectionId, selectedValue, setNoAssesment1, setNoMean1, setBarData1);
      fetchBarChartData(assessment2?.vector, trial[0]?.trial_id, collectionId, selectedValue, setNoAssesment2, setNoMean2, setBarData2);
    }
  }, [assessment1?.vector, assessment2?.vector, trialname, collectionId, selectedValue]);

  const fetchBarChartData = (assessment, trialId, collectionId, subplotId, setNoAssessment, setNoMean, setBarData) => {
    barChartApi(
      {
        assessment,
        trial_id: trialId,
        collection_id: collectionId,
        subplot_id: selectedValue === "All subplots" || !selectedValue ? "" : selectedValue
      },
      (res) => {
        if (
          res.length <= 0 ||
          res?.meanValues.length === 0 ||
          res?.treatmentSummary?.length === 0
        ) {
          setNoAssessment(true);
          setNoMean(true);
        } else {
          setNoAssessment(false);
          setBarData(res);
        }
      },
      (err) => {
        if (err === "Request failed with status code 403") {
          setError(true);
        }
      }
    );
  };

  const bar = new Set();

  const meanV1 = [];
  if (barData1?.meanValues) {
    barData1?.meanValues
      .sort((a, b) => a?.num - b?.num)
      .map((obj) => {
        bar.add(obj?.num);

        meanV1.push(parseFloat(obj?.value).toFixed(2));
      });
  }

  const meanV2 = [];
  if (barData2?.meanValues) {
    barData2?.meanValues
      .sort((a, b) => a?.num - b?.num)
      .map((obj) => {
        meanV2.push(parseFloat(obj?.value).toFixed(2));
      });
  }

  const min1 = Math.min(...meanV1);
  const max1 = Math.max(...meanV1);
  const min2 = Math.min(...meanV2);
  const max2 = Math.max(...meanV2);

  const trace1 = {
    type: "bar",
    x: Array.from(bar),
    y: meanV1,
    yaxis: "y",
    offsetgroup: 1,
    customdata: meanV1,
    marker: { color: "#462255" },
    hovertemplate: "%{x} <br> %{fullData.name}:%{y}<extra></extra>",
    textposition: "auto",
    insidetextanchor: "start",
    textangle: 0,
    name: barData1?.name?.split("v:")[0]
  };
  const trace2 = {
    type: "bar",
    x: Array.from(bar),
    y: meanV2,
    yaxis: "y2",
    offsetgroup: 2,
    customdata: meanV2,
    marker: { color: "#FB6107" },
    hovertemplate: "%{x} <br> %{fullData.name}:%{y}<extra></extra>",
    textposition: "auto",
    insidetextanchor: "start",
    textangle: 0,
    name: barData2?.name?.split("v:")[0]
  };

  const plotData = [trace1, trace2];

  const isAssessmentDataUnavailable = (noAssesment1 && !noAssesment2) || (!noAssesment1 && noAssesment2);
  const isMeanValuesZero = (min1 === 0 && max1 === 0) || (min2 === 0 && max2 === 0);
  const isMeanDataUnavailable = noMean1 || noMean2;
  const shouldDisplayErrorMessage = isAssessmentDataUnavailable || isMeanValuesZero || isMeanDataUnavailable || error;
  const boxHeight = window.innerHeight < 440 ? "150px" : "320px";
  const noRightDrawerWidth = !rightDrawer ? "73vw" : "60vw";
  const boxWidth = window.innerWidth < 440 ? "90vw" : noRightDrawerWidth;
  return shouldDisplayErrorMessage ? (
    <Typography
      variant="title"
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <strong>Assessment data is unavailable</strong>
    </Typography>
  ) : (
    <Box
      sx={{
        height: boxHeight,
        width: boxWidth,
        overflowY: "hidden",
        overflowX: "hidden"
      }}
    >
      <Plot
        style={{ cursor: "pointer" }}
        data={plotData}
        layout={{
          barmode: "group",
          autosize: true,
          margin: {
            t: 40,
            b: window.innerHeight < 440 ? 350 : 200
          },
          legend: {
            orientation: "h",
            usePointStyle: true,
            xanchor: "center",
            x: 0.5,
            y: 1.3,
            font: {
              family: "Helvetica",
              size: 12
            }
          },
          xaxis: {
            type: "category",
            linecolor: "#E8E8E8",
            tickvals: Array.from(bar),
            tickfont: {
              size: window.innerWidth < 440 && 8
            },
            title: {
              text: window.innerHeight > 440 ? "Treatments" : ""
            },
            titlefont: {
              family: "Helvetica",
              size: 12
            }
          },
          yaxis: {
            rangemode: "tozero",
            zerolinecolor: "#E8E8E8",
            linecolor: "#E8E8E8",
            title: {
              text: window.innerHeight > 440 ? "Mean Values" : ""
            },
            titlefont: {
              family: "Helvetica",
              size: 12
            }
          },
          yaxis2: {
            overlaying: "y",
            rangemode: "tozero",
            side: "right",
            title: {
              text: window.innerHeight > 440 ? "Mean Values" : ""
            },
            titlefont: {
              family: "Helvetica",
              size: 12
            }
          },

          responsive: true
        }}
        config={{
          staticPlot: false,
          displaylogo: false,
          responsive: true,
          animation: false,
          modeBarButtonsToRemove: [
            "pan2d",
            "select2d",
            "autoScale2d",
            "hoverClosestCartesian",
            "zoom2d",
            "lasso2d"
          ]
        }}
      />
    </Box>
  );
}

CompareChart.propTypes = {
  assessment1: propTypes.any,
  assessment2: propTypes.any,
  trialname: propTypes.any,
  measurements: propTypes.any,
  collectionId: propTypes.any,
  selectedValue: propTypes.string,
  rightDrawer: propTypes.any
};

export default React.memo(CompareChart);
