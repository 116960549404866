import "../assests/Styles/charts.css";
import propTypes from "prop-types";
export default function CustomLegend({
  plotData,
  onToggleTrace,
  isTablet,
}) {
  return (
      <div
        className={`custom-legend ${isTablet ? "ml-4" : "ml-3"}`}
      >
        {plotData.map((trace, index) => (
          <div
            key={trace?.name}
            className="custom-legend-item"
            style={{ opacity: trace.visible === true ? 1 : 0.5 }}
          >
            <div
              className="custom-legend-symbol"
              style={{ backgroundColor: trace.marker ? trace.marker.color : "#444" }}
            ></div>
            <div className="custom-legend-text">
              <button
                onClick={() => onToggleTrace(index)}
                style={{ fontFamily: "sans-serif", border:"none", background:"white", color: "#0D1A40" }}
              >
                {trace?.name ? trace?.name : ""}
              </button>
            </div>
          </div>
        ))}
      </div>
  );
}

CustomLegend.propTypes = {
  plotData: propTypes.any,
  onToggleTrace: propTypes.any,
  isTablet: propTypes.any
};
