import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Fullscreen } from "react-bootstrap-icons";
import propTypes from "prop-types";
export default function WebTools({
  ShowFullMap,
  measurementToolVisible,
  switchMeasurementTool,
  measurementIcon,
  droneExpand,
}) {
  return (
    <div
      className="web-tools-container"
      style={{
        backgroundColor: "#ddd",
        position: "absolute",
        bottom: window.innerWidth > window.innerHeight ? "130px" : "12px",
        zIndex: droneExpand === true ? 998 : 997
      }}>
      <Button
        title="Show full map"
        className="d-flex align-items-center tools-button"
        onClick={ShowFullMap}>
        <Fullscreen color="#0D1A40" height={18} width={18} />
      </Button>
      <Button
        title="Measurement tool"
        className={`d-flex p-1 align-items-center tools-button measurement-icon ${measurementToolVisible ? "isGrey" : "isWhite"
          }`}
        onClick={() => switchMeasurementTool(!measurementToolVisible)}>
        <img src={measurementIcon} width={30} alt="" />
      </Button>
    </div>
  );
}
WebTools.propTypes = {
  ShowFullMap: propTypes.any,
  measurementToolVisible: propTypes.any,
  switchMeasurementTool: propTypes.any,
  measurementIcon: propTypes.any,
  droneExpand: propTypes.any,
};
