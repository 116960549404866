import { CircularProgress, Stack, Divider, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import propTypes from "prop-types";
import {
  getAllTrialSummaryData,
  loginSyt,
  getAllTrialAssesmentData,
  getAllFilterData,
  edcGetAccessToken
} from "../services/API";
import { formatDate, hoverDateFormatForTrial } from "../services/Common";
import Plot from "react-plotly.js";
import { colors } from "../services/Common";
function TrailSummary({ trail, droneFlightDates }) {
  const [trailSummaryData, setTrailSummaryData] = useState([]);
  const [trailAssesmentData, setTrailAssesmentData] = useState([]);
  const [allData, setAllData] = useState();
  const [loading, setLoading] = useState(true);
  const idToken = sessionStorage.getItem("idToken");
  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          edcGetAccessToken((res) => {
            if (res?.id_token !== "") {
              getAllTrialSummaryData(
                trail,
                res?.id_token,
                (data) => {
                  setTrailSummaryData(data);
                  setLoading(false);
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                }
              );
              getAllTrialAssesmentData(
                trail,
                res?.id_token,
                (data) => {
                  setTrailAssesmentData(data);
                  setLoading(false);
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                }
              );
              getAllFilterData(
                {
                  filters: [
                    {
                      comparator: "LIKE",
                      field: "id",
                      value: trail
                    }
                  ]
                },
                res?.id_token,
                (data) => {
                  setAllData(data);
                },
                (err) => {
                  console.log("err", err);
                  setLoading(false);
                }
              );
            }
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [trail]);

  const formatDateWithoutHour = (date) => {
    return date && date.indexOf("T") > -1 ? formatDate(date) : date;
  };

  let applicationData = [];
  let plantingData = [];
  let visualData = [];
  let cropGrowthData = [];
  let x = new Set();
  let y = new Set();
  let droneXaxis = new Set();
  let droneYaxis = [];
  let droneDataOnHover = new Set();
  let ratingTypes = new Set();
  let plantDataHover = [];
  let maintainenceData = [];
  let alertsData = [];
  let maintenanceDataOnHover = new Set();

  droneFlightDates.forEach((item) => {
    let date = item?.x.split("-");
    let dateString = date[0] + "-" + date[1] + "-" + date[2];
    droneDataOnHover.add(hoverDateFormatForTrial(dateString));
    x.add(dateString);
    y.add(item?.y);
    droneXaxis.add(dateString);
    droneYaxis.push(item?.y);
  });

  let aph = new Set();

  trailSummaryData &&
    trailSummaryData?.trialApplication &&
    trailSummaryData?.trialApplication.forEach((app) => {
      if (app?.applicationDate !== null) {
        let date = formatDateWithoutHour(app?.applicationDate)?.split("-");
        let dateString = date && date[0] + "-" + date[1] + "-" + date[2];
        let hoverText = new Date(dateString).toString().split(" ");
        let droneHoverText = hoverText[1] + " " + hoverText[2] + ", " + hoverText[3];
        aph.add(droneHoverText);
        x.add(formatDateWithoutHour(app?.applicationDate));
        y.add("Application");
        applicationData.push({
          id: app?.id,
          x: formatDateWithoutHour(app?.applicationDate),
          y: "Application"
        });
      }
    });

  trailSummaryData &&
    trailAssesmentData?.trialAssessmentDef &&
    trailAssesmentData?.trialAssessmentDef.forEach((item) => {
      if (item?.crop?.plantingDate !== null) {
        let date = formatDateWithoutHour(item?.crop?.plantingDate).split("-");
        let dateString = date[0] + "-" + date[1] + "-" + date[2];
        plantDataHover.push(hoverDateFormatForTrial(dateString));
        x.add(formatDateWithoutHour(item?.crop?.emergenceDate));
        x.add(formatDateWithoutHour(item?.crop?.plantingDate));
        y.add("Planting Date");
        plantingData.push({
          x: formatDateWithoutHour(item?.crop?.plantingDate),
          y: "Planting Date"
        });
      }
    });

  let max = new Set();
  max.add(0);
  trailSummaryData &&
    trailAssesmentData?.trialAssessmentDef &&
    trailAssesmentData?.trialAssessmentDef.forEach((item) => {
      if (item?.cropStage?.scale === "BBCH" && item?.cropStage?.majority !== null) {
        max.add(item?.cropStage?.majority);
        cropGrowthData.push({
          x: formatDateWithoutHour(item?.ratingDate),
          y: item?.cropStage?.majority
        });
      }
    });
  trailSummaryData &&
    trailSummaryData?.trialSummary?.generalTrial &&
    trailSummaryData?.trialSummary?.generalTrial?.initiationDate !== null;
  x.add(formatDateWithoutHour(trailSummaryData?.trialSummary?.generalTrial?.initiationDate));

  let vsh = new Set();
  trailAssesmentData &&
    trailAssesmentData?.trialAssessmentDef &&
    trailAssesmentData?.trialAssessmentDef.forEach((item) => {
      if (item?.ratingDate !== null && item?.ratingType !== null) {
        let date = formatDateWithoutHour(item?.ratingDate).split("-");
        let dateString = date[0] + "-" + date[1] + "-" + date[2];
        let hoverText = new Date(dateString).toString().split(" ");
        let droneHoverText = hoverText[1] + " " + hoverText[2] + ", " + hoverText[3];
        vsh.add(droneHoverText);
        x.add(formatDateWithoutHour(item?.ratingDate));
        y.add(item?.ratingType);
        ratingTypes.add(item?.ratingType);
        visualData.push({
          x: formatDateWithoutHour(item?.ratingDate),
          y: item?.ratingType
        });
      }
    });

  const individualSets = (data) => {
    const groupData = {};
    data.forEach((item) => {
      const { x, y } = item;
      if (!groupData[y]) {
        groupData[y] = [];
      }
      groupData[y].push({ x, y });
    });
    return Object.values(groupData);
  };

  const visualdataGroup = visualData && individualSets(visualData);

  const uniqueVisualData = [];
  visualdataGroup.forEach((item) => {
    const hasSeen = new Set();
    const uniqueData = [];

    item.forEach((obj) => {
      const data = JSON.stringify(obj);
      if (!hasSeen.has(data)) {
        hasSeen.add(data);
        uniqueData.push(obj);
      }
    });
    uniqueVisualData.push(uniqueData);
  });

  trailSummaryData &&
    trailSummaryData?.maintenances &&
    trailSummaryData?.maintenances.forEach((item) => {
      if (item?.date !== null) {
        let dateString = formatDateWithoutHour(item?.date).split(" ")[0];
        let hoverText = new Date(dateString).toString().split(" ");
        let maintenanceText =
          hoverText[2] + " " + hoverText[1].toUpperCase() + " , " + hoverText[3];
        maintenanceDataOnHover.add(maintenanceText);
        maintainenceData.push({
          x: formatDateWithoutHour(item?.date).split(" ")[0],
          y: "Maintenance",
          id: item?.maintenanceId
        });
      }
    });

  allData?.contents.forEach((item) => {
    if (item?.notes && item?.notes.length > 0) {
      item?.notes.forEach((obj) => {
        let dateString = obj?.date !== null && formatDateWithoutHour(obj?.noteDate);
        let hoverText = new Date(dateString).toString().split(" ");
        let AlertDate = hoverText[1] + " " + hoverText[2] + ", " + hoverText[3];
        let noteText = obj?.content.split(":");
        let AlertText = noteText.slice(1, noteText.length).join(" ");
        alertsData.push({ date: dateString, content: AlertText, convertedDate: AlertDate });
      });
    }
  });

  alertsData.sort((a, b) => new Date(a?.date) - new Date(b?.date)).reverse();

  const alertsGrouping = {};
  alertsData.forEach((item) => {
    const { convertedDate, content } = item;
    if (!alertsGrouping[convertedDate]) {
      alertsGrouping[convertedDate] = [];
    }
    alertsGrouping[convertedDate].push({ content });
  });

  const dataWithTwoorMoreIds = (data) => {
    const md = {};
    data.forEach((item) => {
      const key = `${item?.x}-${item?.y}`;
      if (md[key]) {
        md[key].ids.push(item?.id);
      } else {
        md[key] = {
          x: item?.x,
          y: item?.y,
          ids: [item?.id]
        };
      }
    });
    return Object.values(md);
  };

  const trace = [];

  const MaintainenceTrace = {
    x: dataWithTwoorMoreIds(maintainenceData).map(
      (item) => (item?.x !== undefined || item?.x !== null) && item?.x
    ),
    y: dataWithTwoorMoreIds(maintainenceData).map(
      (item) => (item?.y !== undefined || item?.y !== null) && item?.y
    ),
    text: dataWithTwoorMoreIds(maintainenceData).map((item) =>
      (item?.ids !== undefined || item?.ids !== null || item?.ids.length !== 0) &&
        item?.ids.length <= 1
        ? item?.ids.join("")
        : Array.from(new Set(item?.ids)).join("+")
    ),
    mode: "markers+text",
    type: "scatter",
    marker: { size: 12, color: "#006D82" },
    customdata: Array.from(maintenanceDataOnHover),
    hovertemplate: "%{x}<extra></extra>",
    textposition: "top center",
    yaxis: "y1",
    showlegend: false
  };

  trace.push(MaintainenceTrace);
  const applicationTrace = {
    x: dataWithTwoorMoreIds(applicationData).map(
      (item) => (item?.x !== undefined || item?.x !== null) && item?.x
    ),
    y: dataWithTwoorMoreIds(applicationData).map(
      (item) => (item?.y !== undefined || item?.y !== null) && item?.y
    ),
    text: dataWithTwoorMoreIds(applicationData).map((item) =>
      (item?.ids !== undefined || item?.ids !== null || item?.ids.length !== 0) &&
        item?.ids.length <= 1
        ? item?.ids.join("")
        : item?.ids.join("+")
    ),
    mode: "markers+text",
    type: "scatter",
    marker: { size: 12, color: "#859940" },
    customdata: Array.from(aph),
    hovertemplate: "%{x}<extra></extra>",
    textposition: "bottom center",
    yaxis: "y2",
    showlegend: false
  };
  trace.push(applicationTrace);

  const plantingTrace = {
    x: plantingData.map((item) => (item?.x !== undefined || item?.x !== null) && item?.x),
    y: plantingData.map((item) => (item?.y !== undefined || item?.y !== null) && item?.y),
    mode: "markers",
    type: "scatter",
    marker: { size: 12, color: "#FF7373" },
    customdata: Array.from(plantDataHover),
    hovertemplate: "%{x}<extra></extra>",
    yaxis: "y3",
    showlegend: false
  };
  trace.push(plantingTrace);

  const cropGrowthTrace = {
    x: cropGrowthData.map((item) => (item?.x !== undefined || item?.x !== null) && item?.x),
    y: cropGrowthData.map((item) => (item?.y !== undefined || item?.y !== null) && item?.y),
    mode: "markers",
    type: "scatter",
    hovertemplate: "%{y}<br>%{x}<extra></extra>",
    marker: { size: 12, color: "#3EB62A" },
    yaxis: "y4",
    showlegend: false
  };
  trace.push(cropGrowthTrace);

  const tickValues = Array.from(ratingTypes);
  const tickText = tickValues.map((val, index) => {
    let tickColor = colors[index];
    if (val === "CONTRO") {
      // eslint-disable-next-line quotes
      return `<span style="color:#5A7FFF">Contro</span>`;
    } else if (val === "PHYGEN") {
      // eslint-disable-next-line quotes
      return `<span style="color:#FFA841">Phygen</span>`;
    } else return `<span style="color:${tickColor}">${val}</span>`;
  });

  uniqueVisualData.forEach((item, index) => {
    const vsData = new Set(item.map((obj) => obj?.y !== undefined && obj?.y));
    trace.push({
      x: item.map((obj) => obj?.x !== undefined && obj?.x),
      y: item.map((obj) => obj?.y !== undefined && obj?.y),
      mode: "markers",
      type: "scatter",
      customdata: Array.from(vsh),
      hovertemplate: "%{x}<extra></extra>",
      marker: {
        size: 12,
        color:
          Array.from(vsData).length > 0 &&
          (Array.from(vsData)[0] === "CONTRO"
            ? "#5A7FFF"
            : Array.from(vsData)[0] === "PHYGEN"
              ? "#FFA841"
              : colors[index])
      },
      yaxis: "y5",
      showlegend: false
    });
  });

  const droneTrace = {
    x: Array.from(droneXaxis),
    y: Array.from(droneYaxis),
    mode: "markers",
    type: "scatter",
    customdata: Array.from(droneDataOnHover),
    hovertemplate: "%{x}<extra></extra>",
    marker: { size: 12, color: "#A64BEE" },
    showlegend: false,
    yaxis: "y6"
  };
  trace.push(droneTrace);

  const layout = {
    margin: {
      t: 15,
      r: 0,
      l: window.innerWidth > 440 ? 130 : 90,
      b: 35
    },
    autosize: true,
    xaxis: {
      type: "date",
      linecolor: "#E8E8E8",
      tickformat: "%e %b <br>%Y"
    },
    yaxis: {
      domain: maintainenceData.length > 0 && [0, 0.12],
      showticklabels: maintainenceData.length === 0 ? false : true,
      linecolor: "#E8E8E8"
    },
    yaxis2: {
      domain: applicationData.length > 0 && [0.12, 0.24],
      linecolor: "#E8E8E8",
      showticklabels: applicationData.length === 0 ? false : true,
      zerolinecolor: "#E8E8E8"
    },
    yaxis3: {
      rangemode: "tozero",
      zerolinecolor: "#E8E8E8",
      domain: applicationData.length > 0 ? [0.24, 0.3] : [0.12, 0.2],
      tickfont: {
        color: plantingData.length > 0 && "FF7373"
      },
      showticklabels: plantingData.length === 0 ? false : true,
      linecolor: "#E8E8E8"
    },
    yaxis4: {
      zerolinecolor: "#E8E8E8",

      title: {
        text: cropGrowthData.length > 0 && "Growth<br> Stage",
        font: {
          size: 12,
          color: "#3EB62A"
        },
        standoff: 25
      },
      domain:
        applicationData.length > 0 && plantingData.length > 0
          ? [0.3, 0.6]
          : applicationData.length > 0 && plantingData.length === 0
            ? [0.24, 0.54]
            : applicationData.length === 0 && plantingData.length > 0
              ? [0.18, 0.58]
              : applicationData.length === 0 && plantingData.length === 0 && [0.12, 0.42],
      showticklabels: cropGrowthData.length === 0 ? false : true,
      linecolor: "#E8E8E8"
    },
    yaxis5: {
      title: {
        text: uniqueVisualData.length > 0 && "Visual<br>Assessment",
        font: {
          size: 12,
          color: "#AAAAAA"
        },
        standoff: 13
      },

      domain:
        applicationData.length > 0 && plantingData.length > 0 && cropGrowthData.length > 0
          ? [0.6, 0.9]
          : applicationData.length === 0 && plantingData.length > 0 && cropGrowthData.length > 0
            ? [0.48, 0.78]
            : applicationData.length > 0 && plantingData.length === 0 && cropGrowthData.length > 0
              ? [0.54, 0.84]
              : applicationData.length > 0 && plantingData.length > 0 && cropGrowthData.length === 0
                ? [0.3, 0.6]
                : applicationData.length === 0 && plantingData.length === 0 && cropGrowthData.length > 0
                  ? [0.42, 0.92]
                  : applicationData.length === 0 && plantingData.length > 0 && cropGrowthData.length === 0
                    ? [0.18, 0.48]
                    : applicationData.length > 0 && plantingData.length === 0 && cropGrowthData.length === 0
                      ? [0.24, 0.54]
                      : [0.12, 0.42],
      tickvals: tickValues,
      ticktext: tickText,
      linecolor: "#E8E8E8",
      showticklabels: uniqueVisualData.length === 0 ? false : true
    },
    yaxis6: {
      rangemode: "tozero",
      domain:
        applicationData.length > 0 &&
          plantingData.length > 0 &&
          cropGrowthData.length > 0 &&
          uniqueVisualData.length > 0
          ? [0.9, 1]
          : applicationData.length === 0 &&
            plantingData.length > 0 &&
            cropGrowthData.length > 0 &&
            uniqueVisualData.length > 0
            ? [0.78, 0.88]
            : applicationData.length > 0 &&
              plantingData.length === 0 &&
              cropGrowthData.length > 0 &&
              uniqueVisualData.length > 0
              ? [0.84, 0.94]
              : applicationData.length > 0 &&
                plantingData.length > 0 &&
                cropGrowthData.length === 0 &&
                uniqueVisualData.length > 0
                ? [0.6, 0.7]
                : applicationData.length > 0 &&
                  plantingData.length > 0 &&
                  cropGrowthData.length > 0 &&
                  uniqueVisualData.length === 0
                  ? [0.6, 0.7]
                  : applicationData.length === 0 &&
                    plantingData.length === 0 &&
                    cropGrowthData.length > 0 &&
                    uniqueVisualData.length > 0
                    ? [0.72, 0.82]
                    : applicationData.length === 0 &&
                      plantingData.length > 0 &&
                      cropGrowthData.length === 0 &&
                      uniqueVisualData.length > 0
                      ? [0.48, 0.58]
                      : applicationData.length === 0 &&
                        plantingData.length > 0 &&
                        cropGrowthData.length > 0 &&
                        uniqueVisualData.length === 0
                        ? [0.48, 0.58]
                        : applicationData.length > 0 &&
                          plantingData.length === 0 &&
                          cropGrowthData.length === 0 &&
                          uniqueVisualData.length > 0
                          ? [0.54, 0.64]
                          : applicationData.length > 0 &&
                            plantingData.length === 0 &&
                            cropGrowthData.length > 0 &&
                            uniqueVisualData.length === 0
                            ? [0.54, 0.64]
                            : applicationData.length > 0 &&
                              plantingData.length > 0 &&
                              cropGrowthData.length === 0 &&
                              uniqueVisualData.length === 0
                              ? [0.3, 0.4]
                              : applicationData.length === 0 &&
                                plantingData.length === 0 &&
                                cropGrowthData.length === 0 &&
                                uniqueVisualData.length > 0
                                ? [0.48, 0.58]
                                : applicationData.length === 0 &&
                                  plantingData.length > 0 &&
                                  cropGrowthData.length === 0 &&
                                  uniqueVisualData.length === 0
                                  ? [0.18, 0.28]
                                  : applicationData.length > 0 &&
                                    plantingData.length === 0 &&
                                    cropGrowthData.length === 0 &&
                                    uniqueVisualData.length === 0
                                    ? [0.24, 0.34]
                                    : [0.12, 0.22],
      tickfont: {
        color: "#A64BEE"
      },
      linecolor: "#E8E8E8"
    },
    responsive: true
  };
  return (
    <>
      {loading ? (
        <Stack
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress
            size={80}
            thickness={6}
            sx={{
              color: "green",
              "--CircularProgress-size": "80px",
              top: "10%",
              left: "50%"
            }}
          />
        </Stack>
      ) : (
        <>
          {Object.values(alertsGrouping).length > 0 && (
            <div style={{ marginBottom: "10px", marginTop: "0px" }}>
              <div
                style={{
                  borderRadius: "25px",
                  background: "#F9DCDC",
                  display: "inline-block",
                  padding: "8px"
                }}>
                <Typography sx={{ fontSize: "12px", fontFamily: "Noto Sans", color: "#C20000" }}>
                  {Object.keys(alertsGrouping)[0]}
                </Typography>

                {Object.values(alertsGrouping).length > 0 &&
                  Object.values(alertsGrouping)[0].map((item, key) => (
                    <Typography
                      key={key}
                      sx={{ fontSize: "14px", fontFamily: "Noto Sans", color: "#C20000" }}>
                      {item?.content}
                    </Typography>
                  ))}
              </div>
            </div>
          )}
          <Divider />
          <div>
            <Plot
              style={{ width: "100%" }}
              data={trace}
              layout={layout}
              config={{
                staticPlot: false,
                displaylogo: false,
                animation: false,
                modeBarButtonsToRemove: [
                  "pan2d",
                  "select2d",
                  "autoScale2d",
                  "hoverClosestCartesian",
                  "zoom2d",
                  "lasso2d"
                ]
              }}></Plot>
          </div>
        </>
      )}
    </>
  );
}

TrailSummary.propTypes = {
  trail: propTypes.any,
  droneFlightDates: propTypes.any
};

export default TrailSummary;
