import { useState, useRef } from "react";
import {
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
  Button,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import { ChevronDown, ChevronRight, InfoCircleFill, Search, X } from "react-bootstrap-icons";
import propTypes from "prop-types";
//import leftRightCircleIcon from "../assests/Styles/assets/leftRightArrowCircleIcon.svg";
import leftRightArrowGreenCircleIcon from "../assests/Styles/assets/leftRightArrowCircleIcon1.svg";
import droneIcon from "../assests/Styles/assets/drone.svg";
import droneIconBlack from "../assests/Styles/assets/droneIconBlack.svg";
import loanVesselIcon from "../assests/Styles/assets/loanVesselIcon.svg";
import loanVesselIconfaint from "../assests/Styles/assets/loanVesselIconfaint.svg";
import { customTabletDimensions, formatFlightDate } from "../services/Common";
import Assessmentdatecombinations from "./Assessmentdatecombinations";
import Clipboard from "../assests/Styles/assets/clipboard.svg";

const orderArray = ["A", "B", "C", "D", "E", "F"];

function AssessmentDropDown({
  assessmentContainerVisible,
  selectedOption,
  assessmentBy,
  setAssessmentBy,
  isMobile,
  isTablet,
  isMobileLandscape,
  isDesktop,
  assessmentDatesAndTypes,
  assessmentsFilteredByType,
  handleAssessmentSelect,
  assessmentDateType,
  setAssessmentDateType,
  isAssessmentDropdown,
  handleAsessmentDropdown,
  setShowTreatment,
  setShowTime,
  setShowCompare,
  setShowTreatmentMT,
  setShowTimeMT,
  setShowCompareMT,
  setAssessmentOptionsVisible,
  setSelectedCombinationForHeatMap,
  selectedCombinationForHeatMap,
  selectedCombinationForEyeOpen,
  setSelectedCombinationForEyeOpen,
  showTreatment,
  showTime,
  showCompare,
  setTreatmentDataLoaded,
  setFightDataLoaded,
  setCompareData1Loaded,
  setCompareData2Loaded,
  dropdownassessmentdatecombination,
  handleRatingDescription,
  isShowRatingdescription,
  setSelectedTrialInfoForEyeOpen,
  setNavbarButtons,
  navbarButtons,
  showTabletTooltip,
  setShowTabletTooltip,
  targetAssTabletRef,
  dateTypeUrl
}) {
  const isMax6Item = assessmentDateType?.length >= 6;
  const [searchValue, setSearchValue] = useState("");
  const [assessmentDropdownButton, setAssessmentDropdownButton] = useState(false);
  const assessmentDropdownRef = useRef(null);
  const assessmentButtonClickRef = useRef(null);
  // const [selectText, setSelectText] = useState(false);
  const mobileLandscapeAlignments = isMobileLandscape
    ? "col-5"
    : "col-md-3 col-lg-2 col-xl-2 col-xxl-2 col-sm-3 d-flex align-items-center";
  const assessmentContainerVisibleColor = !assessmentContainerVisible ? "white" : "#FFE8BC";
  const assessmentContainerVisibleColorForTablet =
    isTablet && isAssessmentDropdown ? "#FFE8BC" : "white";

  const renderAssessmentTooltip = (props) => (
    <Tooltip id="tooltip" {...props} className="newviewer-buttons-tooltip">
      <div className="d-flex align-items-center justify-content-between ">
        <span style={{ marginLeft: "8px", marginRight: "15px" }}>
          <InfoCircleFill height={20} width={20} />
        </span>
        <span className="mr-4 font-size-small font-weight-700 ">
          Please select a trial to enable Assessments
        </span>
        {customTabletDimensions() && (
          <span>
            <X height={24} width={24} />
          </span>
        )}
      </div>
    </Tooltip>
  );

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       assessmentDropdownRef.current &&
  //       !assessmentDropdownRef.current.contains(event.target) &&
  //       !assessmentButtonClickRef.current.contains(event.target)
  //     ) {
  //       setSelectText(true);
  //     }
  //   };
  //   window.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     window.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <div
      className={isMobile && !customTabletDimensions() ? "col-5" : mobileLandscapeAlignments}
      style={{
        padding: isDesktop ? "5px 10px" : "0px",
        borderRight: !isMobileLandscape ? "1px solid #EDEDED" : "none",
        backgroundColor:
          isMobile || isMobileLandscape
            ? assessmentContainerVisibleColor
            : assessmentContainerVisibleColorForTablet,
        height: "60px"
        // marginRight: "-12px"
      }}>
      <Dropdown
        className={`assessment-main-dropdown ${
          assessmentDateType?.length > 0 || assessmentDropdownButton
            ? "assessment-main-dropdown-tablet"
            : ""
        }`}
        show={isAssessmentDropdown}
        onToggle={handleAsessmentDropdown}
        style={{ backgroundColor: "transparent !important" }}>
        <OverlayTrigger
          placement="bottom"
          ref={targetAssTabletRef}
          trigger={["hover"]}
          onHide={() => setShowTabletTooltip(false)}
          onToggle={() => setShowTabletTooltip(true)}
          overlay={
            !selectedOption ||
            (["All trials", "No trials"].includes(selectedOption) && showTabletTooltip) ? (
              renderAssessmentTooltip
            ) : (
              <></>
            )
          }>
          <Dropdown.Toggle
            className="dropdown-toggle assessment-tab d-flex"
            style={{ backgroundColor: "transparent !important" }}
            disabled={!selectedOption || ["All trials", "No trials"].includes(selectedOption)}
            variant="light">
            <p style={{ marginBottom: 0 }}>Assessments</p>
            {assessmentDateType?.length > 0 && (
              <span
                className="pl-3"
                style={{
                  fontSize: "16px",
                  fontWeight: "600"
                }}>
                {assessmentDateType?.length}
              </span>
            )}
            <span>
              <ChevronDown
                className="down-icon"
                style={{ width: "100%", height: "16px", marginLeft: isTablet && "16px" }}
              />
            </span>
          </Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu
          col-xs-12
          className="tablet-custom-menu"
          style={{
            width:
              assessmentDateType?.length > 0 || assessmentDropdownButton
                ? isTablet || customTabletDimensions()
                  ? "620px"
                  : "665px"
                : isTablet || customTabletDimensions()
                ? "330px"
                : "360px",
            minHeight: "400px",
            maxHeight: isTablet || customTabletDimensions() ? "585px" : "480px"
            // overflowY: "auto"
          }}>
          <div
            style={{
              width:
                assessmentDateType?.length > 0 || assessmentDropdownButton
                  ? isTablet || customTabletDimensions()
                    ? "620px"
                    : "660px"
                  : isTablet || customTabletDimensions()
                  ? "330px"
                  : "360px",
              maxHeight: isTablet || customTabletDimensions() ? "585px" : "480px",
              overflowY: "scroll",
              overflowX: "hidden",
              cursor: "pointer"
            }}>
            <div
              style={{
                width: isTablet || customTabletDimensions() ? "330px" : "360px",
                position: "relative"
              }}>
              <div
                style={{
                  position: "absolute",
                  left: isTablet || customTabletDimensions() ? "330px" : "360px",
                  border: "1px solid #F9F9F9"
                }}>
                {/* {selectText && (assessmentDateType?.length > 0 || assessmentDropdownButton) ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "290px",
                      height: "304px",
                      backgroundColor: "transparent",
                      color: "#AAA9A9",
                      fontSize: "12px",
                      fontFamily: "Helvetica"
                    }}>
                    {assessmentBy === "date"
                      ? "Select Assessments Type"
                      : "Select Assessments Date"}
                  </div>
                ) : null} */}
              </div>
              <div className="d-flex pt-3 pl-3">
                <span>{AssessmentMessage}</span>
                <Button
                  title="Rating Description"
                  disabled={!selectedOption || ["All trials", "No trials"].includes(selectedOption)}
                  className="d-flex align-items-center"
                  style={{
                    zIndex: isShowRatingdescription ? 9999 : 99,
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    padding: "0px 10px",
                    boxShadow: "none"
                  }}
                  onClick={handleRatingDescription}>
                  <img src={Clipboard} height={24} width={24} alt="" />
                </Button>
              </div>

              <div>
                <AssessmentBySwitch
                  assessmentBy={assessmentBy}
                  setAssessmentBy={setAssessmentBy}
                  assessmentDateType={assessmentDateType}
                  setAssessmentDateType={setAssessmentDateType}
                  setSearchValue={setSearchValue}
                  setShowTreatment={setShowTreatment}
                  setShowTime={setShowTime}
                  setShowCompare={setShowCompare}
                  setShowTreatmentMT={setShowTreatmentMT}
                  setShowTimeMT={setShowTimeMT}
                  setShowCompareMT={setShowCompareMT}
                  setAssessmentOptionsVisible={setAssessmentOptionsVisible}
                  dateTypeUrl={dateTypeUrl}
                />
              </div>

              <div
                className="assessment-scroll"
                style={{ maxHeight: "220px", overflowY: "auto", height: "266px" }}>
                {assessmentBy === "date" ? (
                  <>
                    {assessmentDatesAndTypes?.map((assessment) => {
                      const haschildSelected = assessmentDateType?.filter(
                        (i) => i.date === assessment?.assessmentdate
                      );
                      return (
                        <div key={assessment?.assessmentdate} ref={assessmentDropdownRef}>
                          <DropdownButton
                            drop="end"
                            variant="Light"
                            onClick={() => {
                              setAssessmentDropdownButton(true);
                            }}
                            className={`assessment-dropdown ${
                              isTablet || customTabletDimensions()
                                ? "assessment-dropdown-tablet"
                                : ""
                            }`}
                            title={
                              <span
                                ref={assessmentButtonClickRef}
                                className="d-flex justify-content-between align-items-center"
                                style={{ height: "49px" }}>
                                <span className="assessment-option" style={{ width: "100px" }}>
                                  {formatFlightDate(assessment?.assessmentdate)}
                                </span>
                                <div style={{ width: "16px" }}>
                                  {assessment?.isDrone && <img src={droneIconBlack} alt="" />}
                                </div>
                                <div style={{ width: "16px" }}>
                                  {assessment?.isManual && <img src={loanVesselIcon} alt="" />}
                                </div>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    width: "18px"
                                  }}>
                                  {haschildSelected.length > 0 && `(${haschildSelected.length})`}
                                </span>
                                <ChevronRight color="#0D1A40" height={8} />
                              </span>
                            }
                            id={"nested-dropdown-button-drop-end"}
                            style={{
                              boxShadow: "3px",
                              width: "100%"
                            }}>
                            <div
                              className="p-2"
                              style={{
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #F9F9F9 !important"
                              }}>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <InputGroup className="AssessmentSearchInputGroup">
                                    <FormControl
                                      type="text"
                                      placeholder="Search for ratings"
                                      className={
                                        isDesktop
                                          ? "RatingModalSearch mt-0 position-realtive"
                                          : "MobileRatingModalSearch mt-0 position-realtive"
                                      }
                                      value={searchValue}
                                      onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                    {searchValue ? (
                                      <X
                                        onClick={() => setSearchValue("")}
                                        className="assessment-search-icon1"
                                        width={15}
                                        height={15}
                                      />
                                    ) : (
                                      <Search
                                        onClick={() => {}}
                                        className="assessment-search-icon1"
                                        width={15}
                                        height={15}
                                      />
                                    )}
                                  </InputGroup>
                                </div>
                              </div>
                              <button className="pr-0 btn clear-all-button">
                                <span
                                  className="ass-clear-all-position"
                                  onClick={() => {
                                    setAssessmentDateType(
                                      assessmentDateType.filter(
                                        ({ date }) => date !== assessment?.assessmentdate
                                      )
                                    );
                                    setNavbarButtons(
                                      navbarButtons.filter((item) => item != "Charts")
                                    );
                                  }}>
                                  DESELECT ALL
                                </span>
                              </button>

                              <div
                                className="scrollbar-assessment"
                                style={{
                                  // height: "378px",
                                  maxHeight: "207px",
                                  overflowY: "auto",
                                  paddingRight: "8px"
                                }}>
                                {Object.keys(assessment?.categories)
                                  .sort((a, b) => {
                                    if (a?.toLowerCase() < b?.toLowerCase()) {
                                      return -1;
                                    }
                                    if (a?.toLowerCase() > b?.toLowerCase()) {
                                      return 1;
                                    }
                                    return 0;
                                  })

                                  .map((type) => {
                                    const filteredCategories = assessment?.categories[type].filter(
                                      (test) => {
                                        return assessment.group[type] !== "manual"
                                          ? test?.toLowerCase().includes(searchValue?.toLowerCase())
                                          : test?.fullName
                                              ?.toLowerCase()
                                              .includes(searchValue?.toLowerCase());
                                      }
                                    );
                                    return filteredCategories.length > 0 ? (
                                      <>
                                        <div className="d-flex justify-content-between assessment-group">
                                          <span
                                            style={{
                                              fontWeight: "700",
                                              fontSize: "12px",
                                              color: "#0D1A40"
                                            }}>
                                            {type}
                                          </span>
                                          {assessment.group[type] === "drone" ? (
                                            <img src={droneIcon} alt="" />
                                          ) : (
                                            <img src={loanVesselIconfaint} alt="" />
                                          )}
                                        </div>
                                        {filteredCategories?.map((title) => {
                                          const typeCheck =
                                            assessment.group[type] !== "manual"
                                              ? title
                                              : title.fullName;
                                          const foundIndex =
                                            assessmentDateType.findIndex(findAssessmentIndex);

                                          function findAssessmentIndex(i) {
                                            return (
                                              i.date === assessment?.assessmentdate &&
                                              i.type === typeCheck
                                            );
                                          }

                                          const handleCheckboxChange = (e) => {
                                            handleAssessmentSelect(
                                              e,
                                              assessment?.assessmentdate,
                                              typeCheck,
                                              assessment?.assessmentID,
                                              assessment.group[type]
                                            );
                                          };

                                          return (
                                            <div
                                              className="d-flex justify-content-between align-items-center menu-bottom-border"
                                              style={{
                                                opacity: isMax6Item && foundIndex === -1 ? 0.5 : 1
                                              }}
                                              key={title}>
                                              <div className="d-flex py-1 align-items-center">
                                                <label className="checkbox-label">
                                                  <input
                                                    type="checkbox"
                                                    name="options"
                                                    className="assessment-input"
                                                    style={{ width: "16px", height: "16px" }}
                                                    onChange={handleCheckboxChange}
                                                    disabled={isMax6Item && foundIndex === -1}
                                                    checked={foundIndex !== -1}
                                                  />
                                                  <span
                                                    style={{
                                                      paddingLeft: "8px",
                                                      fontSize: "12px",
                                                      fontWeight: "400",
                                                      color: "#0D1A40"
                                                    }}>
                                                    {typeCheck.split("v:")[0]}
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                  color: "#0D1A40"
                                                }}>
                                                {foundIndex !== -1 && `(${orderArray[foundIndex]})`}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : null;
                                  })}
                              </div>
                            </div>
                          </DropdownButton>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div ref={assessmentDropdownRef}>
                      <div className="d-flex justify-content-center">
                        <InputGroup className="AssessmentSearchInputGroup">
                          <FormControl
                            type="text"
                            placeholder="Search for ratings"
                            className={
                              isDesktop
                                ? "RatingModalSearch mt-1 position-realtive"
                                : "MobileRatingModalSearch mt-1 position-realtive"
                            }
                            style={{ color: "#0D1A40" }}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          {searchValue ? (
                            <X
                              onClick={() => setSearchValue("")}
                              className="assessment-search-icon"
                              width={15}
                              height={15}
                            />
                          ) : (
                            <Search
                              onClick={() => {}}
                              className="assessment-search-icon"
                              width={15}
                              height={15}
                              fill="#0D1A40"
                            />
                          )}
                        </InputGroup>
                      </div>
                    </div>
                    {assessmentsFilteredByType &&
                      Object.keys(assessmentsFilteredByType)?.map((typeGroup) => {
                        const filterAssessment = Object.keys(
                          assessmentsFilteredByType[typeGroup]
                        )?.filter((item1) =>
                          item1?.toLowerCase().includes(searchValue?.toLowerCase())
                        );

                        return filterAssessment.length > 0 ? (
                          <div key={typeGroup}>
                            <div className="px-3 d-flex justify-content-between assessment-group">
                              <span
                                style={{
                                  fontWeight: "700",
                                  fontSize: "12px",
                                  color: "#0D1A40"
                                }}>
                                {typeGroup}
                              </span>
                              {assessmentsFilteredByType[typeGroup][
                                Object.keys(assessmentsFilteredByType[typeGroup])?.[0]
                              ]?.[0]?.isDrone ? (
                                <img src={droneIcon} alt="" />
                              ) : (
                                <img src={loanVesselIconfaint} alt="" />
                              )}
                            </div>

                            {filterAssessment?.map((type) => {
                              const haschildSelected = assessmentDateType.filter(
                                (i) => i.type === type
                              );
                              return (
                                <DropdownButton
                                  onClick={() => {
                                    setAssessmentDropdownButton(true);
                                  }}
                                  key={type}
                                  drop="end"
                                  variant="Light"
                                  className={`assessment-dropdown assessment-dropdown-type ${
                                    isTablet || customTabletDimensions()
                                      ? "assessment-dropdown-tablet"
                                      : ""
                                  }`}
                                  title={
                                    <span
                                      ref={assessmentButtonClickRef}
                                      className="d-flex justify-content-between align-items-center"
                                      style={{ height: "32px" }}>
                                      <span className="assessment-option">
                                        {type.split("v:")[0]}
                                      </span>
                                      <div className="d-flex justify-content-center align-items-center">
                                        <span
                                          className="pr-3"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: 700,
                                            marginLeft: "6px"
                                          }}>
                                          {haschildSelected.length > 0 &&
                                            `(${haschildSelected.length})`}
                                        </span>
                                        <span>
                                          <ChevronRight color="#0D1A40" height={8} />
                                        </span>
                                      </div>
                                    </span>
                                  }
                                  id={"nested-dropdown-button-drop-end"}
                                  style={{
                                    boxShadow: "3px",
                                    width: "100%"
                                  }}>
                                  <div className="p-2">
                                    <button
                                      className="btn clear-all-button"
                                      onClick={() =>
                                        setAssessmentDateType(
                                          assessmentDateType.filter(
                                            ({ type: test }) => test !== type
                                          )
                                        )
                                      }>
                                      <span className="ass-clear-all-position">DESELECT ALL</span>
                                    </button>
                                    <div
                                      style={{
                                        maxHeight: "207px",
                                        overflowY: "auto",
                                        paddingRight: "8px"
                                      }}>
                                      {assessmentsFilteredByType[typeGroup][type].map((dates) => {
                                        const foundIndex = assessmentDateType.findIndex(
                                          (i) => i.date === dates.date && i.type === type
                                        );
                                        return (
                                          <div
                                            style={{
                                              height: "48px",
                                              borderBottom: "1px solid rgb(237, 237, 237)",
                                              opacity: isMax6Item && foundIndex === -1 ? 0.5 : 1
                                            }}
                                            className="d-flex justify-content-between align-items-center"
                                            key={dates.date}>
                                            <div
                                              style={{
                                                width: "130px"
                                              }}>
                                              <label htmlFor="checkbox1" className="checkbox-label">
                                                <input
                                                  type="checkbox"
                                                  name="options"
                                                  className="assessment-input"
                                                  style={{ width: "16px", height: "16px" }}
                                                  onChange={(e) => {
                                                    handleAssessmentSelect(
                                                      e,
                                                      dates.date,
                                                      type,
                                                      dates.assessmentID,
                                                      dates.isManual ? "manual" : "drone"
                                                    );
                                                  }}
                                                  id=""
                                                  disabled={isMax6Item && foundIndex === -1}
                                                  checked={foundIndex !== -1}
                                                />
                                                <span
                                                  style={{
                                                    paddingLeft: "8px",
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    color: "#0D1A40"
                                                  }}>
                                                  {formatFlightDate(dates.date)}
                                                </span>
                                              </label>
                                            </div>
                                            <div style={{ width: "16px" }}>
                                              {dates?.isDrone && (
                                                <img src={droneIconBlack} alt="" />
                                              )}
                                            </div>
                                            <div style={{ width: "16px" }}>
                                              {dates?.isManual && (
                                                <img src={loanVesselIcon} alt="" />
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                width: "18px"
                                              }}>
                                              {foundIndex !== -1 && `(${orderArray[foundIndex]})`}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </DropdownButton>
                              );
                            })}
                          </div>
                        ) : null;
                      })}
                  </>
                )}
              </div>
            </div>
            <div style={{ backgroundColor: "#F6F6F6", zIndex: "99999", position: "relative" }}>
              <div className="d-flex justify-content-between pt-3">
                <span
                  style={{
                    marginLeft: "24px",
                    fontSize: "14px",
                    color: "#0D1A40",
                    fontWeight: 700
                  }}>
                  Assessments
                </span>
                <span className="d-flex">
                  {assessmentDateType?.length > 0 && (
                    <span
                      onClick={() => {
                        setAssessmentDateType([]);
                        setNavbarButtons(navbarButtons.filter((item) => item != "Charts"));
                        setSearchValue("");
                        setShowTreatment(true);
                        setAssessmentOptionsVisible(true);
                        setShowTime(false);
                        setShowCompare(false);
                        setShowTreatmentMT(false);
                        setShowTimeMT(false);
                        setShowCompareMT(false);
                      }}
                      style={{
                        marginRight: "15px",
                        fontSize: "14px",
                        color: "#0D1A40",
                        fontWeight: 700,
                        borderBottom: "1px solid",
                        cursor: "pointer",
                        zIndex: 9
                      }}>
                      CLEAR ALL
                    </span>
                  )}
                </span>
              </div>
              <div
                ref={assessmentDropdownRef}
                style={{
                  borderBottom: "1px solid #EDEDED",
                  paddingBottom: "15px",
                  marginTop: assessmentDateType?.length > 0 ? "10px" : "5px"
                }}>
                {assessmentDateType?.length === 0 ? (
                  <div
                    style={{
                      fontSize: "12px",
                      fontFamily: "Helvetica",
                      color: "#AAA9A9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px"
                    }}>
                    No Assessments selected
                  </div>
                ) : (
                  <div>
                    <Assessmentdatecombinations
                      assessmentDateType={assessmentDateType}
                      orderArray={orderArray}
                      setAssessmentDateType={setAssessmentDateType}
                      setSelectedCombinationForHeatMap={setSelectedCombinationForHeatMap}
                      selectedCombinationForHeatMap={selectedCombinationForHeatMap}
                      selectedCombinationForEyeOpen={selectedCombinationForEyeOpen}
                      setSelectedCombinationForEyeOpen={setSelectedCombinationForEyeOpen}
                      showTreatment={showTreatment}
                      showTime={showTime}
                      showCompare={showCompare}
                      setTreatmentDataLoaded={setTreatmentDataLoaded}
                      setFightDataLoaded={setFightDataLoaded}
                      setCompareData1Loaded={setCompareData1Loaded}
                      setCompareData2Loaded={setCompareData2Loaded}
                      dropdownassessmentdatecombination={dropdownassessmentdatecombination}
                      setSelectedTrialInfoForEyeOpen={setSelectedTrialInfoForEyeOpen}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
AssessmentDropDown.propTypes = {
  assessmentContainerVisible: propTypes.any,
  selectedOption: propTypes.any,
  setAssessmentContainerVisible: propTypes.any,
  assessmentBy: propTypes.any,
  setAssessmentBy: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any,
  assessmentDatesAndTypes: propTypes.any,
  assessmentsFilteredByType: propTypes.any,
  handleAssessmentSelect: propTypes.any,
  assessmentDateType: propTypes.any,
  setAssessmentDateType: propTypes.any,
  isMobileLandscape: propTypes.any,
  isDesktop: propTypes.any,
  isAssessmentDropdown: propTypes.any,
  handleAsessmentDropdown: propTypes.func,
  setMobileTrailDropdown: propTypes.any,
  setShowExtendendMenu: propTypes.any,
  setShowTreatment: propTypes.any,
  setShowTime: propTypes.any,
  setShowCompare: propTypes.any,
  setShowTreatmentMT: propTypes.any,
  setShowTimeMT: propTypes.any,
  setShowCompareMT: propTypes.any,
  setTreatmentLabel: propTypes.any,
  setAssessmentOptionsVisible: propTypes.any,
  setSelectedCombinationForHeatMap: propTypes.any,
  selectedCombinationForHeatMap: propTypes.any,
  selectedCombinationForEyeOpen: propTypes.any,
  setSelectedCombinationForEyeOpen: propTypes.any,
  showTreatment: propTypes.any,
  showTime: propTypes.any,
  showCompare: propTypes.any,
  setTreatmentDataLoaded: propTypes.any,
  setFightDataLoaded: propTypes.any,
  setCompareData1Loaded: propTypes.any,
  setCompareData2Loaded: propTypes.any,
  dropdownassessmentdatecombination: propTypes.any,
  handleRatingDescription: propTypes.any,
  isShowRatingdescription: propTypes.any,
  selectedTrialInfoForEyeOpen: propTypes.any,
  setSelectedTrialInfoForEyeOpen: propTypes.any,
  setNavbarButtons: propTypes.any,
  navbarButtons: propTypes.any,
  showTabletTooltip: propTypes.any,
  setShowTabletTooltip: propTypes.any,
  targetAssTabletRef: propTypes.any,
  dateTypeUrl:propTypes.any
};
export default AssessmentDropDown;

export const AssessmentBySwitch = ({
  assessmentBy,
  // assessmentDateType,
  setAssessmentBy,
  isTablet,
  setAssessmentDateType,
  customMobilePortrait,
  customMobileLandscape,
  // dateTypeUrl
}) => {
  return (
    <span
      style={{
        paddingLeft: isTablet ? "0px" : "16px",
        marginRight: "10px",
        fontSize: "14px",
        color: "#0D1A40",
        fontWeight: "700"
      }}>
      <span style={{ marginRight: "10px" }}>{assessmentBy === "date" ? "Date" : "Type"}</span>
      <img
        style={{ marginBottom: "10px", marginTop: "10px" }}
        src={leftRightArrowGreenCircleIcon}
        alt=""
        className="cursor-pointer"
        onClick={() => setAssessmentBy(assessmentBy === "date" ? "type" : "date")}
      />
      <span style={{ marginLeft: "10px" }}>{assessmentBy === "date" ? "Type" : "Date"} </span>
      {(customMobilePortrait || customMobileLandscape) && (
        <span
          className="ass-clear-all-position"
          onClick={() => {
            setAssessmentDateType([]);
            // setSearchValue("");
            // setShowTreatment(true);
            // setAssessmentOptionsVisible(true);
            // setShowTime(false);
            // setShowCompare(false);
            // setShowTreatmentMT(false);
            // setShowTimeMT(false);
            // setShowCompareMT(false);
          }}>
          CLEAR ALL
        </span>
      )}
    </span>
  );
};
AssessmentBySwitch.propTypes = {
  assessmentBy: propTypes.any,
  setAssessmentBy: propTypes.any,
  isTablet: propTypes.any,
  assessmentDateType: propTypes.any,
  setAssessmentDateType: propTypes.any,
  setSearchValue: propTypes.any,
  setShowTreatment: propTypes.any,
  setShowTime: propTypes.any,
  setShowCompare: propTypes.any,
  setShowTreatmentMT: propTypes.any,
  setShowTimeMT: propTypes.any,
  setShowCompareMT: propTypes.any,
  setAssessmentOptionsVisible: propTypes.any,
  customMobilePortrait: propTypes.any,
  customMobileLandscape: propTypes.any
};

export const AssessmentMessage = (
  <span
    style={{
      fontSize: "12px",
      color: "#0D1A40",
      fontWeight: "400",
      marginBottom: "100px",
      textWrap: "nowrap"
    }}>
    <InfoCircleFill fill="#FFB71E" height={20} width={20} />
    &nbsp; Select up to 6 Date - Assessment combinations
  </span>
);
