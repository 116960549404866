import { useEffect, useState, useRef } from "react";
import { Card, Accordion, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as L from "leaflet";
import {
  Bookmark,
  BookmarkFill,
  Filter,
  ChevronDown,
  ChevronUp,
  X,
  ChevronRight,
  EmojiFrown,
  InfoCircleFill,
  InfoCircle
} from "react-bootstrap-icons";
import ReactDOMServer from "react-dom/server";
import MapDesktopBar from "./MapDesktopTopBar";
import MapMobileTopBar from "./MapMobileTopBar";
import RefinedSearch from "./RefinedSearch";
import {
  myTrialsAPI,
  recentTrialsAPI,
  bookmarkTrialsAPI,
  saveBookMarkTrialsAPI,
  deleteBookMarkTrialsAPI,
  loginSyt,
  yearsAPI,
  getAllTrialSummaryData,
  flightDatesAPI,
  edcGetAccessToken,
  treatmentUserLabel
} from "../services/API";
import axios from "axios";
import leafBlue from "../assests/Styles/assets/leafBlue.svg";
import trialSummaryOrangeIcon from "../assests/Styles/assets/trialSummaryOrangeIcon.svg";
import orangeMarkerIcon from "../assests/Styles/assets/orangeMarkerIcon.svg";
import welcomeArrow from "../assests/Styles/assets/welcome-arrow.svg";
import customMarker from "../assests/Styles/assets/bluemarker.png";
import "../assests/Styles/common.css";
import "../assests/Styles/mapviewer.css";
import { customTabletDimensions, dateConverter } from "../services/Common";
import NewViewerTrialSummary from "./NewViewerTrialSummary";
import { Spinner } from "../services/Common";
import { isMobileOnly, useMobileOrientation, isMobile, isDesktop } from "react-device-detect";

/* to show custom marker */
const DefaultIcon = L.icon({
  iconUrl: customMarker,
  iconSize: [14, 20],
  popupAnchor: [0, 0]
});
let trialsListConst = [];
let bookMarkedTrialsConst = [];

/* Assign custom mock up to leafet marker */
L.Marker.prototype.options.icon = DefaultIcon;

export default function LandingPage() {
  const refinedAccordions = [
    { key: "trial_owner", label: "Trial Owner", directKey: "trial_owner" },
    { key: "trial_id", label: "Trial Number", directKey: "trial_id" },
    { key: "trial_name", label: "Trial Number", directKey: "trial_name" },
    { key: "protocol_owner", label: "Protocol Owner", directKey: "protocol_owner" },
    { key: "protocol_id", label: "Protocol Number", directKey: "protocol_id" },
    { key: "crops", label: "Crop", dataKey: "crops", subKey: "commonName" },
    { key: "pests", label: "Target", dataKey: "pests", subKey: "scientificName" },
    { key: "country_name", label: "Country", directKey: "country_name" }
  ];
  const [selectedItems, setSelectedItems] = useState({});
  const [bookMarkedTrials, setBookMarkedTrials] = useState([]);
  const [showCardsAtLeft, setShowCardsAtLeft] = useState(true);
  const [showWelcomeText, setShowWelcomeText] = useState(false);
  const [yearLoader, setYearLoader] = useState(false);
  const [selectedResult, setSelectedResult] = useState("");
  const [trialsList, setTrialsList] = useState([]);
  const [refinedSearch, setRefinedSearch] = useState(false);
  const [show, setShow] = useState(null);
  const [myTrials, setMyTrials] = useState(true);
  const [recentTrials, setRecentTrials] = useState(false);
  const [yearSelection, setYearSelection] = useState(sessionStorage.getItem("selectedYear"));
  const [isOn, setIsOn] = useState(true);
  const [topBookmarkTrail, setTopBookmarkTrail] = useState(false);
  const idToken = sessionStorage.getItem("idToken");
  const effectRun = useRef(false);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState();
  const [loading, setLoading] = useState(true);
  const [yearList, setYearList] = useState(null);
  const [selectedOption, setSelectedOption] = useState(setYearList[0]);
  const [trialsByYear, setTrialsByYear] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showAutoSuggestionPopup, setShowAutoSuggestionPopup] = useState(false);
  const [cardClicked, setCardClicked] = useState(null);
  const [searchLoader, setSearchLoader] = useState(false);
  const [myTrialToggleButton, setMyTrialToggleButton] = useState(false);
  const [selectedTrail, setSelectedTrail] = useState(null);
  const [flightDates, setFlightDates] = useState([]);
  const [trailSumamryResult, setTrailSummaryResult] = useState(false);
  const [pestData, setPestData] = useState(null);
  const [refinedSerachText, setRefinedSearchText] = useState(
    refinedAccordions.reduce((acc, category) => {
      acc[category.key] = "";
      return acc;
    }, {})
  );
  const [refinedSearchTrialList, setRefinedSearchTrialslist] = useState([]);
  const refineSearchRef = useRef(null);
  const trialCardRef = useRef({});
  const trialSummaryRef = useRef(null);
  const [trailSummaryColor, setTrailSummaryColor] = useState(null);
  const [refineCancel, setRefineCancel] = useState(false);
  const [isFilterByOrthoMosaic, setIsFilterByOrthoMosaic] = useState(false);
  const [isFilterByDroneAssessment, setIsFilterByDroneAssessment] = useState(false);
  const [showTooltip, setShowTooltip] = useState();
  const tooltipRef = useRef(null);
  const [isTreatmentUser, setIsTreatmentUser] = useState();
  const { isLandscape, isPortrait } = useMobileOrientation();
  const customMobilePortrait = isMobile && isPortrait && !customTabletDimensions();
  const customMobileLandscape = isMobile && isLandscape && !customTabletDimensions();

  const handleTooltipButtonClick = (id) => {
    setShowTooltip(id);
  };

  useEffect(() => {
    treatmentUserLabel(
      {
        email: sessionStorage.getItem("userName")
      },
      (res) => {
        setIsTreatmentUser(res?.is_allowed);
      },
      (err) => {
        console.log("err in treatmentUserLabel", err);
      }
    );
  }, [sessionStorage.getItem("userName")]);

  const handleTooltipCloseButton = () => {
    setShowTooltip();
  };

  const renderLandingPageTooltip = (props, trial) => (
    <Tooltip
      id="tooltip"
      {...props}
      className={`landing-page-tooltip background-air-force-color ${isLandscape && isMobileOnly ? "landing-page-tooltip-landscape" : ""
        }`}>
      {isLandscape && (isMobileOnly || customTabletDimensions()) && (
        <div style={{ position: "absolute", zIndex: 9999, right: "5px", top: "10px" }}>
          <X
            onClick={(e) => {
              e.stopPropagation();
              handleTooltipCloseButton();
            }}
            alt=""
            height={24}
            width={24}
          />
        </div>
      )}
      <div style={{ fontSize: "14px", fontWeight: 400 }}>
        {trial?.has_ortho && <div> Orthomosaic</div>}
        {trial?.has_drone_data && <div> Drone Assessments</div>}
        {isTreatmentUser && <div> Treatment Labels</div>}
        <div> Environmental</div>
      </div>
    </Tooltip>
  );

  const renderMobileLandingPageTooltip = (props, trial) => (
    <Tooltip
      id="tooltip"
      {...props}
      className="landing-mobile-page-tooltip background-air-force-color">
      <div style={{ position: "absolute", zIndex: 9999, right: "5px", top: "10px" }}>
        <X
          onClick={(e) => {
            e.stopPropagation();
            handleTooltipCloseButton();
          }}
          alt=""
          height={24}
          width={24}
        />
      </div>
      <div style={{ fontSize: "14px", fontWeight: 400, paddingLeft: "12px" }}>
        {trial?.has_ortho && <div> Orthomosaic</div>}
        {trial?.has_drone_data && <div> Drone Assessments</div>}
        {isTreatmentUser && <div> Treatment Labels</div>}
        <div> Environmental</div>
      </div>
    </Tooltip>
  );

  useEffect(() => {
    const handleTooltipClickOutside = (event) => {
      if (showTooltip) {
        if (
          tooltipRef.current &&
          !tooltipRef.current.contains(event.target) &&
          showTooltip !== event.target.id
        ) {
          setShowTooltip();
        }
      }
    };
    window.addEventListener("mousedown", handleTooltipClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleTooltipClickOutside);
    };
  }, [showTooltip]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!refineSearchRef?.current?.contains(event.target)) {
        setRefinedSearch(false);
        setShow(null);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handler = (event) => {
      let parentdiv = document.getElementById("trialSummaryID");
      if (parentdiv != null && !parentdiv.contains(event.target)) {
        setTrailSummaryResult(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    trialsListConst = trialsList;
  }, [trialsList]);

  useEffect(() => {
    bookMarkedTrialsConst = bookMarkedTrials;
  }, [bookMarkedTrials]);

  //to set selected accordion value
  const handleToggle = (index) => {
    setShow(show === index ? null : index);
  };
 
  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          edcGetAccessToken((res) => {
            if (selectedTrail && res.id_token !== "") {
              setPestData([]);
              getAllTrialSummaryData(
                selectedTrail,
                res.id_token,
                (data) => {
                  let pestName = new Set();

                  data?.trialSummary?.pest?.forEach((item) => {
                    pestName.add(item?.scientificName);
                  });

                  setPestData(Array.from(pestName));
                },
                (err) => {
                  console.log("err", err);
                }
              );
            }
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [selectedTrail, idToken]);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    if (effectRun.current === false) {
      loginSyt(
        idToken,
        (res) => {
          setError(null);
          if (res === 200) {
            if (sessionStorage.getItem("yearsList") === null) {
              yearsAPI((years) => {
                const yearList = years.sort(function (a, b) {
                  return b - a;
                });

                setYearList(yearList);
                sessionStorage.setItem("yearsList", JSON.stringify(yearList));
                if (yearList.length > 0) {
                  if (sessionStorage.getItem("selectedYear") === null) {
                    sessionStorage.setItem("selectedYear", yearList[0]);
                    setSelectedOption(yearList[0]);
                    setYearSelection(yearList[0]);
                    setSelectedYear(yearList[0]);
                  } else {
                    setSelectedYear(sessionStorage.getItem("selectedYear"));
                  }
                }
              });
            } else {
              setYearList(JSON.parse(sessionStorage.getItem("yearsList")));
              setSelectedYear(sessionStorage.getItem("selectedYear"));
              setSelectedOption(sessionStorage.getItem("selectedYear"));
              setYearSelection(sessionStorage.getItem("selectedYear"));
              setLoading(false);
            }
          }
        },
        (err) => {
          setError(err.message);
          window.location.reload();
          return () => {
            effectRun.current = false;
          };
        }
      );
      return () => {
        effectRun.current = true;
      };
    }
  }, [error, loading, selectedYear]);

  const getDates = flightDates;
  let droneFlightDates = [];
  getDates?.map((text) => {
    droneFlightDates.push({ x: text, y: "Drone Flight" });
  });

  const handleTrialSummaryClick = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedTrail(item);
    setTrailSummaryResult(true);
    setTrailSummaryColor(item);
    const card = trialCardRef.current[item];

    if (card && !(customMobilePortrait || customMobileLandscape)) {
      card.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  const handleTrialSummaryClose = () => {
    setTrailSummaryResult(false);
    const previousMarker = window.markersOnMap?.find((obj) => obj?.trialId === trailSummaryColor);
    if (previousMarker) {
      previousMarker.closePopup();
    }
  };

  useEffect(() => {
    if (selectedTrail) {
      flightDatesAPI(
        {
          trial_name: `${selectedTrail}`
        },
        (res) => {
          setFlightDates(Object.keys(res?.flight_dates));
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  }, [selectedTrail]);

  /* only for desktop showcardatleft value to set as true  */
  useEffect(() => {
    if ((customMobilePortrait || customTabletDimensions()) && !customMobileLandscape) {
      setShowCardsAtLeft(false);
    } else {
      setShowCardsAtLeft(true);
    }
  }, [customMobilePortrait, customMobileLandscape, customTabletDimensions(), isDesktop]);

  useEffect(() => {
    if (yearList !== null || selectedYear !== null) {
      if (topBookmarkTrail) {
        setYearLoader(true);
        bookmarkTrialsAPI(
          {
            created_by: `${sessionStorage.getItem("userName")}`
          },
          (res) => {
            if (res?.bookmark_trials.length > 0) {
              setShowWelcomeText(false);
              setYearLoader(false);
              setTrialsList(res?.bookmark_trials?.map((item) => item?.details));
              setRefinedSearchTrialslist(res?.bookmark_trials?.map((item) => item?.details));
              popMarker();
              setBookMarkedTrials(res?.bookmark_trials?.map((item) => item?.details?.trial_name));
              setCardClicked(null);
              setSearchText("");
            } else {
              setTrialsList([]);
              popMarker();
              setYearLoader(false);
              setShowWelcomeText(false);
            }
          },
          (err) => {
            setYearLoader(false);
            console.log("err", err);
          }
        );
      } else if (isOn && yearSelection !== null) {
        setYearLoader(true);
        myTrialsAPI(
          {
            email: `${sessionStorage.getItem("userName")}`,
            year: `${yearSelection}`
          },
          (res) => {
            if (res?.results.length > 0) {
              setShowWelcomeText(false);
              setSelectedResult("");
              setYearLoader(false);
              setTrialsList(res?.results);
              setRefinedSearchTrialslist(res?.results);
              popMarker();
              setTopBookmarkTrail(false);
              setCardClicked(null);
              setMyTrialToggleButton(true);
            } else {
              setIsOn(false);
              setMyTrials(false);
              setRecentTrials(true);
            }
          },
          (err) => {
            setYearLoader(false);
            console.log("err", err);
          }
        );
      } else if (
        selectedResult == "" &&
        `${sessionStorage.getItem("userName")}` !== "aneta.thuering@syngenta.com"
      ) {
        setYearLoader(true);
        recentTrialsAPI(
          {
            created_by: `${sessionStorage.getItem("userName")}`
          },
          (res) => {
            setYearLoader(false);
            setSelectedResult("");
            if (res?.recent_trials.length > 0 && topBookmarkTrail === false) {
              setRecentTrials(true);
              setShowWelcomeText(false);
              const filteredUniqueRecentTrial = res?.recent_trials?.filter(
                (item) => item?.details && Object.keys(item.details).length > 0
              );
              filteredUniqueRecentTrial?.sort(
                (a, b) => dateConverter(b.updated_on) - dateConverter(a.updated_on)
              );
              setTrialsList(filteredUniqueRecentTrial?.map((item) => item?.details));
              setRefinedSearchTrialslist(filteredUniqueRecentTrial?.map((item) => item?.details));
              popMarker();
              setCardClicked(null);
            } else {
              setShowWelcomeText(true);
              setRecentTrials(false);
            }
          },
          (err) => {
            setYearLoader(false);
            console.log("err", err);
          }
        );
      }
    }
  }, [isOn, yearSelection, topBookmarkTrail]);

  useEffect(() => {
    setSearchLoader(true);
    setShowAutoSuggestionPopup(false);
    setSearchText("");
    setTrialsByYear([]);
    setSelectedResult("");
    setTrialsList([]);
    if (selectedOption) {
      setRefinedSearch(false);
      setCardClicked(null);
      setSearchLoader(false);
    }
  }, [selectedOption]);

  const showResultBar = () => {
    return (
      <>
        {bookMarkedTrials.length === 0 && topBookmarkTrail && !yearLoader ? (
          <div className="d-flex ml-3 align-items-center justify-content-center p-3 ">
            <InfoCircleFill fill="#FFB71E" height={20} width={20} />
            <span className="no-bookmark-data-text ml-2">No Bookmarked Trial</span>
          </div>
        ) : trialsList.length === 0 && selectedResult ? (
          <div className="d-flex ml-3 align-items-center justify-content-center p-3 ">
            <EmojiFrown color="#FF3F3F" />
            <span className="no-data-text ml-2">Sorry we could not find any results</span>
          </div>
        ) : topBookmarkTrail && !selectedResult && bookMarkedTrials.length > 0 && !yearLoader ? (
          <span className="result-text">Showing all Bookmarked Trials ({trialsList.length})</span>
        ) : myTrials && !selectedResult && trialsList.length > 0 && !yearLoader ? (
          <span className="result-text">Showing all My Trials ({trialsList.length})</span>
        ) : recentTrials && !selectedResult && trialsList.length>0 && !yearLoader ? (
          <span className="result-text">Showing all Recent Trials ({trialsList.length})</span>
        ) : (
          trialsList.length > 0 &&
          (selectedResult || !selectedResult) &&
          !yearLoader && (
            <span className="result-text" data-testId="refined-search-icon">
              Showing Results for ‘{selectedResult}’ ({trialsList.length})
            </span>
          )
        )}

        {refinedSearchTrialList?.length > 0 && selectedResult && !topBookmarkTrail && !isOn && (
          <span className={customMobileLandscape ? "landscape-filter-icon" : "landing-filter-icon"} data-testId="refined-filter-icon">
            <Filter color="#0D1A40" height={24} width={24} onClick={() => setRefinedSearch(true)}  />
          </span>
        )}
      </>
    );
  };

  /* using reactDOMServer show the marker custom popup */
  const popuptrialCard = (item) => {
    const cardContent = trialCardBody(item, true);
    const trial = item?.trial_id ? item?.trial_id : item?.trial_name;
    const htmlString = ReactDOMServer.renderToString(cardContent);
    const popupElement = document.createElement("card");
    popupElement.innerHTML = htmlString;
    setTimeout(() => {
      popupElement.querySelector("button").addEventListener("click", () => {
        window.open(`/app/viewer?trial=${trial}`, "_self");
      });

      const bookmarkFillElement = popupElement.querySelector(".fill");
      const bookmarkElement = popupElement.querySelector(".empty");
      if (bookmarkFillElement) {
        bookmarkFillElement.addEventListener("click", (e) => {
          onClickOfBookMark(e, item);
          bookmarkFillElement.classList.add("empty");
          bookmarkFillElement.classList.remove("fill");
          item.is_bookmark = false; // Update is_bookmark property
        });
      }
      if (bookmarkElement) {
        bookmarkElement.addEventListener("click", (e) => {
          onRemoveOfBookMark(e, item);
          bookmarkElement.classList.add("fill");
          bookmarkElement.classList.remove("empty");
          item.is_bookmark = true; // Update is_bookmark property
        });
      }

      const trialSummaryIcon = popupElement.querySelector(".trial-summary-icon");
      if (trialSummaryIcon) {
        trialSummaryIcon.addEventListener("click", (e) => {
          handleTrialSummaryClick(e, trial); // Call the handleTrialSummaryClick function
        });
      }
    }, 0);
    return popupElement;
  };

  const onClickOfBookMark = (e, item) => {
    e.stopPropagation();
    const trial = item?.trial_id ? item?.trial_id : item?.trial_name;
    setBookMarkedTrials(bookMarkedTrialsConst.filter((i) => i !== trial));
    deleteBookMarkTrialsAPI(
      {
        trial_name: trial,
        created_by: `${sessionStorage.getItem("userName")}`
      },
      (res) => {
        console.log("err", res);
        const updatedTrailsList = trialsListConst.map((i) => {
          if (i.trial_name === trial || i.trial_id === trial) {
            return { ...i, is_bookmark: false };
          }
          return i;
        });
        setTrialsList(updatedTrailsList);
      },
      (err) => {
        console.log("err", err);
      }
    );
    if (topBookmarkTrail) {
      const updatedTrailsList = trialsListConst.filter(
        (i) => i.trial_name !== item.trial_name || i.trial_id !== item.trial_id
      );
      setTrialsList(updatedTrailsList);
      setTimeout(() => {
        bookMarkChanged(trial);
      }, 100);
      popMarker();
    } else {
      const updatedTrailsList = trialsListConst.map((i) => {
        if (i.trial_name === trial || i.trial_id === trial) {
          return { ...i, is_bookmark: false };
        }
        return i;
      });
      setTrialsList(updatedTrailsList);
      setTimeout(() => {
        bookMarkChanged(trial);
      }, 100);
    }
  };

  const onRemoveOfBookMark = (e, item) => {
    e.stopPropagation();
    const trial = item?.trial_id ? item?.trial_id : item?.trial_name;
    setBookMarkedTrials([...bookMarkedTrialsConst, trial]);
    saveBookMarkTrialsAPI(
      {
        trial_name: trial,
        created_by: `${sessionStorage.getItem("userName")}`
      },
      (res) => {
        setTimeout(() => {
          bookMarkChanged(trial);
        }, 100);
        const updatedTrailsList = trialsListConst.map((i) => {
          if (i.trial_name === trial || i.trial_id === trial) {
            return { ...i, is_bookmark: true };
          }
          return i;
        });
        setTrialsList(updatedTrailsList);
        console.log("err", res);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  // Trial card body in function to avoid repeating code
  const trialCardBody = (item, onMap = false) => {
    const crops = item?.crops?.filter(({ commonName }) => commonName);
    const trial = item?.trial_id ? item?.trial_id : item?.trial_name;

    return (
      <Card.Body className="text-left p-2">
        <Card.Text>
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div style={{ width: "55%" }}>
              <span className="map-card-FirstLine">
                {item?.trial_id ? item?.trial_id : item?.trial_name}
              </span>
            </div>
            <div
              style={{ width: onMap ? "45%" : "75%" }}
              className="d-flex align-items-center justify-content-between cursor-pointer">
              <div
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  handleCardClick(item);
                  handleTrialSummaryClick(e, trial);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter" || event.key === " ") {
                    handleTrialSummaryClick(event, trial);
                  }
                }}>
                <img
                  data-testId="trial-summary-icon"
                  className="trial-summary-icon"
                  alt=""
                  src={trialSummaryOrangeIcon}
                  height={24}
                  width={24}
                  style={{
                    background: trailSummaryColor === trial && trailSumamryResult ? "#FFE8BC" : "",
                    borderRadius: "50px"
                  }}
                />
              </div>
              <span>
                {bookMarkedTrialsConst.includes(trial) || item?.is_bookmark ? (
                  <BookmarkFill
                    className="fill bookmark-icon-color cursor-pointer"
                    height={24}
                    width={18}
                    onClick={(e) => onClickOfBookMark(e, item)}
                  />
                ) : (
                  <Bookmark
                    className="empty cursor-pointer bookmark-style-empty"
                    height={24}
                    width={18}
                    onClick={(e) => onRemoveOfBookMark(e, item)}
                  />
                )}
              </span>
              <a href={`/app/viewer?trial=${trial}`}>
                <button
                  className="data-button d-flex align-items-center justify-content-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`/app/viewer?trial=${trial}`, "_self");
                  }}>
                  <span>DATA</span> <ChevronRight className="pl-1" height={14} width={14} />
                </button>
              </a>
              {!onMap && (
                <OverlayTrigger
                  placement="top"
                  show={showTooltip === (item?.trial_id || item?.trial_name)}
                  overlay={(props) => renderMobileLandingPageTooltip(props, item)}
                  ref={tooltipRef}
                  trigger={["click"]}>
                  <span
                    id={item?.trial_id || item?.trial_name}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (showTooltip === trial) {
                        setShowTooltip();
                      } else {
                        handleTooltipButtonClick(
                          item?.trial_id ? item?.trial_id : item?.trial_name
                        );
                      }
                    }}>
                    <InfoCircle className="info-tooltip" height={24} width={18} color="#636363" />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div style={{ width: "55%" }}>
              <div className="mb-1">
                <span>
                  <img alt="" src={leafBlue} height={12} width={12} />
                </span>
                <span className="leaf-text font-size-extra-small font-weight-700 pl-1">
                  {crops?.length > 2
                    ? "Multi crops"
                    : crops
                      ?.slice(0, 2)
                      ?.map(({ commonName }) => commonName)
                      ?.join(", ") || "No crop information"}
                </span>
              </div>
              <div>
                <span className=" font-weight-400 font-size-small">{item?.protocol_id}</span>
              </div>
              {item?.master_protocol && (
                <div>
                  <span className=" font-weight-400 font-size-small">
                    {item?.master_protocol?.protocolId}
                  </span>
                </div>
              )}
            </div>
            <div style={{ width: "45%", marginTop: "4px" }}>
              <div className="map-card-lines" style={{ whiteSpace: "nowrap" }}>
                <span className="map-card-logo mr-2">T</span>
                <span className="font-size-extra-small font-weight-400">
                  {item?.trial_owner?.trim()}
                </span>
              </div>
              {item?.master_protocol !== null &&
                item?.master_protocol?.ownerName?.trim() !== undefined &&
                item?.master_protocol?.ownerName?.trim() !== item?.protocol_owner?.trim() && (
                  <div>
                    <span className="map-card-logo mr-2">D</span>
                    <span className="font-size-extra-small font-weight-400">
                      {item?.protocol_owner?.trim()}
                    </span>
                  </div>
                )}
              <div>
                <span className="map-card-logo mr-2">M</span>
                <span className="font-size-extra-small font-weight-400">
                  {item?.master_protocol !== null &&
                  item?.master_protocol?.ownerName?.trim() !== undefined
                    ? item?.master_protocol?.ownerName?.trim()
                    : item?.protocol_owner && item?.protocol_owner?.trim()}
                </span>
              </div>
            </div>
          </div>
        </Card.Text>
      </Card.Body>
    );
  };

  //Base Map when page is loaded for the first time
  useEffect(() => {
    setTimeout(() => {
      const map = L.map("map", {
        mapMaxZoom: 18,
        mapMinZoom: 2,
        crs: L.CRS.EPSG3857,
        zoomControl: false,
        touchRotate: true,
        zoomSnap: 0.5, // partial zooms have bad gaps on safari/chrome
        zoomDelta: 0.5,
        zIndex: 1,
        worldCopyJump: true
      });
      map.setView([51.505, -0.09], 2);
      map.setMinZoom(2);
      map.touchRotate.disable();
      map.touchZoom.disable();
      map.compassBearing.disable();
      map.touchGestures.enable();
      map.on("load", () => {
        map.invalidateSize();
      });

      // Create a Tile Layer and add it to the map
      L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png").addTo(
        map
      );

      let ZoomViewer = L.Control.extend({
        onAdd: function () {
          let container = L.DomUtil.create("div");
          let gauge = L.DomUtil.create("div");
          container.style.width = "5em";
          container.style.background = "rgba(255,255,255,0.5)";
          container.style.textAlign = "center";
          container.style.position = "fixed"; //new
          container.style.bottom = "5px"; //new
          container.style.right = "18px"; //new
          container.className = "zoom-label";
          map.on("zoomstart zoom zoomend", function () {
            gauge.innerHTML = "zoom: " + map.getZoom();
          });
          map.on("zoomend", function () {
            gauge.innerHTML = "zoom: " + map.getZoom();
          });
          gauge.innerHTML = "zoom: " + map.getZoom();
          container.appendChild(gauge);

          return container;
        }
      });
      new ZoomViewer({ position: "bottomright" }).addTo(map);
      L.control
        .zoom({
          position: "bottomright"
        })
        .addTo(map);
      window.Mymap = map;
    }, 1000);
  }, []);

  /**
   * Handles the click event on a card.
   * If markers are found for the clicked item, it opens the popup associated with the markers.
   *
   * @param {Object} item - The item that was clicked.
   */
  const handleCardClick = (item) => {
    closeTrialCardPopUp();
    const map = window.Mymap;
    let newTrialId = item?.trial_id ? item?.trial_id : item?.trial_name;
    const markers = window.markersOnMap?.find((obj) => obj?.trialId === newTrialId);
    if (markers) {
      map.setView([markers?._latlng?.lat, markers?._latlng?.lng]);
      markers.openPopup();
    } else {
      // Close previous marker's popup
      const previousMarker = window.markersOnMap?.find((obj) => obj?.trialId === cardClicked);
      if (previousMarker) {
        previousMarker.closePopup();
      }
    }
    setCardClicked(newTrialId);
  };
  const closeTrialCardPopUp = () => {
    setTrailSummaryResult(false);
  };

  const bookMarkChanged = (trialId) => {
    const map = window.Mymap;
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        if (layer.trialId === trialId) {
          layer.bindPopup(
            popuptrialCard({
              ...trialsListConst.find(
                (item) => item?.trial_name === trialId || item?.trial_id === trialId
              ),
              is_bookmark: bookMarkedTrialsConst.includes(trialId)
            })
          );
        }
      }
    });
  };

  //Plotting markers on the map based on search values and zooming into that particular location
  function popMarker() {
    setTimeout(() => {
      const map = window.Mymap;
      window.markersOnMap = [];
      map?.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          layer.closePopup();
          map.removeLayer(layer);
        }
      });
      L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png")?.addTo(
        map
      );
      let bounds = new L.latLngBounds();
      if (trialsListConst.length > 0) {
        const firstCountry = trialsListConst[0]?.geo?.features[0];
        if (
          firstCountry?.geometry?.coordinates &&
          firstCountry?.geometry?.coordinates.length != 0
        ) {
          map.invalidateSize();
          map.setView(
            [firstCountry?.geometry?.coordinates[1], firstCountry?.geometry?.coordinates[0]],
            13
          );
        }
        trialsListConst.forEach((item) =>
          item?.geo?.features.forEach((marker) => {
            if (marker?.geometry?.coordinates && marker?.geometry?.coordinates.length != 0) {
              let popTest = popuptrialCard(item);
              const markerBlueOpen = L.icon({
                iconUrl: orangeMarkerIcon
              });

              let markers = L.marker(
                [marker?.geometry?.coordinates[1], marker?.geometry?.coordinates[0]],
                {
                  icon: DefaultIcon
                }
              )
                .addTo(map)
                .bindPopup(popTest)
                .on("popupopen", function () {
                  markers.setIcon(markerBlueOpen);
                  setCardClicked(item?.trial_id ? item?.trial_id : item?.trial_name);
                  const card =
                    trialCardRef.current[item?.trial_id ? item?.trial_id : item?.trial_name];
                  if (card) {
                    card.scrollIntoView({ behavior: "smooth", block: "nearest" });
                  }
                })
                .on("popupclose", function () {
                  setCardClicked(null);
                  markers.setIcon(DefaultIcon);
                });
              bounds.extend([marker?.geometry?.coordinates[1], marker?.geometry?.coordinates[0]]);
              markers.trialId = item?.trial_id ? item?.trial_id : item?.trial_name;
              window.markersOnMap.push(markers);
            }
          })
        );

        if (Object.keys(bounds).length > 0) {
          map.invalidateSize();
          map.fitBounds(bounds, { padding: [50, 200] }); // Auto-zoom to fit all markers within the bounds with padding
        } else {
          map.invalidateSize();
          map.setView([0, 0], 2); // Set default view if no markers are present
        }
      } else {
        map.invalidateSize();
        map.setView([0, 0], 2);
      }
    }, 1000);
  }

  useEffect(() => {
    popMarker();
  }, []);

  const handleRefinedSearch = (category, value) => {
    setRefinedSearchText((prevTerms) => ({ ...prevTerms, [category]: value }));
  };

  const clearSearchText = (category) => {
    setRefinedSearchText((prevTerms) => ({ ...prevTerms, [category]: "" }));
  };

  const particularCategoryPresent = (category) => {
    if (category?.directKey) {
      return trialsList.some((item) => !!item[category.directKey]);
    } else {
      return trialsList.some((item) =>
        item[category.dataKey]?.some((subItem) => subItem[category.subKey])
      );
    }
  };

  const optionsForRefinedSearch = (category) => {
    let options = [];
    if (
      category?.directKey !== "protocol_owner" &&
      category?.directKey !== "protocol_id" &&
      category.dataKey != "crops" &&
      category.dataKey != "pests"
    ) {
      options = trialsList.map((item) => item[category.directKey]).filter(Boolean);
    } else if (category?.directKey === "protocol_owner") {
      trialsList
        .map((item) => {
          options.push(item[category.directKey]);
          if (item?.master_protocol?.ownerName) options.push(item?.master_protocol?.ownerName);
        })
        .filter(Boolean);
    } else if (category?.directKey === "protocol_id") {
      trialsList
        .map((item) => {
          options.push(item[category.directKey]);
          if (item?.master_protocol?.protocolId) options.push(item?.master_protocol?.protocolId);
        })
        .filter(Boolean);
    } else if (category.dataKey === "crops" || category.dataKey === "pests") {
      options = trialsList
        .flatMap((item) =>
          item[category.dataKey]
            ? item[category.dataKey]?.map((subItem) => subItem[category.subKey])
            : []
        )
        .filter(Boolean);
    }
    return [...new Set(options)].sort((a, b) => a?.localeCompare(b, undefined, { numeric: true }));
  };

  const handleRefinedCheckBoxes = (categoryKey, item, isChecked) => {
    setSelectedItems((prev) => {
      const updatedCheckboxes = { ...prev };
      if (!updatedCheckboxes[categoryKey]) {
        updatedCheckboxes[categoryKey] = new Set();
      }

      if (isChecked) {
        updatedCheckboxes[categoryKey].add(item);
      } else {
        updatedCheckboxes[categoryKey].delete(item);
      }
      return updatedCheckboxes;
    });
  };

  const applyRefinedSearchFilters = () => {
    let filteredData = refinedSearchTrialList.filter((item) =>
      Object.entries(selectedItems).every(([key, value]) => {
        if (!value.size) return true;
        if (key === "protocol_owner") {
          return (
            value.has(item[key]) ||
            (item?.master_protocol?.ownerName && value.has(item?.master_protocol?.ownerName))
          );
        }
        if (key === "protocol_id") {
          return (
            value.has(item[key]) ||
            (item?.master_protocol?.protocolId && value.has(item?.master_protocol?.protocolId))
          );
        }
        if (key === "crops") {
          return item[key].some((subItem) => value.has(subItem?.commonName));
        }
        if (key === "pests") {
          return item[key].some((subItem) => value.has(subItem?.scientificName));
        }

        return value.has(item[key]);
      })
    );

    const updatedSelections = {};
    Object.entries(selectedItems).forEach(([key, value]) => {
      updatedSelections[key] = new Set(
        Array.from(value).filter((item) => {
          if (key === "crops") {
            return filteredData.some((filteredItem) =>
              filteredItem[key].some((subItem) => subItem.commonName === item)
            );
          }
          if (key === "pests") {
            return filteredData.some((filteredItem) =>
              filteredItem[key].some((subItem) => subItem.scientificName === item)
            );
          }
          return filteredData.some((filteredItem) => filteredItem[key] === item);
        })
      );
    });
    if (isFilterByDroneAssessment || isFilterByOrthoMosaic) {
      filteredData = filteredData.filter((item) => {
        if (isFilterByDroneAssessment && isFilterByOrthoMosaic) {
          return item.has_ortho && item.has_drone_data;
        } else if (isFilterByDroneAssessment && !isFilterByOrthoMosaic) {
          return item.has_drone_data;
        } else if (!isFilterByDroneAssessment && isFilterByOrthoMosaic) {
          return item.has_ortho;
        }
      });
    }
    setSelectedItems(updatedSelections);
    setTrialsList(filteredData);
    setRefineCancel(true);
    popMarker();
    setRefinedSearch(false);
    setShow(null);
    setRefinedSearchText(
      refinedAccordions.reduce((acc, category) => {
        acc[category.key] = "";
        return acc;
      }, {})
    );
  };

  const onRefineCancel = () => {
    setRefinedSearch(false);
    setSelectedItems({});
    setShow(null);
    setIsFilterByDroneAssessment(false);
    setIsFilterByOrthoMosaic(false);
    setRefinedSearchText(
      refinedAccordions.reduce((acc, category) => {
        acc[category.key] = "";
        return acc;
      }, {})
    );
    if (refineCancel) {
      setRefineCancel(false);
    }
  };

  const onClearAll = () => {
    setSelectedItems({});
    setRefinedSearchText(
      refinedAccordions.reduce((acc, category) => {
        acc[category.key] = "";
        return acc;
      }, {})
    );
    setTrialsList(refinedSearchTrialList);
    setIsFilterByDroneAssessment(false);
    setIsFilterByOrthoMosaic(false);
    popMarker();
    setShow(null);
  };

  return (
    <div className="map-viewer" style={{ backgroundColor: "lightgrey", height: "100vh" }}>
      {(yearLoader || searchLoader) && (
        <div className="center-spinner new-layout-loader" data-testId="landing-loader">
          <Spinner animation="border" className="date-spinner" />
        </div>
      )}
      {!(customMobilePortrait || customMobileLandscape) ? (
        <MapDesktopBar 
          setYearLoader={setYearLoader}
          setSelectedResult={setSelectedResult}
          setTrialsList={setTrialsList}
          setShowWelcomeText={setShowWelcomeText}
          setMyTrials={setMyTrials}
          myTrials={myTrials}
          setYearSelection={setYearSelection}
          setIsOn={setIsOn}
          isOn={isOn}
          trialsList={trialsList}
          setTopBookmarkTrail={setTopBookmarkTrail}
          setRefinedSearch={setRefinedSearch}
          topBookmarkTrail={topBookmarkTrail}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          yearList={yearList}
          searchText={searchText}
          setSearchText={setSearchText}
          showAutoSuggestionPopup={showAutoSuggestionPopup}
          trialsByYear={trialsByYear}
          setShowAutoSuggestionPopup={setShowAutoSuggestionPopup}
          setRefinedSearchTrialslist={setRefinedSearchTrialslist}
          popMarker={popMarker}
          myTrialToggleButton={myTrialToggleButton}
          setSelectedItems={setSelectedItems}
          setRefinedSearchText={setRefinedSearchText}
          refinedAccordions={refinedAccordions}
          setIsFilterByDroneAssessment={setIsFilterByDroneAssessment}
          setIsFilterByOrthoMosaic={setIsFilterByOrthoMosaic}
          isMobile={customMobilePortrait}
          isMobileLandscape={customMobileLandscape}
          isTablet={customTabletDimensions()}
        />
      ) : (
        <div className="landing-mobiletopbar">
          <MapMobileTopBar
            setYearLoader={setYearLoader}
            setSelectedResult={setSelectedResult}
            setTrialsList={setTrialsList}
            setShowWelcomeText={setShowWelcomeText}
            setRefinedSearch={setRefinedSearch}
            refinedSearch={refinedSearch}
            setMyTrials={setMyTrials}
            myTrials={myTrials}
            setYearSelection={setYearSelection}
            setIsOn={setIsOn}
            isOn={isOn}
            trialsList={trialsList}
            setTopBookmarkTrail={setTopBookmarkTrail}
            topBookmarkTrail={topBookmarkTrail}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            yearList={yearList}
            searchText={searchText}
            setSearchText={setSearchText}
            showAutoSuggestionPopup={showAutoSuggestionPopup}
            trialsByYear={trialsByYear}
            setShowAutoSuggestionPopup={setShowAutoSuggestionPopup}
            setRefinedSearchTrialslist={setRefinedSearchTrialslist}
            popMarker={popMarker}
            myTrialToggleButton={myTrialToggleButton}
            setSelectedItems={setSelectedItems}
            setRefinedSearchText={setRefinedSearchText}
            refinedAccordions={refinedAccordions}
            setIsFilterByDroneAssessment={setIsFilterByDroneAssessment}
            setIsFilterByOrthoMosaic={setIsFilterByOrthoMosaic}
            isMobile={customMobilePortrait}
            isMobileLandscape={customMobileLandscape}
            isTablet={customTabletDimensions()}
          />
        </div>
      )}
      <div data-testId="landing-page-leftside-cards"style={{ position: "absolute", zIndex: customMobileLandscape ? 99 : customTabletDimensions() ? 9999 : 999 }}>
        {showCardsAtLeft ? (
          <>
            {(trialsList?.length > 0 || trialsList?.length === 0) && (
              <div
                className={`${customMobileLandscape ? "landscape-trialui-card" : "desktop-trailui-card"
                  }`}
                tabIndex="-1">
                {customMobileLandscape && (
                  <div className="d-flex p-2 justify-content-between align-items-center">
                    {showResultBar()}
                  </div>
                )}
                <div
                  className="overflow-auto"
                  style={{
                    height: customMobileLandscape ? "calc(100vh - 125px)" : "calc(100vh - 60px)"
                  }}>
                  {trialsList?.map((item) => {
                    const crops = item?.crops?.filter(({ commonName }) => commonName);
                    const trial = item?.trial_id ? item?.trial_id : item?.trial_name;
                    return (
                      <Card data-testId="cards-data"
                        ref={(el) => (trialCardRef.current[trial] = el)}
                        className={`trail-card
                    ${(trailSummaryColor === trial &&
                            !topBookmarkTrail &&
                            trailSumamryResult &&
                            !customMobilePortrait &&
                            !customMobileLandscape) ||
                            cardClicked === trial
                            ? "trailcardBackground"
                            : "noBackgroundCard"
                          }
                   `}
                        style={{
                          background: "white"
                        }}
                        key={item?.trial_id ? item?.trial_id : item?.trial_name}
                        onClick={() => handleCardClick(item)}
                        tabIndex="0">
                        <Card.Body className="text-left p-2">
                          <Card.Text>
                            <div className="d-flex justify-content-between align-items-center pb-2">
                              <div style={{ width: "55%" }}>
                                <span className="map-card-FirstLine">
                                  {item?.trial_id ? item?.trial_id : item?.trial_name}
                                </span>
                              </div>
                              <div
                                style={{ width: "75%" }}
                                className="d-flex align-items-center justify-content-between cursor-pointer">
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={(e) => {
                                    handleCardClick(item);
                                    handleTrialSummaryClick(e, trial);
                                  }}
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter" || event.key === " ") {
                                      handleTrialSummaryClick(event, trial);
                                    }
                                  }}>
                                  <img
                                    className="trial-summary-icon"
                                    alt=""
                                    src={trialSummaryOrangeIcon}
                                    height={24}
                                    width={24}
                                    style={{
                                      background:
                                        trailSummaryColor === trial && trailSumamryResult
                                          ? "#FFE8BC"
                                          : "",
                                      borderRadius: "50px"
                                    }}
                                  />
                                </div>
                                <span>
                                  {bookMarkedTrialsConst.includes(trial) || item?.is_bookmark ? (
                                    <BookmarkFill
                                      className="fill bookmark-icon-color cursor-pointer"
                                      height={24}
                                      width={18}
                                      onClick={(e) => onClickOfBookMark(e, item)}
                                    />
                                  ) : (
                                    <Bookmark
                                      className="empty cursor-pointer bookmark-style-empty"
                                      height={24}
                                      width={18}
                                      onClick={(e) => onRemoveOfBookMark(e, item)}
                                    />
                                  )}
                                </span>
                                <a href={`/app/viewer?trial=${trial}`}>
                                  <button
                                    className="data-button d-flex align-items-center justify-content-center"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(`/app/viewer?trial=${trial}`, "_self");
                                    }}>
                                    <span>DATA</span>
                                    <ChevronRight className="pl-1" height={14} width={14} />
                                  </button>
                                </a>
                                <span>
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={(props) => renderLandingPageTooltip(props, item)}
                                    trigger={["hover", "click"]}>
                                    <InfoCircle
                                      // className=" cursor-pointer"
                                      className="info-desktop-tooltip"
                                      height={24}
                                      width={18}
                                      color="#636363"
                                      ref={tooltipRef}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (showTooltip === trial) {
                                          setShowTooltip();
                                        } else {
                                          handleTooltipButtonClick(
                                            item?.trial_id ? item?.trial_id : item?.trial_name
                                          );
                                        }
                                      }}
                                    />
                                  </OverlayTrigger>
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div style={{ width: "55%" }}>
                                <div className="mb-1">
                                  <span>
                                    <img alt="" src={leafBlue} height={12} width={4} />
                                  </span>
                                  <span className="leaf-text font-size-extra-small font-weight-700 pl-1">
                                    {crops?.length > 2
                                      ? "Multi crops"
                                      : crops
                                        ?.slice(0, 2)
                                        ?.map(({ commonName }) => commonName)
                                        ?.join(", ") || "No crop information"}
                                  </span>
                                </div>
                                <div>
                                  <span className="font-weight-400 font-size-small">
                                    {item?.protocol_id}
                                  </span>
                                </div>
                                {item?.master_protocol && (
                                  <div>
                                    <span className=" font-weight-400 font-size-small">
                                      {item?.master_protocol?.protocolId}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div style={{ width: "45%", marginTop: "4px" }}>
                                <div className="map-card-lines" style={{ whiteSpace: "nowrap" }}>
                                  <span className="map-card-logo mr-2">T</span>
                                  <span className="font-size-extra-small font-weight-400">
                                    {item?.trial_owner?.trim()}
                                  </span>
                                </div>
                                {item?.master_protocol !== null &&
                                  item?.master_protocol?.ownerName?.trim() !== undefined &&
                                  item?.master_protocol?.ownerName?.trim() !==
                                  item?.protocol_owner?.trim() && (
                                    <div>
                                      <span className="map-card-logo mr-2">D</span>
                                      <span className="font-size-extra-small font-weight-400">
                                        {item?.protocol_owner?.trim()}
                                      </span>
                                    </div>
                                  )}
                                <div>
                                  <span className="map-card-logo mr-2">M</span>
                                  <span className="font-size-extra-small font-weight-400">
                                    {item?.master_protocol !== null &&
                                      item?.master_protocol?.ownerName?.trim() !== undefined
                                      ? item?.master_protocol?.ownerName?.trim()
                                      : item?.protocol_owner && item?.protocol_owner?.trim()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </div>
              </div>
            )}
            {trialsList?.length === 0 && selectedResult && customMobileLandscape && (
              <div className="landscape-trialui-card" style={{ width: "354px" }}>
                {customMobileLandscape && (
                  <div className="d-flex p-4 justify-content-between align-items-center">
                    {showResultBar()}
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          (trialsList?.length > 0 || trialsList?.length === 0) && (
            <div className="trial-bottom-row fixed-bottom" style={{ zIndex: customMobilePortrait ? 0 : 9999 }}>
              <div className="d-flex m-2 justify-content-between align-items-center">
                {showResultBar()}
              </div>

              <div
                className="trial-card-wrapper overflow-auto d-flex"
                style={{ flexWrap: "nowrap", scrollBehavior: "smooth" }}>
                {trialsList?.map((item) => {
                  const trial = item?.trial_id ? item?.trial_id : item?.trial_name;
                  return (
                    <Card
                      ref={(el) => (trialCardRef.current[trial] = el)}
                      key={item?.trial_id ? item?.trial_id : item?.trial_name}
                      className={`mb-2 trail-card trial-card-mobile ${(trailSummaryColor === trial &&
                        !topBookmarkTrail &&
                        trailSumamryResult &&
                        !customMobilePortrait &&
                        !customMobileLandscape) ||
                        cardClicked === trial
                        ? "trailcardBackground"
                        : "noBackgroundCard"
                        }`}
                      style={{
                        backgroundColor: "white"
                      }}
                      onClick={() => handleCardClick(item)}>
                      {trialCardBody(item)}
                    </Card>
                  );
                })}
              </div>
            </div>
          )
        )}
      </div>
      <div
        data-testId="map"
        id="map"
        className={`leaflet-container leaflet-retina ${!showCardsAtLeft
          ? trialsList?.length > 0
            ? "trial-cards-at-bottom"
            : ""
          : trialsList?.length > 0
            ? "trial-cards-at-left"
            : ""
          } ${!showCardsAtLeft && selectedResult ? "trial-cards-at-bottom-with-result" : ""}`}>
        {showWelcomeText && (
          <div
            style={{
              top: customMobileLandscape ? "100px" : customMobilePortrait ? "170px" : "90px",
              right: customMobileLandscape && "10%"
            }}
            className={`welcome-wrapper ${customMobilePortrait ? "welcome-wrapper-mobile" : ""} ${customTabletDimensions() && !customMobileLandscape ? "welcome-wrapper-tablet" : ""
              }`}>
            <div>
              <div className="welcome-primary-text">
                <span>WELCOME!</span>
                <img src={welcomeArrow} alt="" />
              </div>
              <div className="welcome-secondary-text">Start your journey by searching</div>
            </div>
          </div>
        )}
      </div>
      {trailSumamryResult && (
        <div>
          <Modal
            id="trialSummaryID"
            ref={trialSummaryRef}
            show={trailSumamryResult}
            //centered
            className={
              customMobilePortrait
                ? "mobileMapTrialSummary"
                : customMobileLandscape
                  ? "landScapeMapTrialSummary"
                  : !(customMobilePortrait || customMobileLandscape || customTabletDimensions())
                    ? "desktopMapTrialSummary"
                    : "tabMapTrialSummary"
            }
            onHide={handleTrialSummaryClose}>
            <Modal.Body className="py-0">
              {/* <div className="close-button" onClick={() => setTrailSummaryResult(false)}>
                      &times;
                    </div> */}
              <div>
                <NewViewerTrialSummary
                  trail={selectedTrail}
                  droneFlightDates={droneFlightDates}
                  target={pestData}
                  isMobile={customMobilePortrait}
                  isMobileLandscape={customMobileLandscape}
                  // isSmallDevice={isSmallDevice}
                  isTablet={customTabletDimensions()}
                  trailSumamryResult={trailSumamryResult}
                  setTrailSummaryResult={setTrailSummaryResult}
                  handleTrialSummaryClose={handleTrialSummaryClose}
                  isDesktop={isDesktop}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      {isDesktop &&
        (trialsList?.length > 0 ||
          (trialsList?.length === 0 && selectedResult) ||
          (trialsList.length === 0 && topBookmarkTrail)) &&
        !customMobileLandscape && !customTabletDimensions() && (
          <div
            className={`search-result-bar ${trialsList?.length > 0 ? "search-result-bar-with-trials" : ""
              }`} data-testId="result-bar">
            {showResultBar()}
          </div>
        )}
      {refinedSearch && (
        <div
          className={customMobileLandscape ? "refined-landscape-search" : "refined-search"}
          ref={refineSearchRef}
          style={{
            width: customMobilePortrait ? "100%" : "354px",
            zIndex: customMobilePortrait ? 99999 : customTabletDimensions() ? 1000 : 9999,
            top: customMobilePortrait ? "80px" : "60px"
          }}>
          <div className="refined-search-div">
            <span className="refined-search-title">Refine Search</span>
            <span
              className="refined-search-ClearAll cursor-pointer"
              style={{ marginLeft: customMobilePortrait ? "110px" : "60px" }}
              onClick={() => {
                onClearAll();
              }}>
              CLEAR FILTERS
            </span>
            <span>
              <X
                width={24}
                height={24}
                fill="#293306"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onRefineCancel();
                }}
              />
            </span>
          </div>
          <hr />
          <div
            style={{
              maxHeight:
                customMobilePortrait || customMobileLandscape
                  ? "calc(100vh - 250px)"
                  : customTabletDimensions()
                    ? "calc(100vh - 485px)"
                    : isDesktop
                      ? "calc(100vh - 220px)"
                      : "",
              overflowY: "scroll",
              overflowX: "hidden"
            }}>
            <Accordion className="refined-accordion" activeKey={show}>
              {refinedAccordions.map((item, ItemIndex) => {
                if (!particularCategoryPresent(item)) return null;
                const totalCount = optionsForRefinedSearch(item);
                const options = refinedSerachText[item.key]
                  ? totalCount.filter((option) =>
                    option.toLowerCase().includes(refinedSerachText[item.key].toLowerCase())
                  )
                  : totalCount;

                return (
                  <div className="refined-div-accordion" key={item.key}>
                    <Accordion.Item eventKey={ItemIndex.toString()}>
                      <Accordion.Header onClick={() => handleToggle(ItemIndex.toString())}>
                        <div>
                          <span
                            className="refined-accodion-heading"
                            style={{ fontWeight: show === ItemIndex.toString() ? 700 : 400 }}>
                            {`${item.label} (${totalCount.length})`}
                          </span>
                          <span className="refined-icon-up-down">
                            {show === ItemIndex.toString() ? (
                              <ChevronUp
                                class="collapse-icon"
                                style={{ fontWeight: "bold" }}
                                role="button"
                              />
                            ) : (
                              <ChevronDown class="collapse-icon" style={{ fontWeight: "bold" }} />
                            )}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ position: "relative" }}>
                        <div>
                          <RefinedSearch
                            refinedSerachText={refinedSerachText}
                            options={options}
                            category={item}
                            handleRefinedSearch={handleRefinedSearch}
                            clearSearchText={clearSearchText}
                            handleRefinedCheckBoxes={handleRefinedCheckBoxes}
                            selectedItems={selectedItems}
                            isMobile={customMobilePortrait}
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {refinedAccordions?.length > 0 && (
                      <hr style={{ width: isMobile ? "348px" : "" }} />
                    )}
                  </div>
                );
              })}
            </Accordion>
            <div>
              <label className="refinedsearch-label" style={{ marginLeft: "22px" }}>
                <input
                  type="checkbox"
                  className="refinedsearch-checkbox-icon"
                  checked={isFilterByOrthoMosaic}
                  onChange={(e) => setIsFilterByOrthoMosaic(e.target.checked)}
                />
                <span className="reinedsearch-checkbox-data" style={{ fontSize: "14px" }}>
                  Orthomosaic
                </span>
              </label>
            </div>
            <div>
              <label className="refinedsearch-label" style={{ marginLeft: "22px" }}>
                <input
                  type="checkbox"
                  className="refinedsearch-checkbox-icon"
                  checked={isFilterByDroneAssessment}
                  onChange={(e) => setIsFilterByDroneAssessment(e.target.checked)}
                />
                <span className="reinedsearch-checkbox-data" style={{ fontSize: "14px" }}>
                  Drone Assessments
                </span>
              </label>
            </div>
          </div>
          <hr
            style={{
              position: customMobileLandscape ? " " : "fixed",
              bottom: customMobileLandscape ? " " : customTabletDimensions() ? "240px" : "52px",
              width: customMobileLandscape ? " " : customMobilePortrait ? "346px" : "100%",
              marginLeft: customMobilePortrait ? "15px" : customMobileLandscape ? " " : "0px"
            }}
          />
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              position: customMobileLandscape ? " " : "fixed",
              bottom: customMobileLandscape ? " " : customMobilePortrait ? "15px" : customTabletDimensions() ? "190px" : "15px",
              width: "inherit"
            }}>
            <Button className="refined-search-cancel" onClick={onRefineCancel}>
              CANCEL
            </Button>
            <Button className="refined-search-apply" onClick={applyRefinedSearchFilters}>
              APPLY
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
