import { useState, useEffect } from "react";
import propTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { siteListAPI, loginSyt } from "../../services/API";
import { CircularProgress, Stack } from "@mui/material";

export default function SitesList() {
  document.title = "Sites";
  const [siteListData, setSiteListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const idToken = sessionStorage.getItem("idToken");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  let navigate = useNavigate();

  const onSiteDetailsClick = (elem) => {
    let path = `/home/sites/${location.pathname.split("/")[4]}/${elem?.id}`;
    navigate(path, {
      state: {
        name: elem?.site,
        id: elem?.id,
      }
    });
  };

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          siteListAPI(location.pathname.split("/")[4], (data) => {
            setSiteListData(data?.site_lists);
          });
          setLoading(false);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [location.pathname.split("/")[3]]);

  return (
    <>
      {loading ? (
        <Stack
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress
            size={80}
            thickness={6}
            sx={{
              color: "green",
              "--CircularProgress-size": "80px",
              top: "10%",
              left: "50%"
            }}
          />
        </Stack>
      ) : 
        <div
          className="flex-container"
          style={{ marginBottom: isMobile ? "50px" : isIpad ? "130px" : "30px" }}>
          {siteListData &&
        siteListData.map((elem, index) => (
          <div className="divflex" key={index} onClick={() => onSiteDetailsClick(elem)}>
            <div className="card-header" key={index}>
              <span className="initial-avatar">{elem?.site.charAt(0)}</span>
              <span className="card-title">{elem?.site}</span>
            </div>
            <ul className="card-details">
              <li className="card-blocks">
                <b>{elem?.block}</b> blocks
              </li>
              <hr className="card-divider" />
              <li className="card-blocks">
                <b>{elem?.collection}</b> collections
              </li>
            </ul>
          </div>
        ))}
        </div>
      }
    </>
  );
}

SitesList.propTypes = {
  collectionData: propTypes.any,
  selectedYear: propTypes.any
};
