import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Fullscreen } from "react-bootstrap-icons";
import propTypes from "prop-types";
export default function TabTools({
  ShowFullMap,
  measurementToolVisible,
  switchMeasurementTool,
  measurementIcon
}) {
  return (
    <div
      className="web-tools-container"
      style={{
        backgroundColor: "#ddd",
        position: "absolute",
        top: "20%",
        zIndex: 998
      }}> 
      <Button className="d-flex align-items-center tools-button" onClick={ShowFullMap}>
        <Fullscreen color="black" height={18} width={18} />
      </Button>
      <Button
        className={`d-flex p-1 align-items-center tools-button measurement-icon ${
          measurementToolVisible ? "isGrey" : "isWhite"
        }`}
        onClick={() => switchMeasurementTool(!measurementToolVisible)}>
        <img src={measurementIcon} width={30} alt=""/>
      </Button>
    </div>
  );
}
TabTools.propTypes = {
  ShowFullMap: propTypes.any,
  measurementToolVisible: propTypes.any,
  switchMeasurementTool: propTypes.any,
  measurementIcon: propTypes.any,
};
