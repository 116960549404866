import { useEffect, useState } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import propTypes from "prop-types";
import {
  Typography,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  Link,
  TableContainer,
  CircularProgress,
  Stack
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@mui/styles";
import { dataConverstion, formatDateNew } from "../../services/Common";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { siteDetailsAPI, loginSyt } from "../../services/API";

const useStyles = makeStyles(() => ({
  cardStyle: {
    borderRadius: "0.25rem",
    boxShadow: "0 0 10px 0 rgba(229,234,250,.75)"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#FFFFD0 !important"
    }
  },
  dateCss: {
    color: "green",
    paddingLeft: "20px",
    "&:hover": {
      color: "#003400",
      textDecoration: "underline"
    }
  }
}));

export default function SiteDetails({ selectedName }) {
  const classes = useStyles();
  const [filteredCollection, setFilteredCollection] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let navigate = useNavigate();
  document.title =
    location?.state !== null ? location?.state?.name : filteredCollection?.[0]?.siteTitle;
  const basicURL = process.env.REACT_APP_BASE_URL;

  const groupingData = (detailsData) => {
    const resultsDataGroup =
      detailsData?.resultfile_details.length > 0 &&
      detailsData?.resultfile_details.reduce((acc, obj) => {
        let collection_id = obj?.collection_id;
        let index = acc.findIndex((item) => {
          return item?.collection_id === collection_id;
        });
        if (index === -1) {
          acc.push({
            collection_id: collection_id,
            results_files: [
              {
                filesize: obj?.filesize,
                res_type: obj?.res_type,
                resultfile_id: obj?.resultfile_id
              }
            ]
          });
        } else {
          acc[index].results_files.push({
            filesize: obj?.filesize,
            res_type: obj?.res_type,
            resultfile_id: obj?.resultfile_id
          });
        }
        return acc;
      }, []);
    resultsDataGroup &&
      resultsDataGroup.forEach((item) => {
        let results =  item?.results_files;
        if(results[1]?.res_type==="csv_zip"){
          let temp  =  results[1];
          results[1] = results[0];
          results[0]=temp;
        }
      });

    let resultsPlusdiffIds = {};
    resultsDataGroup &&
      resultsDataGroup?.forEach((item) => {
        const collection_id = item["collection_id"];
        resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
      });

    detailsData?.rasterlayer_details?.length > 0 &&
      detailsData?.rasterlayer_details.forEach((item) => {
        const collection_id = item["collection_id"];
        resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
      });

    detailsData?.site_details &&
      detailsData?.site_details.forEach((item) => {
        const collection_id = item["collection_id"];
        resultsPlusdiffIds[collection_id] = { ...resultsPlusdiffIds[collection_id], ...item };
      });

    let productName = [];
    Object.values(resultsPlusdiffIds)?.filter((o) => {
      const newIndex = productName.findIndex((i) => i?.tablename === o.block);
      if (newIndex > -1) {
        productName[newIndex].collection.push({
          results_files: o?.results_files !== undefined ? o?.results_files : [],
          date: o?.date,
          collection_id: o?.collection_id,
          ndvi_id: o?.ndvi_id !== undefined ? o?.ndvi_id : null,
          rgb_id: o?.rgb_id !== undefined ? o?.rgb_id : null,
          dem_id: o?.dem_id !== undefined ? o?.dem_id : null
        });
      } else {
        if (o?.block !== undefined) {
          return productName.push({
            tablename: o?.block,
            siteTitle: o?.site,
            collection: [
              {
                results_files: o?.results_files !== undefined ? o?.results_files : [],
                date: o?.date,
                collection_id: o?.collection_id,
                ndvi_id: o?.ndvi_id !== undefined ? o?.ndvi_id : null,
                rgb_id: o?.rgb_id !== undefined ? o?.rgb_id : null,
                dem_id: o?.dem_id !== undefined ? o?.dem_id : null
              }
            ]
          });
        }
      }
      return null;
    });
    productName.length > 0 && productName.sort((a, b) => a?.tablename.localeCompare(b?.tablename));
    selectedName(productName?.[0]?.siteTitle);
    setFilteredCollection(productName);
  };

  useEffect(
    () => {
      loginSyt(
        sessionStorage.getItem("idToken"),
        (res) => {
          if (res === 200) {
            siteDetailsAPI(
              location.pathname.split("/")[3],
              location.pathname.split("/")[4],
              (data) => {
                groupingData(data);
                setLoading(false);
              }
            );
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    },
    [location.pathname.split("/")[3], location.pathname.split("/")[4]],
    sessionStorage.getItem("idToken")
  );

  const onDateClick = (id) => {
    let path = "/oldviewer";
    navigate({
      pathname: path,
      search: `?${createSearchParams({
        collection_id: id?.collection_id
      })}`
    });
  };
  
  return (
    <>
      {loading ? (
        <Stack
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress
            size={80}
            thickness={6}
            sx={{
              color: "green",
              "--CircularProgress-size": "80px",
              top: "10%",
              left: "50%"
            }}
          />
        </Stack>
      ) : (
        <div style={{ backgroundColor: "#fafbfe", height: "100vh" }}>
          <Typography align="center" variant="h4" style={{ marginBottom: "15px" }}>
            {filteredCollection?.[0]?.siteTitle}
          </Typography>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            sx={{ marginBottom: "20px", paddingBottom: isMobile ? "100px" : "10px" }}>
            {filteredCollection.map((val, key) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={key}>
                <TableContainer
                  component="div"
                  style={{
                    boxShadow: "0 0 10px 0 rgba(229,234,250,.75)",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                    height: "100%",
                    border: "1px solid rgba(224, 224, 224, 1)"
                  }}>
                  <Table container className={classes.cardStyle} size="small">
                    <>
                      <TableHead>
                        <TableRow
                          key={key}
                          sx={{
                            borderTop: "3px solid green",
                            boxShadow: 1
                          }}>
                          <TableCell
                            colSpan={3}
                            sx={{
                              padding: "5px",
                              backgroundColor: "black",
                              color: "white",
                              textAlign: "center",
                              fontSize: "0.95rem",
                              fontWeight: 500,
                              lineHeight: "1.2"
                            }}>
                            {val?.tablename}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {val?.collection
                            .sort(function (a, b) {
                              return new Date(a?.date) - new Date(b?.date);
                            })
                            .map((newValue, i) => (
                              <TableRow
                                className={
                                  newValue?.dem_id === null &&
                                  newValue?.rgb_id === null &&
                                  newValue?.ndvi_id === null
                                    ? null
                                    : classes.tableRow
                                }
                                key={i}>
                                <TableCell
                                  style={{
                                    backgroundColor: i % 2 == 0 ? "rgba(0,0,0,.075)" : "",

                                    cursor:
                                      newValue?.dem_id === null &&
                                      newValue?.rgb_id === null &&
                                      newValue?.ndvi_id === null
                                        ? "default"
                                        : "pointer"
                                  }}
                                  size="small"
                                  padding="none">
                                  <Link
                                    className={
                                      newValue?.dem_id === null &&
                                      newValue?.rgb_id === null &&
                                      newValue?.ndvi_id === null
                                        ? "datelink"
                                        : "datelinknone"
                                    }
                                    style={{
                                      textDecoration:
                                        newValue?.dem_id === null &&
                                        newValue?.rgb_id === null &&
                                        newValue?.ndvi_id === null
                                          ? "none"
                                          : null,
                                      cursor:
                                        newValue?.dem_id === null &&
                                        newValue?.rgb_id === null &&
                                        newValue?.ndvi_id === null
                                          ? "default"
                                          : "pointer",

                                      paddingLeft: "20px"
                                    }}
                                    onClick={() =>
                                      newValue?.dem_id === null &&
                                      newValue?.rgb_id === null &&
                                      newValue?.ndvi_id === null
                                        ? null
                                        : onDateClick(newValue)
                                    }>
                                    <Typography variant="h8">
                                      {formatDateNew(newValue?.date)}
                                    </Typography>
                                  </Link>
                                </TableCell>

                                <TableCell
                                  style={{
                                    backgroundColor: i % 2 == 0 ? "rgba(0,0,0,.075)" : "",
                                    cursor:
                                      newValue?.dem_id === null &&
                                      newValue?.rgb_id === null &&
                                      newValue?.ndvi_id === null
                                        ? "default"
                                        : "pointer",
                                    paddingTop: "6px",
                                    paddingBottom: " 6px"
                                  }}
                                  size="small"
                                  padding="none">
                                  <Link
                                    style={{
                                      textDecoration:
                                        newValue?.results_files[1]?.res_type !== "pdf_zip"
                                          ? "none"
                                          : null,
                                      cursor:
                                        newValue?.results_files[1]?.res_type !== "pdf_zip"
                                          ? "default"
                                          : null,

                                      color:
                                        newValue?.dem_id === null &&
                                        newValue?.rgb_id === null &&
                                        newValue?.ndvi_id === null
                                          ? "black"
                                          : "green"
                                    }}
                                    href={
                                      newValue?.results_files[1]?.res_type === "pdf_zip"
                                        ? `${basicURL}/api/resultsfile/${newValue?.results_files[1]?.resultfile_id}`
                                        : null
                                    }>
                                    <IconButton sx={{ padding: 0 }}>
                                      {newValue?.results_files[1]?.res_type === "pdf_zip" ? (
                                        <DownloadIcon sx={{ color: "black", fontSize: "15px" }} />
                                      ) : null}
                                    </IconButton>

                                    <Typography
                                      variant="h8"
                                      style={{
                                        color:
                                          newValue?.results_files[1]?.res_type === "pdf_zip"
                                            ? "green"
                                            : "black",

                                        fontSize: ".7rem"
                                      }}>
                                      {newValue?.results_files[1]?.res_type == "pdf_zip"
                                        ? "pdf "
                                        : "n/a"}
                                    </Typography>
                                  </Link>

                                  <Typography
                                    variant="h8"
                                    sx={{ fontSize: ".7rem", color: "#6c757d!important" }}>
                                    {newValue?.results_files[1]?.filesize
                                      ? dataConverstion(newValue?.results_files[1]?.filesize)
                                      : ""}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  style={{
                                    backgroundColor: i % 2 == 0 ? "rgba(0,0,0,.075)" : "",
                                    cursor:
                                      newValue?.dem_id === null &&
                                      newValue?.rgb_id === null &&
                                      newValue?.ndvi_id === null
                                        ? "default"
                                        : "pointer"
                                  }}
                                  size="small"
                                  padding="none">
                                  <Link
                                    style={{
                                      textDecoration:
                                        newValue?.results_files[0]?.res_type !== "csv_zip"
                                          ? "none"
                                          : null,

                                      cursor:
                                        newValue?.results_files[0]?.res_type !== "csv_zip"
                                          ? "default"
                                          : null,

                                      color:
                                        newValue?.dem_id === null &&
                                        newValue?.rgb_id === null &&
                                        newValue?.ndvi_id === null
                                          ? "black"
                                          : "green"
                                    }}
                                    href={
                                      newValue?.results_files[0]?.res_type === "csv_zip"
                                        ? `${basicURL}/api/resultsfile/${newValue?.results_files[1]?.resultfile_id}`
                                        : null
                                    }>
                                    <IconButton sx={{ padding: 0 }}>
                                      {newValue?.results_files[0]?.res_type == "csv_zip" ? (
                                        <DownloadIcon sx={{ color: "black", fontSize: "15px" }} />
                                      ) : null}
                                    </IconButton>

                                    <Typography
                                      variant="h8"
                                      style={{
                                        color:
                                          newValue?.results_files[0]?.res_type === "csv_zip"
                                            ? "green"
                                            : "black",

                                        fontSize: ".7rem"
                                      }}>
                                      {newValue?.results_files[0]?.res_type === "csv_zip"
                                        ? "excel "
                                        : "n/a"}
                                    </Typography>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
}

SiteDetails.propTypes = {
  selectedYear: propTypes.any,
  selectedName: propTypes.func
};
