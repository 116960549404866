import { Search, XLg, EmojiFrown } from "react-bootstrap-icons";
import { propTypes } from "react-bootstrap/esm/Image";

export default function RefinedSearch({
  options,
  category,
  handleRefinedSearch,
  refinedSerachText,
  clearSearchText,
  handleRefinedCheckBoxes,
  selectedItems,
  isMobile,
}) {
  
  /*To allow only alphanumeric characters in search*/
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
      "Escape",
      "Enter",
      " "
    ];
    if (!e.key.match(/^[a-z0-9]+$/i) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <div>
      <div style={{ width: "356px", height: "48px", position: "relative" }}>
          <input
            type="text"
            style={{width:  isMobile ? "330px" : "322px"}}
          
            className="refinedsearch-input"
            onChange={(e) => handleRefinedSearch(category.key, e.target.value)}
          onKeyDown={handleKeyDown}
          value={refinedSerachText[category.key] || ""}
          placeholder="Search..."
           
          />
          <span
            style={{
              position: "absolute",
              right: isMobile  ? "8%" : "6%",
              top: "10px"
            }}>
            {refinedSerachText[category.key].length === 0  ? (
              <Search onClick={() => {}} width={15} height={15} fill="#0D1A40" />
            ) : (
              <XLg
                color="#0D1A40"
                role="button"
                onClick={() => clearSearchText(category.key)}
              onKeyDown={() => clearSearchText(category.key)}
              />
            )}
          </span>
        </div>
      {options.length > 0 ? (
        options.map((option, idx) => (
          <div key={`${category.key}-${idx}`}>
            <label className="refinedsearch-label">
              <input
                type="checkbox"
                className="refinedsearch-checkbox-icon"
                checked={selectedItems[category.key]?.has(option) || false}
                onChange={(e) => handleRefinedCheckBoxes(category.key, option, e.target.checked)}
              />
              <span className="reinedsearch-checkbox-data">{option}</span>
            </label>
          </div>
        ))
      ) : (
        <div className="d-flex ml-3 align-items-center p-3 mb-3">
          <EmojiFrown color="#FF3F3F"/>
          <span className="DFS-no-data-text ml-2">Sorry we could not find any results</span>
        </div>
      )}
    </div>
  );
}
RefinedSearch.propTypes = {
  options: propTypes.any,
  category: propTypes.any,
  handleRefinedSearch: propTypes.any,
  refinedSerachText: propTypes.any,
  clearSearchText: propTypes.any,
  handleRefinedCheckBoxes: propTypes.any,
  selectedItems: propTypes.any,
  isMobile: propTypes.any,
};
