import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { ArrowsAngleContract, ArrowsAngleExpand } from "react-bootstrap-icons";
import propTypes from "prop-types";

export default function DesktopAndModalNav({
  modelBool,
  showTreatment,
  setShowTreatment,
  barExpand,
  setBarExpand,
  showTime,
  setShowTime,
  setShowCompare,
  showCompare,
  setFightDataLoaded,
  setTreatmentDataLoaded,
  setCompareData1Loaded,
  setCompareData2Loaded,
  timeChartUrl,
  // showTreatmentUrl,
  showCompareUrl
}) {
  return (
    <div className="pl-2 pr-2">
      <div
        style={{
          width: "100%",
          border: "0.5px solid #E8E8E8",
          display: "flex",
          flexDirection: "row"
        }}>
        <div
          className="col-3 d-flex flex-column justify-content-center"
          style={{
            backgroundColor: showTreatment  && !timeChartUrl ? "#FFE8BC" : "white"
          }}>
          <Button
            className="d-flex flex-column align-items-center"
            style={{
              border: "none",
              height: "60px",
              backgroundColor: showTreatment && !timeChartUrl ? "#FFE8BC" : "white",
              boxShadow: "none"
            }}
            onClick={() => {
              setShowTreatment(true);
              setShowTime(false);
              setShowCompare(false);
              setTreatmentDataLoaded(true);
            }}>
            <span
              style={{
                color: "#0D1A40",
                fontSize: "12px",
                marginTop: "12px",
                fontFamily: "Helvetica",
                fontWeight: showTreatment ? 700 : 400
              }}>
              Treatment Summary
            </span>
          </Button>
        </div>
        <div
          className="col-2 d-flex flex-column justify-content-center"
          style={{
            backgroundColor: showTime || timeChartUrl ? "#FFE8BC" : "white"
          }}>
          <Button
            className="d-flex flex-column align-items-center"
            style={{
              border: "none",
              height: "60px",
              backgroundColor: showTime || timeChartUrl ? "#FFE8BC" : "white",
              boxShadow: "none"
            }}
            onClick={() => {
              setShowTime(true);
              setShowTreatment(false);
              setShowCompare(false);

              setFightDataLoaded(true);
            }}>
            <span
              style={{
                color: "#0D1A40",
                fontSize: "12px",
                marginTop: "12px",
                fontFamily: "Helvetica",
                fontWeight: showTime ? 700 : 400
              }}>
              Time
            </span>
          </Button>
        </div>
        <div
          className="col-2 d-flex flex-column justify-content-center"
          style={{
            backgroundColor: showCompare || showCompareUrl ? "#FFE8BC" : "white"
          }}>
          <Button
            className="d-flex flex-column align-items-center"
            style={{
              border: "none",
              height: "60px",
              backgroundColor: showCompare || showCompareUrl ? "#FFE8BC" : "white",
              boxShadow: "none"
            }}
            onClick={() => {
              setShowCompare(true);
              setShowTreatment(false);
              setShowTime(false);
              setCompareData1Loaded(true);
              setCompareData2Loaded(true);
            }}>
            <span
              style={{
                color: "#0D1A40",
                fontSize: "12px",
                marginTop: "12px",
                fontFamily: "Helvetica",
                fontWeight: showCompare ? 700 : 400
              }}>
              Compare
            </span>
          </Button>
        </div>
        <div className="col-4"></div>
        {modelBool === true && (
          <div
            className="col-1 d-flex flex-column justify-content-center align-items-end"
            style={{
              backgroundColor: "white"
            }}>
            <Button
              className="d-flex flex-column align-items-center"
              style={{
                border: "none",
                height: "40px",
                backgroundColor: "white",
                boxShadow: "none"
              }}
              onClick={() => setBarExpand(!barExpand)}>
              {modelBool === true ? (
                <ArrowsAngleContract color="black" alignItems="center" width={24} height={24} />
              ) : (
                <ArrowsAngleExpand color="black" alignItems="center" width={24} height={24} />
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
DesktopAndModalNav.propTypes = {
  modelBool: propTypes.any,
  showTreatment: propTypes.any,
  setShowTreatment: propTypes.any,
  barExpand: propTypes.any,
  setBarExpand: propTypes.any,
  showTime: propTypes.any,
  setShowTime: propTypes.any,
  setShowCompare: propTypes.any,
  showCompare: propTypes.any,
  setFightDataLoaded: propTypes.any,
  setTreatmentDataLoaded: propTypes.any,
  setCompareData1Loaded: propTypes.any,
  setCompareData2Loaded: propTypes.any,
  timeChartUrl: propTypes.any,
  // showTreatmentUrl: propTypes.any,
  showCompareUrl: propTypes.any
};
