/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import propTypes from "prop-types";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import syngentaLogo from "../assests/Styles/assets/logo.png";
import mobileLogo from "../assests/Styles/assets/mobileLogo.png";
import { customTabletDimensions, formatFlightDate } from "../services/Common";
import { useMediaQuery } from "react-responsive";
// import ToggleSwitch from "./ToggleSwitch";
import ChatList from "./ChatList";
import logout from "../assests/Styles/assets/logout1.svg";
import orangeDrone from "../assests/Styles/assets/orangeDrone.svg";
import excelIcon from "../assests/Styles/assets/excelIcon.svg";
import whiteDrone from "../assests/Styles/assets/whiteDrone.svg";
import "../assests/Styles/color.css";
import whiteTrailSummary from "../assests/Styles/assets/whiteTrailSummary.svg";
import { isDesktop } from "react-device-detect";
import FeedbackForm from "./FeedbackForm";
import vectorNewIcon from "../assests/Styles/assets/vectorNewIcon.svg";
import shapeNewIcon from "../assests/Styles/assets/ShapeNewIcon.svg";
import "../assests/Styles/newviewer.css";
import { ChatLeftText, XLg, X, CaretDownFill } from "react-bootstrap-icons";
import AddRemovePeople from "./AddRemovePeople";
import { chatsGroupsList } from "../services/API";
export default function DesktopTopBar({
  protocolName,
  viewAPIData,
  cropData,
  selectedOption,
  trail,
  selectedFlightDate,
  isEditViewer = false,
  protocolTrials,
  collectionId,
  trialType,
  handleTrialSummary,
  accounts,
  instance,
  state,
  submitForm
}) {
  const name = sessionStorage.getItem("userName");
  const pro = name?.split(".");
  const [profileClick, setProfileClick] = useState(false);
  const [showTrials, setShowTrials] = useState(false);
  const dropdownRef = useRef(null);
  const profileIconRef = useRef(null);
  const protocolRef = useRef(null);
  const protocolContainerRef = useRef(null);
  const menuIconRef = useRef(null);
  const menuIconContainerRef = useRef(null);
  let navigate = useNavigate();
  const tabletMaxWidth = useMediaQuery({ maxWidth: 1024 });
  const [menuIcon, setMenuIcon] = useState(false);
  const [isShowFeedback, setIsShowFeedback] = useState(false);
  const [showNewDiv, setShowNewDiv] = useState(false);
  const [showChatDiv, setShowChatDiv] = useState(false);
  const newDivRef = useRef(null);
  const newIconRef = useRef(null);
  const chatDivRef = useRef(null);
  const addPeopleModalRef = useRef(null);
  const accessToken = sessionStorage.getItem("accessToken");
  const [groupsList, setGroupsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newChat, setNewChat] = useState(false);
  const [newGroupCreated, setNewGroupCreated] = useState(false);
  const tooltipRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [addPeopleChatList, setAddPeopleChatList] = useState(false);
  const [deleteChatList, setDeleteChatList] = useState(false);
  const [addPeople, setAddPeople] = useState(false);

  const groupLists = () => {
    const chatPayload = {
      access_token: accessToken,
      topic: trail
    };
    setLoading(true);
    chatsGroupsList(chatPayload, (res) => {
      let list = [];
      res?.value.forEach((item, index) => {
        list.push({
          id: item?.id,
          name: item?.topic,
          teamsLink: item?.webUrl,
          children: Object.entries(item?.members).map(([key, value]) => ({
            id: index,
            name:
              key === "general_user"
                ? "Others"
                : key
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),
            children: value.map((obj) => ({
              id: obj.id,
              name: obj.email,
              chatGroupId: item?.id
            }))
          }))
        });
      });

      setGroupsList(list);
      setLoading(false);
    }, (err) => {
      console.log("Error", err);
    });
  };
  useEffect(() => {
    groupLists();
    setNewGroupCreated(false);
  }, [trail, newGroupCreated]);

  useEffect(() => {
    if (deleteChatList === true) {
      groupLists();
      setDeleteChatList(false);
    }
  }, [deleteChatList]);

  useEffect(() => {
    if (addPeopleChatList === true) {
      groupLists();
      setAddPeopleChatList(false);
    }
  }, [addPeopleChatList]);

  useEffect(() => {
    if (showChatDiv === true) {
      groupLists();
    }
  }, [showChatDiv]);

  const handleOpenFeedback = () => {
    setIsShowFeedback(true);
  };

  const handleCloseFeedback = () => {
    setIsShowFeedback(false);
  };

  const truncateCombinedText = (text1, text2, maxLength) => {
    const combinedText = text1 && text2 ? `${text1} - ${text2}` : "";
    if (combinedText.length > maxLength) {
      return combinedText.substring(0, maxLength) + "...";
    }
    return combinedText;
  };
  const combinedText =
    viewAPIData?.site_name != undefined && viewAPIData?.block_name != undefined
      ? `${viewAPIData?.site_name} - ${viewAPIData?.block_name}`
      : "";
  const showTooltip = combinedText.length > 35;
  const truncatedText = truncateCombinedText(viewAPIData?.site_name, viewAPIData?.block_name, 34);

  const handleCloseTooltip = () => {
    setTooltipVisible(false);
    setShowNewDiv(true);
  };

  useEffect(() => {
    const handleClickOutside1 = (event) => {
      if (
        (chatDivRef.current && !chatDivRef.current.contains(event.target)) ||
        !chatDivRef.current
      ) {
        setShowChatDiv(false);
        setNewChat(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!tooltipVisible && addPeople) {
        if (
          newIconRef.current &&
          !newIconRef.current.contains(event.target) &&
          ((newDivRef.current && !newDivRef.current.contains(event.target)) ||
            !newDivRef.current) &&
          ((addPeopleModalRef.current && !addPeopleModalRef.current.contains(event.target)) ||
            !addPeopleModalRef.current)
        ) {
          setShowNewDiv(false);
          setShowChatDiv(true);
          setNewChat(true);
          // setAddPeople(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipVisible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (tooltipRef.current && !tooltipRef.current.contains(event.target)) ||
        !tooltipRef.current
      ) {
        setTooltipVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCreateNewChat = () => {
    setShowChatDiv(true);
    setShowNewDiv(false);
    setNewChat(true);
  };

  const toggleNewDiv = () => {
    setShowNewDiv(!showNewDiv);
    setShowChatDiv(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!tooltipVisible && !addPeople && showNewDiv) {
        if (
          newIconRef.current &&
          !newIconRef.current.contains(event.target) &&
          ((newDivRef.current && !newDivRef.current.contains(event.target)) || !newDivRef.current)
        ) {
          setShowNewDiv(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipVisible, addPeople, showNewDiv]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileIconRef?.current?.contains(event.target)
      ) {
        setProfileClick(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickProtocolOutside = (event) => {
      if (
        protocolRef.current &&
        !protocolRef.current.contains(event.target) &&
        !protocolContainerRef?.current?.contains(event.target)
      ) {
        setShowTrials(false);
      }
    };
    window.addEventListener("mousedown", handleClickProtocolOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickProtocolOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickMenuOutside = (event) => {
      if (
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target) &&
        !menuIconContainerRef?.current?.contains(event.target)
      ) {
        setMenuIcon(false);
      }
    };
    window.addEventListener("mousedown", handleClickMenuOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickMenuOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setProfileClick(!profileClick);
  };

  const routeChange = () => {
    let path = "/home/logout";
    navigate(path);
  };

  const handleTrials = (trialDetails) => {
    window.open(`/app/viewer?trial=${trialDetails?.trial_name}`, "_blank");
  };
  const handleClick = () => {
    setShowTrials(!showTrials);
  };
  const handleMenuIconClick = () => {
    setMenuIcon(!menuIcon);
  };

  let isCheckSelectedOption =
    selectedOption !== "All trials" && selectedOption !== "No trials" ? selectedOption : " ";
  let isCheckTrial = trail !== "All trials" && trail !== "No trials" ? trail : " ";

  return (
    <div
      className="row"
      style={{
        flexWrap: "unset",
        height: "60px",
        backgroundColor: "#0D1A40",
        zIndex: "999",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        display: "flex"
      }}>
      <div
        className="col-1 col-md-1 col-sm-1"
        style={{ borderRight: "1px solid white", padding: "5px 0px 0px 10px" }}>
        <div className="logo" id="viewer-link">
          <a className="logo" href="/app/" id="viewer-link">
            <div className="align-items-center" style={{ textAlign: "center" }}>
              {window.innerWidth < 600 ? (
                <img
                  src={mobileLogo}
                  alt="syngenta"
                  style={{
                    cursor: "pointer"
                  }}
                />
              ) : (
                <img
                  src={syngentaLogo}
                  alt="syngenta"
                  style={{
                    cursor: "pointer"
                  }}
                />
              )}
            </div>
          </a>
        </div>
      </div>
      <div className="col-7 col-md-7 col-sm-7" style={{ paddingRight: 0 }}>
        <div style={{ marginTop: isEditViewer && "21px" }}>
          {!isEditViewer && (
            <div
              className={
                isCheckSelectedOption === " " || isCheckTrial === "" ? "mt-4" : "mt-2 d-flex"
              }>
              <span
                style={{
                  color: "white",
                  fontWeight: "600",
                  fontSize: tabletMaxWidth ? "14px" : "18px"
                }}>
                {isCheckSelectedOption ? isCheckSelectedOption : isCheckTrial}
              </span>
              {isCheckSelectedOption === " " || isCheckTrial === " " ? null : (
                <span className="ml-3 cursor-pointer" ref={menuIconContainerRef}>
                  <CaretDownFill color="white" onClick={handleMenuIconClick} data-testid="caret-down-icon" />
                </span>
              )}
            </div>
          )}
          {trialType !== "manual" && showTooltip && !isEditViewer ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              style={{ color: "red" }}
              overlay={
                <Tooltip id="tooltip-site-name" className="topbar-tooltip">
                  {combinedText}{" "}
                </Tooltip>
              }
              hide={showTooltip}>
              <div
                data-testid="site-block-name"
                className="d-inline-block"
                style={{
                  marginTop:
                    isCheckSelectedOption === " " || isCheckTrial === " "
                      ? "12px"
                      : isEditViewer && "20px"
                }}>
                <span
                  style={{
                    fontSize: tabletMaxWidth ? "10px" : "14px",
                    color: "white",
                    fontWeight: "500",
                    cursor: viewAPIData?.site_name.length > 10 ? "pointer" : "default"
                  }}>
                  {isEditViewer ? combinedText : truncatedText}
                </span>
                {viewAPIData?.block_name && (
                  <span
                    style={{
                      fontSize: tabletMaxWidth ? "7px" : "10px",
                      color: "white",
                      fontWeight: "500"
                    }}></span>
                )}
                {showTooltip && isEditViewer && (
                  <span
                    style={{
                      fontSize: tabletMaxWidth ? "10px" : "14px"
                    }}>
                    ...
                  </span>
                )}
              </div>
            </OverlayTrigger>
          ) : (
            trialType !== "manual" && (
              <span
                style={{
                  fontSize: tabletMaxWidth ? "10px" : "14px",
                  color: "white",
                  fontWeight: "500"
                }}>
                {combinedText}
              </span>
            )
          )}
        </div>
        <div style={{ position: "absolute", top: "0px", left: "282px" }}>
          {selectedOption !== "All trials" && selectedOption !== "No trials" && protocolName && (
            <>
              <div
                className={location.pathname.indexOf("ploteditor") === -1 ? "mt-2" : "mt-4"}
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: tabletMaxWidth ? "10px" : "14px",
                  paddingBottom: "7px",
                  marginLeft: isEditViewer && "20px"
                }}>
                {protocolName}
              </div>

              {location.pathname.indexOf("ploteditor") === -1 && (
                <div
                  onClick={handleClick}
                  style={{
                    fontFamily: "Helvetica",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#ffffff",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  ref={protocolContainerRef}>
                  Protocol Trials
                  <span style={{ marginLeft: "8px" }}>
                    <CaretDownFill color="white" data-testid="caret-down-icon" />
                  </span>
                </div>
              )}
              {showTrials ? (
                <div
                  className="desktop-protocol-trials"
                  ref={protocolRef}
                  style={{
                    backgroundColor: "#0D1A40",
                    maxHeight: "300px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    textAlign: "center",
                    width: "180px",
                    marginLeft: "-24px",
                    top: "60px"
                  }}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontFamily: "Helvetica",
                      fontWeight: 400,
                      marginTop: "6%",
                      color: "white"
                    }}>
                    Select a Trial
                  </div>
                  {protocolTrials.length > 0 &&
                    protocolTrials?.map((item) => (
                      <Button
                        key={item?.id}
                        onClick={() => handleTrials(item)}
                        disabled={item?.trial_name === trail}
                        style={{
                          width: "150px",
                          overflowWrap: "break-word",
                          borderRadius: "20px",
                          fontSize: "12px",
                          backgroundColor: item?.trial_name === trail ? "#E08330" : "#3d4359",
                          color: "#FFFFFF",
                          margin: "3%",
                          border: "none",
                          opacity: item?.trial_name === trail && 1,
                          cursor: item?.trial_name === trail && "context-menu"
                        }}>
                        {item?.trial_name}
                        {item?.has_drone_assessments ? (
                          <img
                            src={item?.trial_name === trail ? whiteDrone : orangeDrone}
                            alt=""
                            style={{ marginLeft: "10px" }}
                          />
                        ) : null}
                      </Button>
                    ))}
                </div>
              ) : null}
            </>
          )}
        </div>
        <div>
          {selectedOption !== "All trials" &&
            selectedOption !== "No trials" &&
            cropData.length > 0 ? (
            <div
              className="d-flex align-items-center"
              style={{
                fontSize: tabletMaxWidth ? "8px" : "12px",
                color: "white",
                position: "absolute",
                top: "35px",
                left: "440px"
                // marginTop: "38px",
                // marginLeft: "-35%"
              }}>
              <div>
                <span style={{ fontWeight: 400 }}>Crops: </span>
                <span style={{ fontWeight: 700 }}>{cropData.join(", ")}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div
        className="col-2 col-md-2 flight-date-section"
        style={{ paddingLeft: 0, paddingRight: 0 }}>
        {selectedFlightDate && (
          <>
            <div style={{ marginTop: "20px" }}>
              <span
                style={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: tabletMaxWidth ? "10px" : "14px"
                }}>
                Flight Date:
              </span>
              <span
                style={{
                  color: "white",
                  fontWeight: 700,
                  fontSize: tabletMaxWidth ? "10px" : "14px"
                }}>
                {` ${formatFlightDate(selectedFlightDate)}`}
              </span>
            </div>
          </>
        )}
      </div>
      <div className="col-2 col-md-2 col-sm-2"></div>
      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: "flex-end",
          paddingRight: "40px"
        }}
        className="col-2 col-md-2">
        {/* <span className="d-flex align-items-center" style={{ marginRight: "25px" }}>
          {selectedOption !== "All trials" &&
            selectedOption !== "No trials" &&
            location.pathname.indexOf("ploteditor") === -1 && (
              <button
                data-testid="chat-button"
                onClick={toggleNewDiv}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  height: "24px",
                  width: "24px",
                  borderRadius: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: "12px"
                }}>
                <ChatLeftText ref={newIconRef} color="#0D1A40" width={16} height={16} />
              </button>
            )}
          {groupsList?.length > 0 && showNewDiv && (
            <div
              ref={newDivRef}
              style={{
                position: "absolute",
                right: "0px",
                top: "60px",
                minWidth: "417px",
                width: "max-content",
                height: "584px",
                backgroundColor: "white",
                zIndex: 9999,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
              }}>
              <div className="d-flex justify-content-between pt-3">
                <span className="com-heading">Chat (s)</span>
                <span style={{ paddingRight: "25px" }}>
                  <X
                    className="cursor-pointer"
                    height={22}
                    width={22}
                    onClick={() => setShowNewDiv(false)}
                  />
                </span>
              </div>
              <div className="chat-text" onClick={handleCreateNewChat}>
                <div className="new-chat-text">NEW CHAT</div>
              </div>
              <div
                style={{
                  marginTop: "8px",
                  maxHeight: "460px",
                  overflowY: "scroll",
                  marginRight: "8px"
                }}>
                <AddRemovePeople
                  addPeopleModalRef={addPeopleModalRef}
                  trail={trail}
                  groupsList={groupsList}
                  tooltipVisible={tooltipVisible}
                  setTooltipVisible={setTooltipVisible}
                  onCloseTooltip={handleCloseTooltip}
                  setDeleteUser={setDeleteUser}
                  deleteUser={deleteUser}
                  setAddPeopleChatList={setAddPeopleChatList}
                  addPeopleChatList={addPeopleChatList}
                  loading={loading}
                  setDeleteChatList={setDeleteChatList}
                  setShowChatDiv={setShowChatDiv}
                  setShowNewDiv={setShowNewDiv}
                  addPeople={addPeople}
                  setAddPeople={setAddPeople}
                />
              </div>
            </div>
          )}
          {((groupsList?.length == 0 && showChatDiv) || newChat) && (
            <div
              className="accordion-content"
              ref={chatDivRef}
              style={{
                position: "absolute",
                right: "16px",
                top: "60px",
                minWidth: "417px",
                width: "max-content",
                height: "584px",
                backgroundColor: "white",
                zIndex: 9999,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)"
              }}>
              <div
                className="chat-heading-text"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "15px"
                }}>
                New Chat
                <XLg
                  role="button"
                  width={18}
                  height={18}
                  color="#0D1A40"
                  onClick={() => {
                    setShowChatDiv(false);
                    setNewChat(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="nested-dropdown-design">
                <ChatList
                  addPeopleModalRef={addPeopleModalRef}
                  trail={trail}
                  setNewGroupCreated={setNewGroupCreated}
                  groupsList={groupsList}
                  groupLists={groupLists}
                  selectedFlightDate={selectedFlightDate}
                  combinedText={combinedText}
                  addPeople={addPeople}
                  setAddPeople={setAddPeople}
                  setShowChatDiv={setShowChatDiv}
                  setShowNewDiv={setShowNewDiv}
                  setNewChat={setNewChat}
                />
              </div>
            </div>
          )}
        </span> */}
        {/* <span style={{ marginTop: "8px" }}>
          <ToggleSwitch trial={selectedOption} collectionId={collectionId} trialType={trialType} />
        </span> */}
        <span className="d-flex align-items-center">
          <button
            data-testid="profile-button"
            style={{
              marginLeft: "30px",
              border: "none",
              backgroundColor: "#006D82",
              height: "24px",
              width: "24px",
              borderRadius: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "12px"
            }}
            ref={profileIconRef}
            onClick={handleButtonClick}>
            {pro && pro.length > 1
              ? pro[0].charAt(0).toUpperCase() + pro[1].charAt(0).toUpperCase()
              : pro[0].charAt(0).toUpperCase()}
          </button>
          {profileClick && isDesktop && !customTabletDimensions() && (
            <div
              ref={dropdownRef}
              style={{
                position: "absolute",
                right: "15px",
                zIndex: "9999",
                minWidth: "300px",
                top: "60px"
              }}>
              <ul className="list-group">
                <li className="list-group-item" style={{ fontSize: ".875rem" }}>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <span>
                      <img
                        src={shapeNewIcon}
                        alt="shapeNewIcon"
                        height={24}
                        width={24}
                      //  style={{ width: "24px", height:"24px", margin:"2px"}}
                      />
                    </span>
                    <span> {name}</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <button className="desktop-top-feedback" onClick={handleOpenFeedback}>
                    <div className="desktopPro-vector">
                      <span>
                        <img
                          src={vectorNewIcon}
                          alt="vectorNewIcon"
                          style={{ width: "20px", height: "20px", margin: "2px" }}
                        />
                      </span>
                      <span style={{ fontWeight: 400 }}> Feedback</span>
                    </div>
                  </button>
                </li>
                <li
                  className="list-group-item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgrondColor: "#0D1A40"
                  }}>
                  <img
                    src={logout}
                    alt="logout"
                    style={{ width: "18px", height: "18px", margin: "3px", fontWeight: "700" }}
                  />
                  <button className="logout" onClick={routeChange}>
                    <span style={{ fontWeight: "700" }}>&nbsp;LOGOUT</span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </span>
      </div>

      {menuIcon ? (
        <div
          ref={menuIconRef}
          className="cursor-pointer"
          style={{
            position: " absolute",
            top: "32px",
            left: "10%",
            width: "166px",
            height: trialType !== "manual" ? "81px" : "47px",
            backgroundColor: "#0D1A40",
            zIndex: 999,
            padding: "10px 10px 0px 10px"
          }}>
          {trialType !== "manual" ? (
            <div>
              <a
                href={`${process.env.REACT_APP_BASE_URL}/api/trail_detail_export_excel?trial=${
                  isCheckSelectedOption || isCheckTrial
                  }`}>
                <span style={{ display: "flex", justifyContent: "start" }}>
                  <span style={{ marginRight: "12px" }}>
                    <img src={excelIcon} alt="excelIcon" className="cursor-pointer" />
                  </span>
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Helvetica",
                      fontSize: "14px",
                      fontWeight: 400
                    }}>
                    Download
                  </span>
                </span>
              </a>
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
              marginTop: trialType !== "manual" ? "8px" : ""
            }}
            onClick={handleTrialSummary}>
            <span style={{ marginRight: "11px" }}>
              <img src={whiteTrailSummary} alt="" />
            </span>
            <span
              style={{
                color: "white",
                fontFamily: "Helvetica",
                fontSize: "14px",
                fontWeight: 400
              }}>
              Trial Summary
            </span>
          </div>
        </div>
      ) : null}
      <div>
        <FeedbackForm
          data-testid="feedback-form"
          handleFeedbackClose={handleCloseFeedback}
          isShowFeedback={isShowFeedback}
          handleCloseFeedback={handleCloseFeedback}
          isDesktop={true}
          accounts={accounts}
          instance={instance}
          state={state}
          submitForm={submitForm}
          collectionId={collectionId}
          selectedTrail={selectedOption}
          selectedFlightDate={selectedFlightDate}
        />
      </div>
    </div>
  );
}

DesktopTopBar.propTypes = {
  protocolName: propTypes.any,
  viewAPIData: propTypes.any,
  cropData: propTypes.any,
  selectedOption: propTypes.any,
  trail: propTypes.any,
  selectedFlightDate: propTypes.any,
  isEditViewer: propTypes.any,
  protocolTrials: propTypes.any,
  collectionId: propTypes.any,
  trialType: propTypes.any,
  handleTrialSummary: propTypes.any,
  accounts: propTypes.any,
  instance: propTypes.any,
  state: propTypes.any,
  submitForm: propTypes.any
};
