import { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { ChevronRight, InfoCircleFill, EyeSlash, Eye, ChevronLeft, X } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import "../assests/Styles/newviewer.css";
import "../assests/Styles/baseoverlay.css";
import { formatFlightDate } from "../services/Common";
import mdiCalendarIcon from "../assests/Styles/assets/mdiCalendarIcon.svg";
import { propTypes } from "react-bootstrap/esm/Image";
import { useMobileOrientation, isMobile } from "react-device-detect";
import { customTabletDimensions } from "../services/Common";

const MobileBaseOverlayFlight = ({
  baseOverlayFlightOpen,
  toggleBaseOverlayFlight,
  flightDropdown,
  flightDatesContainerRef,
  selectedFlightDate,
  flightDates,
  handleFlightOption,
  flightDateRef,
  isDisabled = false,
  trialType,
  rasterLayersList,
  overlayLayersList,
  overlayVectors,
  overlayChecklist,
  currentRaster,
  activeOverlays,
  boundingBoxes,
  selectedBoundingBoxes,
  handleRaster,
  activerVectorLayers,
  plotVectorChanged,
  selectedVectors,
  selectedTrialInfoForEyeOpen,
  setSelectedTrialInfoForEyeOpen,
  selectedCombinationForEyeOpen,
  setSelectedCombinationForEyeOpen,
  baseOverlayRef
}) => {
  const { isPortrait, isLandscape } = useMobileOrientation();
  const [showFlightOptions, setShowFlightOptions] = useState(false);
  const [isFlightOpen, setIsFlightOpen] = useState(false);

  useEffect(() => {
    if(!baseOverlayFlightOpen){
      setShowFlightOptions(false);
    }
  },[baseOverlayFlightOpen]);

  const handleCloseClick = () => {
    toggleBaseOverlayFlight(false);
  };

  const handleBackClick = () => {
    setShowFlightOptions(!showFlightOptions);
    // setBaseOverlayFlightOpen(true);
  };

  const handleFlightDropdown = () => {
    setShowFlightOptions(true);
    flightDropdown();
    handleFlightOpen();
  };
  const handleFlightOpen = () => {
    setIsFlightOpen(!isFlightOpen);
  };

  return (
    <div ref={baseOverlayRef}>
      <Dropdown
        show={baseOverlayFlightOpen}
        onToggle={toggleBaseOverlayFlight}
        className="base-flight-dropdown-show">
        <Dropdown.Menu
          className={
            isMobile && isPortrait && !customTabletDimensions()
              ? "mobile-overlay-toggle"
              : isMobile && isLandscape && !customTabletDimensions()
              ? " mobile-overlay-toggle mL-overlayF-width"
              : "flight-overlay-toggle"
          }
          style={{
            width:
              trialType !== "manual"
                ? isMobile && isPortrait && !customTabletDimensions()
                  ? "100vw"
                  : isMobile && isLandscape && !customTabletDimensions()
                  ? "45vw"
                  : "319px"
                : isLandscape
                ? "45vw"
                : "100vw",
            height:
              overlayVectors !== null
                ? "310px !important"
                : trialType !== "manual"
                ? isMobile && isPortrait && !customTabletDimensions()
                  ? "352px"
                  : isMobile && isLandscape && !customTabletDimensions()
                  ? "360px"
                  : "288px"
                : "auto"
          }}>
          {isMobile && (isPortrait || isLandscape) && !customTabletDimensions() && (
            <div
              className="option-cross bg-white"
              style={{
                borderBottom: "3px solid #EDEDED",
                width: isLandscape ? (showFlightOptions ? "90vw" : "45vw") : "100vw"
              }}>
              <span style={{ color: "#0D1A40", fontSize: "16px", fontWeight: 700 }}> Options</span>
              {isMobile && (isPortrait || isLandscape) && !customTabletDimensions() ? (
                <span style={{ zIndex: 100, marginRight: "20px" }}>
                  <X
                    onClick={handleCloseClick}
                    alt=""
                    height={22}
                    style={{
                      color: "#0D1A40",
                      width: "30px"
                    }}
                  />
                </span>
              ) : null}
            </div>
          )}
          <div style={{ border: "0.5px solid #E8E8E8" }}>
            {trialType !== "manual" && (
              <DropdownButton
                drop="end"
                variant="Light"
                className={
                  isMobile && isPortrait && !customTabletDimensions() && showFlightOptions
                    ? "flight-dropdown baseOF-mobiledropdown"
                    : isMobile && isLandscape && !customTabletDimensions() && showFlightOptions
                    ? "flight-dropdown baseOF-landscapedropdown"
                    : isMobile &&
                      (isPortrait || isLandscape) &&
                      !customTabletDimensions() &&
                      !showFlightOptions
                    ? "flight-dropdown hide-flight"
                    : "flight-dropdown baseOF-dropdown"
                }
                style={{ borderBottom: "3px solid #EDEDED" }}
                onClick={() => {
                  if (isLandscape) {
                    showFlightOptions ? handleBackClick() : handleFlightDropdown();
                  } else {
                    if (!showFlightOptions) {
                      handleFlightDropdown();
                    }
                  }
                }}
                ref={flightDateRef}
                disabled={isDisabled}
                show={showFlightOptions}
                title={
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="flight-menu-button d-flex justify-content-between align-items-center"
                      style={{ width: "100%" }}>
                      <div>
                        <span>
                          <img
                            src={mdiCalendarIcon}
                            alt=""
                            height={22}
                            style={{ paddingLeft: "7px", color: "#0D1A40", width: "30px" }}
                          />
                        </span>
                        <span
                          style={{
                            marginLeft: "20px",
                            color: "#0D1A40",
                            fontSize: "12px",
                            fontWeight: 700
                          }}>
                          Flight Date &nbsp;
                          {selectedFlightDate ? formatFlightDate(selectedFlightDate) : " "}
                        </span>
                      </div>
                      <div style={{ paddingTop: "4px", marginRight: "12px" }}>
                        <span
                          style={{
                            height: "16px",
                            width: "16px"
                          }}>
                          <ChevronRight
                            color="#0D1A40"
                            stroke={2}
                            height={16}
                            width={16}
                            style={{ marginTop: "-10px" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                }>
                {showFlightOptions && (
                  <div
                    className={
                      isMobile && isPortrait && !customTabletDimensions()
                        ? "baseOF-mobiledropdown d-flex flex-column justify-content-between"
                        : isMobile && isLandscape && !customTabletDimensions()
                        ? "baseOF-landscapedropdown d-flex flex-column justify-content-between"
                        : "baseOF-dropdown d-flex flex-column justify-content-between"
                    }
                    style={{ background: "white" }}>
                    <div ref={flightDatesContainerRef}>
                      <div
                        style={{
                          borderBottom:
                            isMobile && isPortrait && !customTabletDimensions()
                              ? "0.5px solid #E8E8E8"
                              : ""
                        }}>
                        {isMobile && isPortrait && !customTabletDimensions() && (
                          <span>
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "white",
                                height: "60px",
                                width: "40px"
                              }}
                              onClick={handleBackClick}>
                              <ChevronLeft
                                color="#0D1A40"
                                style={{ width: "100%", height: "16px" }}
                              />
                            </button>
                          </span>
                        )}
                      </div>
                      <div>
                        <div
                          className={
                            isMobile && isPortrait && !customTabletDimensions()
                              ? "dd-mobile-flight-title"
                              : isMobile && isLandscape && !customTabletDimensions()
                              ? "dd-mobilelandscape-flight-title"
                              : "dd-flight-title"
                          }>
                          <span>
                            {" "}
                            {isLandscape ? (
                              <>
                                <InfoCircleFill fill="#FFB71E" height={20} width={20} />{" "}
                                &nbsp;&nbsp;
                                <span style={{ fontSize: "12px", fontWeight: 400 }}>
                                  Select a flight date to show image
                                </span>
                              </>
                            ) : (
                              <div className="d-flex  align-items-center">
                                <span>
                                  <img
                                    src={mdiCalendarIcon}
                                    alt=""
                                    height={22}
                                    style={{
                                      paddingLeft: "7px",
                                      color: "#0D1A40",
                                      width: "30px"
                                    }}
                                  />
                                </span>
                                <span
                                  style={{
                                    marginLeft: "12px",
                                    color: "#0D1A40",
                                    fontSize: "12px",
                                    fontWeight: 700
                                  }}>
                                  Flight Date &nbsp;
                                  {selectedFlightDate ? formatFlightDate(selectedFlightDate) : " "}
                                </span>
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingLeft:
                            isMobile && isPortrait && !customTabletDimensions() ? "40px" : "20px"
                        }}>
                        {isPortrait && (
                          <div className="p-1 pb-3">
                            <InfoCircleFill fill="#FFB71E" height={20} width={20} /> &nbsp;&nbsp;
                            <span style={{ fontSize: "12px", fontWeight: 400 }}>
                              Select a flight date to show image
                            </span>
                          </div>
                        )}
                        {flightDates &&
                          Object.keys(flightDates)?.map((option) => (
                            <div className="p-1" key={option}>
                              <div className="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="options"
                                  checked={selectedFlightDate === option}
                                  onChange={() => handleFlightOption(option)}
                                  id={`radio-${option}`}
                                  value={option}
                                />
                                <label
                                  htmlFor={`radio-${option}`}
                                  style={{
                                    fontSize: "12px",
                                    color: "#0D1A40",
                                    cursor: "pointer"
                                  }}>
                                  {formatFlightDate(option)}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </DropdownButton>
            )}
            <div>
              {trialType !== "manual" && Object.values(rasterLayersList) !== null && (
                <div className="overlay-menu">
                  <p className="my-0 overlay-label">BASE</p>
                  <div className="d-flex align-items-center overlay-item ">
                    {Object.values(rasterLayersList).map((text) => (
                      <div className="d-flex pr-2 " key={text}>
                        <input
                          type="radio"
                          className="assessment-input align-items-center"
                          name="visible"
                          onChange={(e) => handleRaster(e.target.value)}
                          checked={currentRaster === text}
                          value={text}
                          id={`base-radio-${text}`}
                        />

                        <label
                          className="ml-1 base-overlay-input-label"
                          htmlFor={`base-radio-${text}`}
                          style={{
                            cursor: "pointer"
                          }}>
                          {text}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div>
              {trialType !== "manual" && overlayLayersList.length > 0 && (
                <div
                  className={overlayVectors !== null ? "overlay-menu-boundingbox" : "overlay-menu"}
                  style={{ height: overlayVectors !== null ? "94px !important" : "" }}>
                  <p className="my-0 overlay-label">OVERLAY</p>
                  <div
                    className={
                      overlayVectors !== null
                        ? "d-flex align-items-center overlay-item-withboundingbox"
                        : activerVectorLayers != null
                        ? "d-flex align-items-center overlay-item"
                        : "d-flex align-items-center overlay-item"
                    }>
                    {Object.values(overlayLayersList)?.map((text) => (
                      <div className="d-flex pr-2" key={text}>
                        <input
                          type="checkbox"
                          className="assessment-input"
                          name="overlay"
                          value={text}
                          onChange={overlayChecklist}
                          checked={activeOverlays.includes(text)}
                          id={`overlay-checkbox-${text}`}
                        />
                        <label
                          className="ml-1 input-label base-overlay-input-label"
                          htmlFor={`overlay-checkbox-${text}`}
                          style={{
                            cursor: "pointer"
                          }}>
                          {text}
                        </label>
                      </div>
                    ))}
                    {overlayVectors !== null && (
                      <div className="d-flex pr-2" key="chkoverlayVectors">
                        <input
                          type="checkbox"
                          className="assessment-input"
                          value="Bounding Box"
                          onChange={boundingBoxes}
                          checked={selectedBoundingBoxes}
                          id="bounding-checkbox"
                        />
                        <label
                          className="ml-1 input-label input-label-checkbox"
                          htmlFor="bounding-checkbox"
                          style={{
                            cursor: "pointer"
                          }}>
                          Bounding Box
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div>
              {activerVectorLayers.length > 0 && (
                <div
                  className={
                    trialType !== "manual" ? "mr-3 overlay-menu" : "mr-2 overlay-manual-menu"
                  }>
                  <p className="my-0 overlay-label">TRIAL INFO</p>
                  <div className="d-flex align-items-center overlay-item">
                    {Object.values(activerVectorLayers)?.map((text) => (
                      <div className="d-flex pr-2" key={text}>
                        <input
                          type="checkbox"
                          className="assessment-input"
                          name="overlay"
                          checked={selectedVectors?.includes(text)}
                          value={text}
                          onChange={(e) => {
                            plotVectorChanged(e)
                            if(!e.target.checked){
                              if(selectedTrialInfoForEyeOpen === text) {
                                setSelectedTrialInfoForEyeOpen(undefined);
                                setSelectedCombinationForEyeOpen(undefined);
                              }
                            }
                          }}
                          id={`trialInfo-checkbox-${text}`}
                        />
                        <label
                          className="ml-1 base-overlay-input-label"
                          htmlFor={`trialInfo-checkbox-${text}`}
                          style={{
                            cursor: "pointer"
                          }}>
                          {text}
                        </label>
                        {selectedVectors?.includes(text) && (
                          <span className="ml-2">
                            {selectedTrialInfoForEyeOpen === text ||
                            selectedCombinationForEyeOpen === text ? (
                              <EyeSlash
                                fill="#0D1A40"
                                role="button"
                                onClick={() => {
                                  setSelectedTrialInfoForEyeOpen(undefined);
                                  setSelectedCombinationForEyeOpen(undefined);
                                }}
                              />
                            ) : (
                              <Eye
                                fill="#0D1A40"
                                role="button"
                                onClick={() => {
                                  setSelectedTrialInfoForEyeOpen(text);
                                  setSelectedCombinationForEyeOpen(text);
                                }}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
MobileBaseOverlayFlight.propTypes = {
  toggleBaseOverlayFlight: PropTypes.any,
  baseOverlayFlightOpen: PropTypes.any,
  flightDropdown: PropTypes.any,
  flightDatesContainerRef: PropTypes.any,
  selectedFlightDate: PropTypes.any,
  flightDates: PropTypes.any,
  handleFlightOption: PropTypes.any,
  flightDateRef: PropTypes.any,
  isDisabled: PropTypes.any,
  trialType: PropTypes.any,
  rasterLayersList: PropTypes.any,
  overlayLayersList: PropTypes.any,
  overlayVectors: PropTypes.any,
  overlayChecklist: PropTypes.any,
  currentRaster: PropTypes.any,
  activeOverlays: PropTypes.any,
  boundingBoxes: PropTypes.any,
  selectedBoundingBoxes: PropTypes.any,
  handleRaster: PropTypes.any,
  activerVectorLayers: PropTypes.any,
  plotVectorChanged: PropTypes.any,
  selectedVectors: PropTypes.any,
  selectedTrialInfoForEyeOpen: PropTypes.any,
  setSelectedTrialInfoForEyeOpen: PropTypes.any,
  selectedCombinationForEyeOpen: propTypes.any,
  setSelectedCombinationForEyeOpen: propTypes.any,
  baseOverlayRef: propTypes.any
};
export default MobileBaseOverlayFlight;
