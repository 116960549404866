import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
} from "chart.js";
import propTypes from "prop-types";
// import { useMediaQuery } from "react-responsive";
import "../assests/Styles/charts.css";
import { colors } from "../services/Common";
import Plot from "react-plotly.js";
import { useEffect } from "react";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);

export default function NewViewerCorrelationChart({
  barData,
  barData2,
  isMobile,
  isMobileLandscape,
  isTablet,
  isModel,
  navbarButtons,
  barExpand
}) {
  // const isLandScape = useMediaQuery({ maxHeight: 440 });

  // const iphoneSE = useMediaQuery({ maxWidth: 375 });
  // const iphoneSELandScape = useMediaQuery({ maxHeight: 375 });
  let pointValue = false;
  const formedData = [];
  const xGroupedByNum = barData?.treatmentSummary?.reduce((acc, obj) => {
    if (!acc[obj?.num]) {
      acc[obj?.num] = { x: [], plots: [] };
    }
    if (obj?.value?.length > 8) {
      pointValue = true;
    }
    acc[obj?.num].x.push(obj?.value);
    acc[obj?.num].plots.push(obj?.plot_id);
    return acc;
  }, {});

  const yDataGroupedByPlot = barData2?.treatmentSummary?.reduce((acc, obj) => {
    if (!acc[obj?.num]) {
      acc[obj?.num] = {};
    }

    if (!acc[obj?.num][obj?.plot_id]) {
      acc[obj?.num][obj?.plot_id] = obj?.value;
    }
    return acc;
  }, {});

  if (xGroupedByNum) {
    for (const num of Object.keys(xGroupedByNum)) {
      const xValues = xGroupedByNum[num]?.x;
      const plots = xGroupedByNum[num]?.plots;
      const yValues = plots.map((plot) => yDataGroupedByPlot?.[num]?.[plot]);
      formedData.push({ num: num, x: xValues, y: yValues, plots });
    }
  }

  let correlationChart = [];
  const firstLegend =
    barData?.name && barData?.name?.indexOf("v:") > -1
      ? barData?.name?.split("v:")[0]
      : barData?.name?.replace(/([\w\s,]{25,}?)\s?\b/g, "$1<br>");

  const secondLegend =
    barData2?.name && barData2?.name?.indexOf("v:") > -1
      ? barData2?.name?.split("v:")[0]
      : barData2?.name?.replace(/([\w\s,]{18,}?)\s?\b/g, "$1<br>");
  formedData &&
    formedData.forEach((obj) => {
      correlationChart.push({
        type: "scatter",
        x: obj?.x,
        y: obj?.y,
        customdata: obj?.plots,
        mode: "markers",
        text: obj?.num,
        name: obj?.num,
        marker: {
          size: 8,
          color: obj?.num <= 30 ? colors[obj?.num] : colors[obj?.num % 30]
        },
        hovertemplate: `Plot # : %{customdata}<br>Treatment # : ${obj?.num}<br>${firstLegend} : %{x}<br>${secondLegend} : %{y}<extra></extra>`
      });
    });

  const plotData = correlationChart;

  useEffect(() => {
    const legendContainer = document.querySelector(".legend");
    if (legendContainer && isMobileLandscape) {
      legendContainer.style.overflowX = "auto";
      legendContainer.style.whiteSpace = "nowrap";
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const temp = document.querySelector(".modal-dialog .scrollbox");
      if (temp) {
        temp.addEventListener("wheel", (e) => {
          e.preventDefault();
        });
      }
    }, 500);
  });

  useEffect(() => {
    setTimeout(() => {
      const temp = document.querySelector(".scrollbox");
      if (temp) {
        temp.addEventListener("wheel", (e) => {
          e.preventDefault();
        });
      }
    }, 500);
  });

  // const mobileClass = isMobile
  // const mobileLandScapeClass = isMobileLandscape && !iphoneSE && !iphoneSELandScape;
  // const iphoneSELandScapeClass = iphoneSELandScape && "iphoneSELandScapeCorrelation";
  // const iphoneSEClass = iphoneSE ? "iphoneSE" : iphoneSELandScapeClass;
  // const mobileLandScapeAndIphoneClass = mobileLandScapeClass
  //   ? "landscapeCorrelationeChart"
  //   : iphoneSEClass;

  const correlationChartClass = isMobile
    ? "newBarChart"
    : isMobileLandscape
    ? "landscapeCorrelationeChart"
    : "";

  const isMobileLandscapeAndDesktopHeight = isMobileLandscape ? "360px" : "320px";
  // const isLandScapeHeightAndOtherHeights = iphoneSELandScape
  //   ? "400px"
  //   : isMobileLandscapeAndDesktopHeight;
  const correlationChartHeight = isModel ? "700px" : isMobileLandscapeAndDesktopHeight;
  const mobileDevice = isMobile || isMobileLandscape;
  const mobileTabWidth = (mobileDevice || isTablet) && !isModel;
  const desktopWidth = !isModel && !(mobileDevice || isTablet);
  const deskTop = desktopWidth ? "47vw" : "57vw";
  const otherDevices = mobileTabWidth ? "100vw" : deskTop;
  const correlationChartWidth = isModel
    ? "100%"
    : navbarButtons?.length === 1 || barExpand
    ? "100vw"
    : otherDevices;
  const isTabletLayoutHeight = isTablet && 430;
  const mobileTabLayoutHeight = isMobile ? 480 : isTabletLayoutHeight;
  // const iphoneSEAndOtherLayoutHeights = iphoneSE ? 510 : mobileTabLayoutHeight;
  // const iphoneSELandscapeAndOtherLayoutHeights = iphoneSELandScape
  //   ? 370
  //   : iphoneSEAndOtherLayoutHeights;
  const layoutHeight = isMobileLandscape ? 470 : mobileTabLayoutHeight;
  const mobileTab = isModel || isTablet || !mobileDevice;
  const autosizeAndResponsive = isMobileLandscape ? true : isMobile ? false : mobileTab;
  const tabletDesktopMB = isTablet ? 230 : 200;
  const isLandScapeAndTBMarginBottom = isMobileLandscape ? 350 : tabletDesktopMB;
  const isModelAndTBMarginBottom = isModel ? 80 : isLandScapeAndTBMarginBottom;
  const isMobileAndTBMarginBottom = isMobile || isMobileLandscape ? 230 : isModelAndTBMarginBottom;
  // const iphoneSELandscapeAndOtherDevicesMB = iphoneSELandScape ? 170 : isMobileAndTBMarginBottom;
  const correlationMarginBottom = isMobileAndTBMarginBottom;
  const isMobileLandscapeML = isMobileLandscape ? 50 : 80;
  // const isMobileAndOtherDevicesLandscapeML = iphoneSELandScape ? 80 : isMobileLandscapeML;
  const correlationMarginLeft = isMobile ? 50 : isMobileLandscapeML;
  const showAll = isModel || isTablet || isMobileLandscape || !isMobile;
  const correlationShowLegend = isMobile ? false : showAll;
  const landscapeCondition = isMobileLandscape;

  return (
    <div
      className={correlationChartClass}
      style={{
        height: correlationChartHeight,
        width: correlationChartWidth,
        overflowY: "hidden",
        overflowX: landscapeCondition ? "auto" : "hidden"
      }}>
      <div>
        <Plot
          style={{ cursor: "pointer" }}
          data={plotData}
          layout={{
            scattermode: "group",
            scattergap: 0.8,
            height: layoutHeight,
            autosize: autosizeAndResponsive,
            margin: {
              t: 40,
              b: correlationMarginBottom,
              l: pointValue === true ? 90 : correlationMarginLeft,
              r: 10
            },
            showlegend: correlationShowLegend,

            legend: landscapeCondition
              ? {
                  orientation: "h",
                  x: -0.1,
                  y: 1.5,
                  usePointStyle: true
                }
              : {
                  usePointStyle: true
                },

            xaxis: isMobile
              ? {
                  showexponent: "all",
                  exponentformat: "e",
                  minexponent: 3,
                  categoryorder: "category ascending",
                  linecolor: "#E8E8E8",
                  showgrid: false
                }
              : {
                  showexponent: "all",
                  exponentformat: "e",
                  minexponent: 3,
                  categoryorder: "category ascending",
                  linecolor: "#E8E8E8",
                  tickfont: {
                    size: 12
                  },
                  title: {
                    text: firstLegend
                  },
                  titlefont: {
                    family: "Helvetica",
                    size: 12,
                    color: "#AAA9A9"
                  }
                },
            yaxis: {
              linecolor: "#E8E8E8",
              showexponent: "all",
              exponentformat: "e",
              minexponent: 3,
              title: {
                text: secondLegend
              },
              titlefont: {
                family: "Helvetica",
                size: 12,
                color: "#AAA9A9"
              }
            },

            annotations: isMobile && [
              {
                x: 0.2,
                y: -0.23,
                text: firstLegend,
                showarrow: false,
                xref: "paper",
                yref: "paper",
                xanchor: "left",
                yanchor: "bottom",
                font: { size: 12, family: "Helvetica", color: "#AAA9A9" }
              }
            ],
            responsive: autosizeAndResponsive
          }}
          config={{
            staticPlot: false,
            displaylogo: false,
            responsive: autosizeAndResponsive,
            modeBarButtonsToRemove: [
              "pan2d",
              "select2d",
              "autoScale2d",
              "hoverClosestCartesian",
              "zoom2d",
              "lasso2d"
            ]
          }}
        />
      </div>
    </div>
  );
}
NewViewerCorrelationChart.propTypes = {
  barData: propTypes.any,
  barData2: propTypes.any,
  isMobileLandscape: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any,
  isModel: propTypes.any,
  navbarButtons: propTypes.any,
  barExpand: propTypes.any
};
