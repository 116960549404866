import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
} from "chart.js";
import propTypes from "prop-types";
// import { useMediaQuery } from "react-responsive";
import Plot from "react-plotly.js";
import "../assests/Styles/charts.css";
import NewViewerCorrelationChart from "./NewViewerCorrelationChart";
import { formatFlightDate, formatFlightDateWithoutTimeStamp, Spinner } from "../services/Common";
import CustomLegend from "./CustomLegend";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);

function NewViewerCompareChart({
  isModel,
  chartsValue,
  barData1,
  barData2,
  noAssesment1,
  noAssesment2,
  noMean1,
  noMean2,
  error,
  isMobile,
  isMobileLandscape,
  isTablet,
  compareData1Loaded,
  compareData2Loaded,
  collectionDate1,
  collectionDate2,
  navbarButtons,
  barExpand,
  zeroSubPlots
}) {
  // const isLandScape = useMediaQuery({ maxHeight: 440 });
  // const iphoneSE = useMediaQuery({ maxWidth: 375 });
  // const extraSmallDevices = useMediaQuery({ maxWidth: 365 });
  // const ipadPro = useMediaQuery({ minWidth: 870, maxWidth: 1024 });
  // const iphoneSELandScape = useMediaQuery({ maxHeight: 375 });
  let pointValue1 = false;
  let pointValue2 = false;
  const bar = new Set();
  const bar2 = new Set();
  const [legendOneVisible, setLegendOneVisible] = useState(true);
  const [legendTwoVisible, setLegendTwoVisible] = useState(true);

  const meanV1 = [];
  if (barData1?.meanValues) {
    barData1?.meanValues
      .sort((a, b) => a?.num - b?.num)
      .map((obj) => {
        bar.add(obj?.num);
        if (obj?.value?.length > 8) {
          pointValue1 = true;
        }
        meanV1.push(parseFloat(obj?.value));
      });
  }

  const meanV2 = [];
  if (barData2?.meanValues) {
    barData2?.meanValues
      .sort((a, b) => a?.num - b?.num)
      .map((obj) => {
        bar2.add(obj?.num);
        if (obj?.value?.length > 8) {
          pointValue2 = true;
        }
        meanV2.push(parseFloat(obj?.value));
      });
  }

  let firstItem = "A";
  let secondItem = "B";

  const firstLegend =
    barData1?.name && (barData1?.name.length <= 24 || barData1?.name?.indexOf("v:") > -1)
      ? barData1?.name?.split("v:")[0] + ` ${formatFlightDate(collectionDate1)}`
      : barData1?.name && barData1?.name + ` ${formatFlightDateWithoutTimeStamp(collectionDate1)}`;

  const secondLegend =
    barData2?.name && (barData2?.name.length <= 24 || barData2?.name?.indexOf("v:") > -1)
      ? barData2?.name?.split("v:")[0] + ` ${formatFlightDate(collectionDate2)}`
      : barData2?.name && barData2?.name + ` ${formatFlightDateWithoutTimeStamp(collectionDate2)}`;

  const trace1 = {
    type: "bar",
    x: Array.from(bar),
    y: meanV1,
    yaxis: "y",
    offsetgroup: 1,
    customdata: meanV1,
    marker: { color: "#462255" },
    hovertemplate: "%{x} <br> %{fullData.name}:%{y}<extra></extra>",
    textposition: "auto",
    insidetextanchor: "start",
    textangle: 0,
    name: firstItem + " " + firstLegend,
    showlegend: false,
    visible: legendOneVisible
  };

  const trace2 = {
    type: "bar",
    x: Array.from(bar2),
    y: meanV2,
    yaxis: "y2",
    offsetgroup: 2,
    customdata: meanV2,
    marker: { color: "#FB6107" },
    hovertemplate: "%{x} <br> %{fullData.name}:%{y}<extra></extra>",
    textposition: "auto",
    insidetextanchor: "start",
    textangle: 0,
    name: secondItem + " " + secondLegend,
    showlegend: false,
    visible: legendTwoVisible
  };

  const plotData = [trace1, trace2];

  const xArray = barData1?.treatmentSummary?.map((item) => parseInt(item?.value));
  const yArray = barData2?.treatmentSummary?.map((item) => parseInt(item?.value));

  const calculateCorrelation = (x, y) => {
    let n = Math.min(x?.length, y?.length);
    let sumX = 0,
      sumY = 0,
      sumXY = 0,
      sumXSquare = 0,
      sumYSquare = 0;
    for (let i = 0; i < n; i++) {
      sumX += x[i];
      sumY += y[i];
      sumXY += x[i] * y[i];
      sumXSquare += x[i] ** 2;
      sumYSquare += y[i] ** 2;
    }
    let numerator = n * sumXY - sumX * sumY;
    let denominator = Math.sqrt((n * sumXSquare - sumX ** 2) * (n * sumYSquare - sumY ** 2));
    if (denominator === 0) {
      return 0;
    }
    let correlationCoEfficient = numerator / denominator;
    return correlationCoEfficient;
  };
  //restricting scroll wheel so that it doesnot effect other scroll
  useEffect(() => {
    const temp = document.querySelector(".scrollbox");
    if (temp) {
      temp.addEventListener("wheel", (e) => {
        e.preventDefault();
      });
    }
  });
  //restricting scroll wheel so that it doesnot effect other scroll for model
  useEffect(() => {
    if (isModel) {
      const temp = document.querySelector(".scrollbox");
      if (temp) {
        temp.addEventListener("wheel", () => {});
      }
    }
  }, [isModel]);

  const toggleTraceVisibility = (index) => {
    if (index === 0) {
      setLegendOneVisible(!legendOneVisible);
    }
    if (index === 1) {
      setLegendTwoVisible(!legendTwoVisible);
    }
  };

  const errorCondtion =
    (noAssesment1 === true && noAssesment2 == false) ||
    (noAssesment1 === false && noAssesment2 == true) ||
    noMean1 == true ||
    noMean2 === true ||
    error ||
    zeroSubPlots;

  // const mobileDevice = isMobile || isMobileLandscape;
  // const mobileClass = isMobile && !iphoneSE && !iphoneSELandScape;
  // const mobileLandScapeClass = isMobileLandscape && !iphoneSE && !iphoneSELandScape;
  // const isDesktopClass = !isModel && "desktopCompare";
  // const iphoneSELandscapeAndModelClass = iphoneSELandScape
  //   ? "iphoneSELandScapeCompare"
  //   : isDesktopClass;
  // const iphoneSEandModalClass = iphoneSE ? "iphoneSECompare" : iphoneSELandscapeAndModelClass;
  // const extraSmallAndIphoneDevices = extraSmallDevices
  //   ? "extraSmallCompare"
  //   : iphoneSEandModalClass;
  // const mobileLandScapeAndOtherClasses = mobileLandScapeClass
  //   ? "landscapeCompareChart"
  //   : extraSmallAndIphoneDevices;
  const compareChartClass = isMobile
    ? "newCompareChart"
    : isMobileLandscape
    ? "landscapeCompareChart"
    : "";
  // const mobileHeight = isMobile ? "430px" : "600px";
  // const TabletAndMobileHeight = isTablet ? "280px" : mobileHeight;
  // const mobileLandscapeAndTabletHeight = isMobileLandscape ? "340px" : TabletAndMobileHeight;
  // const iphoneSEandMobileHeight = iphoneSELandScape ? "300px" : mobileLandscapeAndTabletHeight;
  const mobileDeviceHeight = isMobile
    ? "430px"
    : isMobileLandscape
    ? "340px"
    : isTablet
    ? "280px"
    : "600px";
  const compareChartHeight = isModel ? "700px" : mobileDeviceHeight;
  const mobileAndTab = isMobile || isMobileLandscape || isTablet;
  const mobileTabWidth = mobileAndTab && !isModel;
  const desktopWidth = !isModel && !mobileAndTab;
  const deskTop = desktopWidth ? "47vw" : "57vw";
  const otherDevices = mobileTabWidth ? "100vw" : deskTop;
  const compareChartWidth = isModel
    ? "100%"
    : navbarButtons?.length === 1 || barExpand
    ? "100vw"
    : otherDevices;
  const isTabletAndDesktopWidth = isTablet ? 260 : 130;
  // const ipadProWidth = ipadPro ? 150 : isTabletAndDesktopWidth;
  // const isLandScapeWidth = isLandScape ? 350 : ipadProWidth;
  const modalWidth = isModel ? 40 : isTabletAndDesktopWidth;
  const isMobileLandScapeWidth = isMobileLandscape ? 280 : modalWidth;
  const isMobileAndOtherDevices = isMobile ? 180 : isMobileLandScapeWidth;
  const compareChartMarginBottom = isMobileAndOtherDevices;
  const mobileTab = isModel || isTablet || !(isMobile || isMobileLandscape);
  const autoSizeandResp = isMobile || isMobileLandscape ? false : mobileTab ? true : false;
  const mobileTabLayoutHeight = isMobileLandscape ? 490 : isTablet && 430;
  const layoutHeight = mobileTabLayoutHeight;

  return compareData1Loaded && compareData2Loaded ? (
    <div className="chart-loader-container">
      <Spinner className="chart-spinner" />
    </div>
  ) : errorCondtion ? (
    <span className="flight-one-date-error">
      <strong>{zeroSubPlots ? "No data selected" : "Assessment data is unavailable"}</strong>
    </span>
  ) : chartsValue === "Compare" && barData1?.length !== 0 && barData2?.length !== 0 ? (
    <div
      className={compareChartClass}
      style={{
        height: compareChartHeight,
        width: compareChartWidth,
        overflowY: "hidden",
        overflowX: "hidden",
        marginTop: "12px"
      }}>
      <CustomLegend
        plotData={plotData}
        onToggleTrace={toggleTraceVisibility}
        isTablet={isTablet}
        isMobile={isMobile}
        isMobileLandscape={isMobileLandscape}
        // iphoneSE={iphoneSE}
        // iphoneSELandScape={iphoneSELandScape}
      />
      <Plot
        className={isMobile ? "compare-chart" : null}
        style={{ cursor: "pointer" }}
        data={plotData}
        layout={{
          barmode: "group",
          height: layoutHeight,
          autosize: autoSizeandResp,
          margin: {
            t: isMobile ? 80 : 40,
            b: compareChartMarginBottom,
            l: pointValue1 === true ? 80 : isMobile ? 45 : isMobileLandscape ? 70 : 75,
            r: pointValue2 === true ? 70 : isMobileLandscape ? 100 : 60
          },

          xaxis: isMobile
            ? {
                type: "category",
                linecolor: "#E8E8E8",
                tickvals: Array.from(bar),
                showgrid: false
              }
            : {
                type: "category",
                linecolor: "#E8E8E8",
                tickvals: Array.from(bar),
                tickfont: {
                  size: 12
                },
                title: {
                  text: "Treatments"
                },
                titlefont: {
                  family: "Helvetica",
                  size: 12,
                  color: "#AAA9A9"
                }
              },
          yaxis: {
            rangemode: "tozero",
            zerolinecolor: "#E8E8E8",
            linecolor: "#E8E8E8",
            showexponent: "all",
            exponentformat: "e",
            minexponent: 3,
            title: {
              text: "Average Value"
            },
            titlefont: {
              family: "Helvetica",
              size: 12,
              color: "#AAA9A9"
            }
          },
          yaxis2: {
            overlaying: "y",
            rangemode: "tozero",
            side: "right",
            showexponent: "all",
            exponentformat: "e",
            minexponent: 3,
            title: {
              text: "Average Value"
            },
            titlefont: {
              family: "Helvetica",
              size: 12,
              color: "#AAA9A9"
            }
          },
          annotations: isMobile && [
            {
              x: 0.2,
              y: -0.3,
              text: "Treatment",
              showarrow: false,
              xref: "paper",
              yref: "paper",
              xanchor: "left",
              yanchor: "bottom",
              font: { size: 12, family: "Helvetica", color: "#AAA9A9" }
            }
          ],
          responsive: autoSizeandResp
        }}
        config={{
          staticPlot: false,
          displaylogo: false,
          responsive: autoSizeandResp,
          animation: false,
          modeBarButtonsToRemove: [
            "pan2d",
            "select2d",
            "autoScale2d",
            "hoverClosestCartesian",
            "zoom2d",
            "lasso2d"
          ]
        }}
      />
    </div>
  ) : (
    chartsValue === "Correlation" &&
    barData1?.length !== 0 &&
    barData2?.length !== 0 && (
      <>
        <div className="correlation-coeefficient">
          <span>Correlation Coefficient = </span>
          <span style={{ fontWeight: 800 }}>{calculateCorrelation(xArray, yArray).toFixed(3)}</span>
        </div>
        <NewViewerCorrelationChart
          barData={barData2 && barData1}
          isModel={isModel}
          barData2={barData2}
          isMobileLandscape={isMobileLandscape}
          isTablet={isTablet}
          isMobile={isMobile}
          navbarButtons={navbarButtons}
          barExpand={barExpand}
        />
      </>
    )
  );
}

NewViewerCompareChart.propTypes = {
  isModel: propTypes.any,
  chartsValue: propTypes.any,
  barData1: propTypes.any,
  barData2: propTypes.any,
  noAssesment1: propTypes.any,
  noAssesment2: propTypes.any,
  noMean1: propTypes.any,
  noMean2: propTypes.any,
  error: propTypes.any,
  isMobileLandscape: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any,
  compareData1Loaded: propTypes.any,
  compareData2Loaded: propTypes.any,
  collectionDate1: propTypes.any,
  collectionDate2: propTypes.any,
  navbarButtons: propTypes.any,
  barExpand: propTypes.any,
  zeroSubPlots: propTypes.any
};

export default React.memo(NewViewerCompareChart);