import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import propTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { collectionCountAPI } from ".././services/API";
import {
  Grid,
  Card,
  CardActions,
  Button,
  CardHeader,
  CardContent,
  Typography
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1.09375rem",
    fontWeight: 500,
    lineHeight: "1.2"
  },
  smallTypo: {
    fontSize: ".875rem",
    fontWeight: 400,
    lineHeight: "1.5"
  },
  overrides: {
    MuiTypography: {
      marginBottom: theme.spacing(5)
    }
  }
}));

export default function Sites({ selectedYear }) {
  const classes = useStyles();
  const [siteData, setSiteData] = useState();
  let navigate = useNavigate();
  const onlySmallScreen = useMediaQuery("(max-width:420px)");
  const onSiteClick = () => {
    let path = `/home/sites/${selectedYear}`;
    navigate(path);
  };

  const onProtocolClick = () => {
    let path = `/home/protocol/${selectedYear}`;
    navigate(path);
  };

  const onTrailClick = () => {
    let path = `/home/trials/${selectedYear}`;
    navigate(path);
  };

  const onTrailOwnerClick = (pages) => {
    let path = `/home/${pages}/${selectedYear}`;
    navigate(path);
  };

  const collectionCount = () => {
    collectionCountAPI(
      selectedYear,
      (res) => {
        setSiteData(res?.new_collection);
      },
      (err) => {
        console.log("error", err);
      }
    );
  };

  useEffect(() => {
    collectionCount();
  }, []);

  return (

    <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Card>
            <CardHeader
              component="div"
              title="Sites"
              sx={{
                padding: "10px",
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                fontSize: "1.09375rem",
                fontWeight: 500,
                lineHeight: "1.2"
              }}
            />
            <CardContent className={classes.root}>
              <Typography align="center" sx={{ fontSize: "1.09375rem", marginBottom: "13px" }}>
                {siteData?.sites} sites
              </Typography>
              <Typography align="center" sx={{ fontSize: ".875rem", marginBottom: "13px" }}>
                {siteData?.blocks} blocks
              </Typography>
              <Typography align="center" sx={{ fontSize: ".875rem", marginBottom: "13px" }}>
                {siteData?.collections} collections
              </Typography>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => onSiteClick()}
                  sx={{
                    fontSize: ".875rem",
                    backgroundColor: "green",
                    color: "white",
                    textTransform: "capitalize"
                  }}>
                  View by Sites
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Card>
            <CardHeader
              component="div"
              title="Trials"
              sx={{
                padding: "10px",
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                fontSize: "1.3125rem",
                fontWeight: 500,
                lineHeight: "1.2"
              }}
            />
            <CardContent className={classes.root}>
              <Typography align="center" sx={{ fontSize: "1.09375rem", marginBottom: "13px" }}>
                {siteData?.trials} trials
              </Typography>
              <Typography align="center" sx={{ fontSize: ".875rem", marginBottom: "13px" }}>
                {siteData?.blocks} blocks
              </Typography>
              <Typography align="center" sx={{ fontSize: ".875rem", marginBottom: "13px" }}>
                {siteData?.collections} collections
              </Typography>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => onTrailOwnerClick("crops")}
                  sx={{
                    fontSize: ".875rem",
                    backgroundColor: "green",
                    color: "white",
                    textTransform: "capitalize"
                  }}>
                  View by Crop
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onTrailClick()}
                  sx={{
                    fontSize: ".875rem",
                    backgroundColor: "green",
                    color: "white",
                    textTransform: "capitalize"
                  }}>
                  View by Trial Name
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onTrailOwnerClick("trialsOwner")}
                  sx={{
                    fontSize: ".875rem",
                    backgroundColor: "green",
                    color: "white",
                    textTransform: "capitalize"
                  }}>
                  View by Trial Owners
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Card sx={{ marginBottom: onlySmallScreen ? "50px" : "10px" }}>
            <CardHeader
              component="div"
              title="Protocols"
              sx={{
                padding: "10px",
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                fontSize: ".875rem",
                fontWeight: 400,
                lineHeight: "1.2"
              }}
            />
            <CardContent className={classes.root}>
              <Typography align="center" sx={{ fontSize: "1.09375rem", marginBottom: "13px" }}>
                {siteData?.protocols} protocols
              </Typography>
              <Typography align="center" sx={{ fontSize: ".875rem", marginBottom: "13px" }}>
                {siteData?.collections} collections
              </Typography>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => onProtocolClick()}
                  sx={{
                    fontSize: ".875rem",
                    backgroundColor: "green",
                    color: "white",
                    textTransform: "capitalize"
                  }}>
                  View by Protocols
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onTrailOwnerClick("protocolsOwner")}
                  sx={{
                    fontSize: ".875rem",
                    backgroundColor: "green",
                    color: "white",
                    textTransform: "capitalize"
                  }}>
                  View by Protocols Owners
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  );
}

Sites.propTypes = {
  selectedYear: propTypes.any,
};
