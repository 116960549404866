import { useMsal } from "@azure/msal-react";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export default function SignOutButton () {
  const { instance } = useMsal();

  instance.logoutRedirect({
    postLogoutRedirectUri: "/app",
    mainWindowRedirectUri: "/app" // redirects the top level app after logout
  });
  sessionStorage.removeItem("msal.interaction.status");
}
