import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
  registerables
} from "chart.js";
import { BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot";
import Plot from "react-plotly.js";
import propTypes from "prop-types";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
  BoxPlotController,
  BoxAndWiskers,
  ...registerables
);
import "../assests/Styles/viewer.css";

export default function NewViewerBoxChart({
  barData,
  isModel,
  isMobile,
  isMobileLandscape,
  isTablet
}) {
  const numValues = {};
  const boxPlot = [];

  const xac = new Set();

  // const isLandScape = useMediaQuery({ maxHeight: 440 });
  // const iphoneSE = useMediaQuery({ maxWidth: 375 });

  if (barData?.treatmentSummary) {
    barData?.treatmentSummary.map((obj) => {
      boxPlot.push({ num: obj?.num, value: parseFloat(obj?.value).toFixed(2).toString() });
      xac.add(obj?.num);
    });
  }

  for (const { num, value } of boxPlot) {
    if (numValues[num]) {
      numValues[num].push(value);
    } else {
      numValues[num] = [value];
    }
  }

  let scatterPlotly = [];
  for (const num in numValues) {
    barData?.meanValues.map((obj) => {
      if (obj?.num === num) {
        scatterPlotly.push({
          x: [num],
          y: [parseFloat(obj?.value).toFixed(2).toString()],
          mode: "markers",
          marker: {
            color: "#0D1A40",
            size: 6
          },
          hoverinfo: "text",
          hovertemplate: "mean : %{y}<extra></extra>",
          showlegend: false
        });
      }
    });
  }

  const boxplots = Object.keys(numValues).map((num) => ({
    x: Array(numValues[num].length).fill(`${num}`),
    y: numValues[num].sort(function (a, b) {
      return a - b;
    }),
    type: "box",
    boxpoints: "Outliers",
    marker: { color: "#51858F" },
    name: num,
    hoverinfo: "none",
    showlegend: false
  }));
  const trace = [...boxplots, ...scatterPlotly];
  const mobileCondition = isMobile || isMobileLandscape;
  const autoSizeAndResponsive = mobileCondition ? false : true;
  const isTabletMB = isTablet ? 230 : 200;
  const isLandScapeMB = isTabletMB;
  const isModalMB = isModel ? 40 : isLandScapeMB;
  const isMobileMB = mobileCondition ? 230 : isModalMB;
  const barChartMarginBottom = isMobileMB;

  return (
    <Plot
      data={trace}
      style={{ width: "100%" }}
      layout={{
        autosize: autoSizeAndResponsive,

        margin: {
          t: 40,
          b: barChartMarginBottom,
          l: mobileCondition ? 45 : 50,
          r: mobileCondition ? 0 : 30
        },

        xaxis: isMobile
          ? {
              type: "category",
              linecolor: "#E8E8E8",
              showgrid: false
            }
          : {
              type: "category",
              linecolor: "#E8E8E8",
              ticks: {
                size: 3
              },

              title: {
                text: "Treatments"
              },
              titlefont: {
                family: "Helvetica",
                size: 12,
                color: "#AAA9A9"
              }
            },
        yaxis: {
          linecolor: "#E8E8E8",
          showexponent: "all",
          exponentformat: "e",
          minexponent: 3,
          title: {
            text: "Assesment Values"
          },
          titlefont: {
            family: "Helvetica",
            size: 12,
            color: "#AAA9A9"
          }
        },
        annotations: isMobile && [
          {
            x: 0.2,
            y: -0.2,
            text: "Treatment",
            showarrow: false,
            xref: "paper",
            yref: "paper",
            xanchor: "left",
            yanchor: "bottom",
            font: { size: 12, family: "Helvetica", color: "#AAA9A9" }
          }
        ]
      }}
      config={{
        displaylogo: false,
        responsive: autoSizeAndResponsive,
        animation: false,
        modeBarButtonsToRemove: [
          "pan2d",
          "select2d",
          "autoScale2d",
          "hoverClosestCartesian",
          "zoom2d",
          "lasso2d"
        ]
      }}></Plot>
  );
}

NewViewerBoxChart.propTypes = {
  barData: propTypes.any,
  isModel: propTypes.any,
  isMobileLandscape: propTypes.any,
  isMobile: propTypes.any,
  isTablet: propTypes.any
};
