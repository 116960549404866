import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
  registerables
} from "chart.js";
import { BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot";
import Plot from "react-plotly.js";
import propTypes from "prop-types";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
  BoxPlotController,
  BoxAndWiskers,
  ...registerables
);
import "../assests/Styles/viewer.css";

export default function BoxPlot({ barData }) {
  const numValues = {};
  const boxPlot = [];
  const boxPlotly = [];
  const xac = new Set();
  const smin = [];
  const smax = [];
  const smed = [];

  if (barData?.treatmentSummary) {
    barData?.treatmentSummary.map((obj) => {
      boxPlot.push({ num: obj?.num, value: obj?.value });
      xac.add(obj?.num);
    });
  }

  for (let i = 0; i < boxPlot.length; i++) {
    const { num, value } = boxPlot[i];
    if (numValues[num]) {
      numValues[num].push(value);
    } else {
      numValues[num] = [value];
    }
  }

  for (const num in numValues) {
    const values = numValues[num];
    const min = Math.min(...values);
    const max = Math.max(...values);
    barData?.meanValues.map((obj) => {
      if (obj?.num === num) {
        let median = obj?.value;
        const q1 = (parseFloat(median) + min) / 2;
        const q3 = (parseFloat(median) + max) / 2;
        const IQR = q3 - q1;
        const lowerExtreme = q1 - 1.5 * IQR;
        const upperExtreme = q3 + 1.5 * IQR;
        const med = parseFloat(median).toFixed(2);
        const filtered = values.filter((value) => value >= lowerExtreme && value <= upperExtreme);
        const mins = Math.min(...filtered).toFixed(2);
        const maxs = Math.max(...filtered).toFixed(2);
        smin.push(mins);
        smax.push(maxs);
        smed.push(med);
        boxPlotly.push({
          lowerfence: [mins],
          q1: [q1],
          median: [med],
          q3: [q3],
          upperfence: [max],
          x: [num],
          type: "box",
          name: num,
          showlegend: false,
          hoverinfo: "none",
          marker: { color: "#51858F" }
        });
      }
    });
  }
  let s1 = {
    x: Array.from(xac),
    y: smin,
    mode: "markers",
    type: "scatter",
    marker: { color: "F2BB05" },
    opacity: 1,
    hovertemplate: "min:%{y} <extra></extra>",
    showlegend: false
  };
  let s2 = {
    x: Array.from(xac),
    y: smed,
    mode: "markers",
    type: "scatter",
    hovertemplate: "mean:%{y} <extra></extra>",
    marker: { color: "F2BB05" },
    showlegend: false
  };
  let s3 = {
    x: Array.from(xac),
    y: smax,
    mode: "markers",
    type: "scatter",
    hovertemplate: "max:%{y} <extra></extra>",
    marker: { color: "F2BB05" },
    showlegend: false
  };

  const trace = [...boxPlotly, s1, s2, s3];

  return (
    <Plot
      data={trace}
      style={{ width: "100%" }}
      layout={{
        autosize: true,

        margin: {
          t: 20,
          b: window.innerHeight < 440 ? 350 : 210,
          l: 50,
          r: 0
        },

        xaxis: {
          type: "category",
          linecolor: "#E8E8E8",
          ticks: {
            size: 3
          },

          title: {
            text: window.innerHeight > 440 ? "Treatments" : ""
          },
          titlefont: {
            family: "Helvetica",
            size: 12
          }
        },
        yaxis: {
          title: {
            text: window.innerHeight > 440 ? "Assesment Values" : ""
          },
          titlefont: {
            family: "Helvetica",
            size: 12
          }
        }
      }}
      config={{
        displaylogo: false,
        responsive: true,
        animation: false,
        modeBarButtonsToRemove: [
          "pan2d",
          "select2d",
          "autoScale2d",
          "hoverClosestCartesian",
          "zoom2d",
          "lasso2d"
        ]
      }}></Plot>
  );
}

BoxPlot.propTypes = {
  barData: propTypes.any,
};
