import { useState, useEffect } from "react";
import propTypes from "prop-types";
import { Grid, TextField, CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { protocolListAPI, loginSyt } from "../../services/API";

export default function ProtocolList({ collectionData }) {
  const [protocolListData, setProtocolListData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const idToken = sessionStorage.getItem("idToken");
  const [loading, setLoading] = useState(true);

  document.title = "Protocols";
  const onChange = (event) => {
    const final = filterList.filter(({ protocolName }) =>
      protocolName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setProtocolListData(final);
  };

  let navigate = useNavigate();

  const onSiteDetailsClick = (elem) => {
    const id = elem?.id ? elem?.id : "undefined";
    let path = `/home/protocol/${location.pathname.split("/")[4]}/${id}`;
    navigate(path, {
      state: {
        id: elem?.id,
        collectionData: collectionData,
        year: location.pathname.split("/")[4],
        name: elem?.protocolName
      }
    });
  };

  const getDataByYear = (protocolList) => {
    const listData =
      protocolList.length > 0 &&
      protocolList.reduce((acc, obj) => {
        let protocolName = obj?.protocol;
        let index = acc.findIndex((item) => {
          return item?.protocolName === protocolName;
        });

        if (index === -1) {
          acc.push({
            protocolName: protocolName ? protocolName : "No Protocol",
            names: [
              {
                blockname: obj?.trial,
                siteName: obj?.site
              }
            ],
            id: obj?.id
          });
        } else {
          acc[index].names.push({
            blockname: obj?.trial,
            siteName: obj?.site
          });
        }
        return acc;
      }, []);
    setFilterList(listData);
    setProtocolListData(listData);
  };

  useEffect(() => {
    loginSyt(
      idToken,
      (res) => {
        if (res === 200) {
          protocolListAPI(location.pathname.split("/")[4], (data) => {
            getDataByYear(data?.protocol_lists);
            setLoading(false);
          });
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, [location.pathname.split("/")[4]]);

  return (
    <>
      {loading ? (
        <Stack
          alignItems="center"
          sx={{ display: "flex", justifyContent: "center", height: "50vh" }}>
          <CircularProgress
            size={80}
            thickness={6}
            sx={{
              color: "green",
              "--CircularProgress-size": "80px",
              top: "10%",
              left: "50%"
            }}
          />
        </Stack>
      ) : (
        <div>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems={protocolListData.length === 1 ? "center" : "stretch"}
            direction="row"
            padding="20px"
            marginBottom={isMobile ? "40px" : isIpad ? "80px" : "0px"}
            marginLeft={isMobile ? "-18px" : "-10px"}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              textAlign="center"
              style={{ paddingTop: "10px", marginBottom: "12px" }}>
              <TextField
                variant="outlined"
                onChange={onChange}
                sx={{
                  padding: 0,
                  width: isMobile ? "100%" : "300px",
                  "& label.Mui-focused": {
                    color: "black"
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "black"
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black"
                    },
                    "&:hover fieldset": {
                      borderColor: "black"
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black"
                    }
                  }
                }}
                label="Search protocol name..."
                size="small"
              />
            </Grid>
            <div className="flex-container">
              {protocolListData &&
                protocolListData.map((elem, index) => (
                  <div className="divflex" key={index} onClick={() => onSiteDetailsClick(elem)}>
                    <div className="card-header" key={index}>
                      <span className="initial-avatar">{elem?.protocolName.charAt(0)}</span>
                      <span className="card-title">{elem?.protocolName}</span>
                    </div>
                    <ul className="card-details">
                      {elem?.names.map((obj, i) => (
                        <>
                          <li key={i} className="card-blocks">
                            <b>{obj?.siteName}</b> - {obj?.blockname}
                          </li>
                          {i === elem?.names.length - 1 ? null : <hr className="card-divider" />}
                        </>
                      ))}
                    </ul>
                  </div>
                ))}
              {protocolListData.length === 0 && (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: "20px" }}>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#e9ecef",
                      display: "flex",
                      alignItems: "center",
                      color: "black",
                      borderRadius: "4px",
                      padding: "15px"
                    }}>
                    <Typography variant="h5" sx={{ fontSize: "22px", margin: "10px 5px" }}>
                      No protocols found for this search query.
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </div>
      )}
    </>
  );
}

ProtocolList.propTypes = {
  collectionData: propTypes.any,
  trialData: propTypes.any,
  selectedYear: propTypes.any
};
